import { Component, OnInit } from '@angular/core';
import { DoodleModel } from '../../doodle-model';
import { DoodlesService } from '../../doodles.service';

@Component({
  selector: 'app-winter-christmas',
  templateUrl: './winter-christmas.component.html',
  styleUrls: ['./winter-christmas.component.less']
})
export class WinterChristmasComponent implements OnInit {
  public model: DoodleModel;

  constructor(
    private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {
    this.model = this.doodlesService.doodleModel;
  }
}
