<div (click)="this.onDoodleClick()"
     [ngSwitch]="this.doodleName">
    <app-wolf *ngSwitchCase="'wolf'"></app-wolf>
    <app-astronaut *ngSwitchCase="'astronaut'"></app-astronaut>
    <app-car *ngSwitchCase="'retroCar'"></app-car>
    <app-airplane *ngSwitchCase="'airplane'"></app-airplane>
    <app-star-wars *ngSwitchCase="'starWars'"></app-star-wars>
    <app-developer *ngSwitchCase="'developer'"></app-developer>
    <app-halloween *ngSwitchCase="'halloween'"></app-halloween>
    <app-lithuania-day *ngSwitchCase="'lithuaniaDay'"></app-lithuania-day>
    <app-winter-christmas *ngSwitchCase="'winterChristmas'"></app-winter-christmas>
</div>
