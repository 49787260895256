import { Component, OnInit } from '@angular/core';
import {DoodleModel} from '../../doodle-model';
import {DoodlesService} from '../../doodles.service';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.less']
})
export class DeveloperComponent implements OnInit {
  public model: DoodleModel;

  constructor(
      private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {
    this.model = this.doodlesService.doodleModel;
  }
}
