<svg
  [ngClass]="{
			'nm-he-300p': this.model.isDoodleActive,
			'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
			'nm-wi-300p': this.model.isDoodleActive,
			'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
			'fillToScreen': this.model.isDoodleActive === false
		}"

  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  width="300px"
  height="300px"
  viewBox="0 0 300 300"
  style="enable-background:new 0 0 300 300;"
  xml:space="preserve">

	<path id="background"
        fill="#311B92"
        d="M300,150c0,56.4-31.13,105.54-77.16,131.15C201.28,293.16,176.44,300,150,300
	c-30.31,0-58.51-8.99-82.1-24.45C27.01,248.76,0,202.53,0,150C0,67.16,67.16,0,150,0S300,67.16,300,150z"/>
  <g id="small_stars">

		<ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -171.5797 259.7878)"
             fill="#B39DDB"
             cx="137.426"
             cy="277.319"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -135.8007 273.5159)"
             fill="#B39DDB"
             cx="167.111"
             cy="253.441"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -97.5768 303.6402)"
             fill="#B39DDB"
             cx="212.107"
             cy="235.66"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -153.8643 223.0175)"
             fill="#B39DDB"
             cx="114.69"
             cy="243.713"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -177.6656 207.2187)"
             fill="#B39DDB"
             cx="89.215"
             cy="256.264"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -184.7144 177.2298)"
             fill="#B39DDB"
             cx="59.923"
             cy="247.326"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -160.3651 157.5055)"
             fill="#B39DDB"
             cx="55.15"
             cy="216.542"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -139.1635 170.126)"
             fill="#B39DDB"
             cx="76.595"
             cy="204.636"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -114.0912 142.8724)"
             fill="#B39DDB"
             cx="65.714"
             cy="169.466"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -98.1712 85.4107)"
             fill="#B39DDB"
             cx="24.301"
             cy="127.056"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -57.2446 155.64)"
             fill="#B39DDB"
             cx="105.107"
             cy="127.006"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -55.0652 103.0319)"
             fill="#B39DDB"
             cx="60.995"
             cy="98.829"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -32.17 76.1569)"
             fill="#B39DDB"
             cx="49.351"
             cy="65.72"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -24.107 129.3908)"
             fill="#B39DDB"
             cx="99.122"
             cy="85.409"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 16.9777 127.3546)"
             fill="#B39DDB"
             cx="117.915"
             cy="49.09"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 23.6126 95.5638)"
             fill="#B39DDB"
             cx="93.917"
             cy="27.493"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 32.5528 162.636)"
             fill="#B39DDB"
             cx="156.017"
             cy="53.348"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 27.5246 240.2094)"
             fill="#B39DDB"
             cx="220.156"
             cy="96.455"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 21.6685 284.614)"
             fill="#B39DDB"
             cx="255.381"
             cy="123.689"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 76.5727 193.9032)"
             fill="#B39DDB"
             cx="204.893"
             cy="31.158"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 65.5351 269.5962)"
             fill="#B39DDB"
             cx="264.411"
             cy="78.489"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -1.7504 273.5235)"
             fill="#B39DDB"
             cx="234.143"
             cy="138.266"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -55.1954 276.6614)"
             fill="#B39DDB"
             cx="210.117"
             cy="185.756"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -58.9193 238.1815)"
             fill="#B39DDB"
             cx="175.192"
             cy="169.716"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 23.5834 313.8856)"
             fill="#B39DDB"
             cx="281.49"
             cy="136.679"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -40.8815 308.2372)"
             fill="#B39DDB"
             cx="244.404"
             cy="189.245"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 59.7012 126.9162)"
             fill="#B39DDB"
             cx="138.9"
             cy="12.161"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 4.4256 165.674)"
             fill="#B39DDB"
             cx="144.564"
             cy="79.034"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -129.4574 243.96)"
             fill="#B39DDB"
             cx="144.888"
             cy="233.213"
             rx="0.802"
             ry="0.802"/>

    <ellipse transform="matrix(0.4941 -0.8694 0.8694 0.4941 -93.0199 321.8814)"
             fill="#B39DDB"
             cx="230.058"
             cy="240.866"
             rx="0.802"
             ry="0.802"/>
</g>
  <g id="big_stars">
	<path class="star-blink2"
        fill="#EDE7F6"
        d="M62.355,179.791l-1.441-1.017l1.017-1.441c0.232-0.328,0.153-0.782-0.175-1.014
		c-0.328-0.232-0.782-0.153-1.014,0.175l-1.017,1.441l-1.441-1.017c-0.328-0.232-0.782-0.153-1.014,0.175
		c-0.232,0.328-0.153,0.782,0.175,1.014l1.441,1.017l-1.017,1.441c-0.232,0.328-0.153,0.782,0.175,1.014
		c0.328,0.232,0.782,0.153,1.014-0.175l1.017-1.441l1.441,1.017c0.328,0.232,0.782,0.153,1.014-0.175S62.684,180.022,62.355,179.791
		z"/>
    <path class="star-blink1"
          fill="#EDE7F6"
          d="M237.783,62.892l-0.325-2.014l2.014-0.325c0.459-0.074,0.771-0.506,0.697-0.965
		c-0.074-0.459-0.506-0.771-0.965-0.697l-2.014,0.325l-0.325-2.014c-0.074-0.459-0.506-0.771-0.965-0.697
		c-0.459,0.074-0.771,0.506-0.697,0.965l0.325,2.014l-2.014,0.325c-0.459,0.074-0.771,0.506-0.697,0.965
		c0.074,0.459,0.506,0.771,0.965,0.697l2.014-0.325l0.325,2.014c0.074,0.459,0.506,0.771,0.965,0.697
		C237.545,63.783,237.857,63.351,237.783,62.892z"/>
    <path class="star-blink3"
          fill="#EDE7F6"
          d="M59.426,60.366l0.609-0.936l0.936,0.609c0.213,0.139,0.498,0.078,0.637-0.135
		c0.139-0.213,0.078-0.498-0.135-0.637l-0.935-0.609l0.609-0.936c0.139-0.213,0.078-0.498-0.135-0.637s-0.498-0.078-0.637,0.135
		l-0.609,0.936l-0.935-0.609c-0.213-0.139-0.498-0.078-0.637,0.135c-0.139,0.213-0.078,0.498,0.135,0.637l0.936,0.609l-0.609,0.936
		c-0.139,0.213-0.078,0.498,0.135,0.637S59.288,60.579,59.426,60.366z"/>
    <path class="star-blink2"
          fill="#EDE7F6"
          d="M234.255,153.33l-0.846-0.615l0.615-0.846c0.14-0.193,0.098-0.463-0.095-0.603
		c-0.193-0.14-0.463-0.098-0.603,0.095l-0.615,0.846l-0.846-0.615c-0.193-0.14-0.463-0.098-0.603,0.095
		c-0.14,0.193-0.098,0.463,0.095,0.603l0.846,0.615l-0.615,0.846c-0.14,0.193-0.098,0.463,0.095,0.603s0.463,0.098,0.603-0.095
		l0.615-0.846l0.846,0.615c0.193,0.14,0.463,0.098,0.603-0.095C234.49,153.74,234.448,153.47,234.255,153.33z"/>
    <path class="star-blink3"
          fill="#EDE7F6"
          d="M177.518,113.72l1.319-0.856l0.856,1.319c0.195,0.3,0.597,0.386,0.897,0.191
		c0.3-0.195,0.386-0.597,0.191-0.897l-0.856-1.319l1.319-0.856c0.3-0.195,0.386-0.597,0.191-0.897
		c-0.195-0.3-0.597-0.386-0.897-0.191l-1.319,0.856l-0.856-1.319c-0.195-0.3-0.597-0.386-0.897-0.191
		c-0.3,0.195-0.386,0.597-0.191,0.897l0.856,1.319l-1.319,0.856c-0.3,0.195-0.386,0.597-0.191,0.897
		C176.816,113.83,177.217,113.915,177.518,113.72z"/>
</g>
  <g id="stand">
	<path fill="#546E7A"
        d="M222.84,281.15c-4.62,2.57-9.38,4.91-14.28,6.98h-0.01c-6.13,2.61-12.47,4.82-18.99,6.59
		c-7.33,2.01-14.9,3.47-22.64,4.34c-5.55,0.62-11.2,0.94-16.92,0.94c-4.69,0-9.32-0.22-13.9-0.64c-7.51-0.69-14.85-1.93-22-3.68
		c-9.81-2.41-19.24-5.79-28.18-10.03c-6.26-2.96-12.27-6.34-18.02-10.1l5.42-29.23l11.2-60.37l14.28,1.61l14.93,1.69l1.45,0.03
		l13.82,0.26l21.85,0.42l15.34,0.3l10.88-0.2l9.2-0.16l5.19-0.62l12.71-1.51l12.29,61.49L222.84,281.15z"/>
    <path fill="#607D8B"
          d="M115.18,189.28l-1.08,106.4c-9.81-2.41-19.24-5.79-28.18-10.03l12.88-98.09l14.93,1.69L115.18,189.28z"/>
    <path fill="#607D8B"
          d="M166.92,299.06c-5.55,0.62-11.2,0.94-16.92,0.94c-4.69,0-9.32-0.22-13.9-0.64L129,189.54l21.85,0.42
		L166.92,299.06z"/>
    <path fill="#607D8B"
          d="M208.55,288.13c-6.13,2.61-12.47,4.82-18.99,6.59l-12.49-104.66l9.2-0.16l5.19-0.62L208.55,288.13z"/>
    <path opacity="0.4"
          fill="#455A64"
          d="M222.84,281.15c-4.62,2.57-9.38,4.91-14.28,6.98h-0.01c-6.13,2.61-12.47,4.82-18.99,6.59
		c-7.33,2.01-14.9,3.47-22.64,4.34c-5.55,0.62-11.2,0.94-16.92,0.94c-4.69,0-9.32-0.22-13.9-0.64c-7.51-0.69-14.85-1.93-22-3.68
		c-9.81-2.41-19.24-5.79-28.18-10.03c-6.26-2.96-12.27-6.34-18.02-10.1l5.42-29.23l19.82,17.76l47.47,2.63l54.03-12.55l15.97-1.02
		l5.85-3.88L222.84,281.15z"/>
</g>
  <g id="wolf">
	<polygon class="wolf-head"
           fill="#FFFFFF"
           points="119.51,69.453 121.614,70.758 119.51,72.731 121.89,74.31 119.678,76.837 122.206,79.124
		119.785,81.533 121.772,82.754 120.858,84.806 119.325,86.587 117.392,81.057 118.203,70.719 	"/>
    <polygon class="wolf-head"
             fill="#FFFFFF"
             points="126.034,66.575 123.705,68.627 125.775,70.124 123.705,72.534 126.332,73.583 124.534,76.046
		126.899,77.099 125.086,79.795 128.204,81.492 130.414,70.166 	"/>
    <polygon fill="#CFD8DC"
             points="126.767,155.134 133.992,156.715 141.831,152.81 143.225,149.249 152.896,146.58 149.641,137.653
		128.441,138.989 123.234,143.883 	"/>
    <polygon fill="#B0BEC5"
             points="142.76,135.097 142.76,127.328 147.967,126.841 149.827,129.907 172.422,137.653 177.908,135.745
		182.371,137.653 175.863,142.489 158.661,141.373 153.732,149.249 150.571,151.136 150.85,144.649 142.76,136.445 	"/>
    <polygon fill="#90A4AE"
             points="142.247,128.311 146.354,132.384 147.15,135.745 148.344,138.459 145.295,139.454 141.645,137.653
		"/>
    <polygon fill="#90A4AE"
             points="131.496,142.106 133.619,144.649 135.675,148.037 142.76,144.649 138.023,141.444 	"/>
    <polygon fill="#B0BEC5"
             points="135.053,123.775 141.421,128.61 145.077,135.745 144.488,140.993 138.945,139.932 131.224,126.394
		"/>
    <polygon fill="#CFD8DC"
             points="132.223,140.521 135.053,145.239 142.247,141.819 139.888,134.153 132.223,124.954 125.736,128.492
		"/>
    <polygon fill="#CFD8DC"
             points="112.053,151.135 110.047,151.676 104.415,153.182 102.386,153.728 102.386,149.837 105.214,147.48
		103.449,129.905 108.047,129.905 108.26,131.031 111.892,150.291 	"/>
    <polygon fill="#CFD8DC"
             points="114.683,169.936 114.683,183.183 111.491,185.018 113.725,189.247 115.481,192.28 124.019,188.848
		124.019,182.624 127.211,185.018 125.136,172.65 122.503,164.944 	"/>
    <polygon fill="#90A4AE"
             points="113.587,174.941 113.587,170.629 119.84,170.231 123.234,168.374 125.195,171.16 125.136,172.65
		127.152,176.135 123.669,178.612 122.21,175.007 115.776,176.466 	"/>
    <polygon fill="#90A4AE"
             points="124.134,142.106 131.224,144.649 132.223,151.526 129.971,157.695 126.521,153.182 	"/>
    <polygon fill="#B0BEC5"
             points="111.892,150.291 110.047,151.676 104.415,153.182 103.852,150.181 106.737,146.836 105.438,131.497
		108.26,131.031 	"/>
    <polygon fill="#B0BEC5"
             points="125.5,144.649 134.463,142.408 132.223,134.153 126.916,126.841 119.84,130.733 	"/>
    <polygon fill="#B0BEC5"
             points="115.516,152.81 110.588,159.505 113.052,164.619 113.052,169.297 111.239,172.429 118.678,172.429
		123.234,170.012 124.257,177.078 127.697,173.266 129.278,162.573 124.722,156.25 	"/>
    <polygon fill="#90A4AE"
             points="112.393,154.909 110.588,157.032 117.661,158.424 117.715,161.675 123.234,163.966 124.134,167.711
		126.389,167.711 126.389,171.457 130.767,170.298 128.777,160.438 121.281,155.237 	"/>
    <polygon fill="#CFD8DC"
             points="105.938,150.428 106.513,155.971 116.066,155.499 124.439,158.447 127.387,161.867 127.387,169.297
		132.223,166.113 132.223,160.57 127.387,144.531 121.137,129.2 106.513,132.03 109.012,148.415 	"/>
    <polygon fill="#90A4AE"
             points="102.386,133.941 103.24,136.602 109.209,134.153 115.643,135.745 119.933,132.954 125.736,133.418
		130.572,129.905 133.088,127.648 136.066,129.566 139.655,125.326 130.899,122.938 109.01,127.714 101.184,131.627 	"/>
    <polygon fill="#B0BEC5"
             points="104.626,117.76 100.852,125.19 98.382,128.021 100.734,134.153 121.845,131.441 136.94,124.6
		136.114,121.194 131.161,120.119 127.505,117.171 	"/>
    <polygon fill="#90A4AE"
             points="100.852,118.295 109.077,124.954 112.858,123.775 116.837,123.775 125.736,124.53 128.71,121.625
		133.088,118.295 120.95,114.713 	"/>
    <polygon fill="#CFD8DC"
             points="133.365,114.651 128.749,119.192 113.841,121.192 102.926,118.651 104.381,109.737 107.294,108.829
		107.363,108.599 108.749,104.105 114.059,103.702 128.019,102.645 128.324,104.386 129.232,109.519 129.658,111.921 	"/>
    <polygon fill="#CFD8DC"
             points="177.978,174.976 172.681,177.078 169.964,181.457 163.134,186.683 168.152,188.356 172.124,191.492
		174.981,190.865 174.075,186.683 176.653,186.544 179.023,188.913 182.371,185.359 182.371,174.001 	"/>
    <polygon fill="#B0BEC5"
             points="129.232,109.519 127.548,109.191 124.68,111.553 117.393,110.203 112.869,111.553 107.363,108.599
		108.749,104.105 114.059,103.702 120.801,103.702 128.324,104.386 	"/>
    <polygon fill="#B0BEC5"
             points="176.932,167.589 174.684,174.349 177.838,177.903 186.27,174.419 181.95,170.516 183.483,166.823
		177.072,165.847 	"/>
    <polygon fill="#90A4AE"
             points="180.147,165.363 179.069,170.134 179.069,174.734 182.112,178.948 185.245,173.493 	"/>
    <polygon class="wolf-head"
             fill="#CFD8DC"
             points="115.534,72.907 114.384,86.282 117.293,88.464 110.747,90.464 106.747,99.556 98.382,103.011
		110.202,107.375 126.203,104.647 138.023,111.921 137.114,101.92 131.113,95.192 136.75,95.374 136.386,82.463 132.568,80.099
		131.067,61.253 125.112,66.01 127.294,79.554 122.203,86.464 119.839,83.918 119.475,67.916 	"/>
    <polygon fill="#B0BEC5"
             points="180.147,169.297 184.807,166.544 190.312,163.966 196.444,169.889 196.444,176.718 189.267,174.697
		184.946,178.948 180.784,174.419 180.147,169.889 	"/>
    <polygon fill="#CFD8DC"
             points="183.331,156.215 190.302,155.879 193.386,151.858 194.995,159.231 202.972,165.465 200.022,181.746
		196.768,185.907 199.514,191.196 194.022,187.771 180.784,191.196 185.61,185.806 179.377,181.746 189.954,180.517
		194.835,174.924 192.496,169.297 189.954,166.075 180.147,165.363 	"/>
    <polygon fill="#B0BEC5"
             points="178.773,148.037 185.61,153.6 183.13,164.996 174.684,161.577 	"/>
    <polygon fill="#90A4AE"
             points="179.716,162.753 184.637,161.199 188.296,162.753 189.104,164.371 190.14,167.285 181.842,165.487
		"/>
    <polygon fill="#CFD8DC"
             points="152.24,148.196 156.103,153.73 153.717,161.488 159.057,164.442 171.1,166.601 168.486,171.486
		177.121,169.297 180.302,167.283 190.641,169.297 186.664,164.944 179.052,161.829 176.099,152.81 175.303,145.239
		168.714,140.993 158.716,138.989 	"/>
    <polygon fill="#90A4AE"
             points="168.968,139.926 164.177,147.602 167.867,150.182 166.378,154.909 170.522,157.695 174.213,159.062
		176.118,159.062 176.118,150.515 	"/>
    <polygon fill="#B0BEC5"
             points="116.039,185.896 108.778,183.901 106.513,181.746 102.386,187.093 103.91,192.599 106.513,190.684
		108.858,193.157 110.588,191.881 114.204,193.796 117.715,190.604 	"/>
    <polygon fill="#CFD8DC"
             points="174.952,140.529 180.784,147.184 177.03,160.438 170.863,151.136 168.584,143.411 173.008,139.926
		"/>
    <polygon fill="#B0BEC5"
             points="165.922,186.056 160.974,184.663 158.117,183.478 156.514,189.053 157.42,194.21 161.218,191.84
		163.901,194.21 166.479,191.492 170.591,194.21 171.009,187.311 	"/>
    <polygon class="wolf-head"
             fill="#90A4AE"
             points="129.589,83.791 128.412,86.372 130.082,89.636 130.414,93.28 131.866,89.485 132.815,87.131
		130.955,84.892 	"/>
    <polygon class="wolf-head"
             fill="#90A4AE"
             points="127.745,100.413 128.886,96.627 132.815,101.866 133.992,107.571 130.868,102.099 127.152,101.814
		"/>
    <polygon fill="#90A4AE"
             points="106.513,190.684 105.628,187.771 108.347,185.951 108.347,188.318 	"/>
    <polygon fill="#90A4AE"
             points="110.588,191.881 110.05,189.865 111.491,188.844 112.053,187.079 112.327,188.844 	"/>
    <polygon fill="#90A4AE"
             points="161.218,191.84 160.217,188.318 161.942,186.615 162.566,188.844 	"/>
    <polygon fill="#90A4AE"
             points="166.479,191.492 166.187,190.263 167.314,188.318 167.314,191.196 	"/>
</g>
  <g id="moon"
     class="rotate-moon">
	<path fill="#FFEB3B"
        d="M173.18,52.47c0,6.56,2.54,12.52,6.69,16.97c-0.13,0-0.27,0.01-0.41,0.01c-0.64,0-1.28-0.02-1.91-0.08
		c-12.86-0.97-22.98-11.7-22.98-24.81c0-13.75,11.14-24.9,24.89-24.9c4.21,0,8.17,1.04,11.64,2.89c2.46,1.3,4.69,3,6.57,5.03
		C184.11,27.8,173.18,38.85,173.18,52.47z"/>
    <path fill="#FBC02D"
          d="M173.18,52.47c0,6.56,2.54,12.52,6.69,16.97c-0.13,0-0.27,0.01-0.41,0.01c-0.64,0-1.28-0.02-1.91-0.08
		c-0.47-0.4-0.95-0.84-1.43-1.32c-6.77-6.81-8.5-27.35,0-38.45c4.12-5.37,10-6.9,14.98-7.05c2.46,1.3,4.69,3,6.57,5.03
		C184.11,27.8,173.18,38.85,173.18,52.47z"/>
    <circle fill="#FBC02D"
            cx="163.046"
            cy="42.839"
            r="2.931"/>
    <circle fill="#FBC02D"
            cx="160.33"
            cy="48.781"
            r="1.506"/>
</g>
</svg>
