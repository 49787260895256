import { Injectable } from '@angular/core';
import { UserService } from '@wap/core/libs/user';

export interface UserProfile {
  name: string;
  fullName: string;
  isGuest: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardProfileService {

  constructor(
    private userService: UserService,
  ) {}

  public getCurrentUser(): any {
    return this.userService.getCurrentUser();
  }

  public signOut(): void {
    return this.userService.signout();
  }
}
