import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCommonModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProfileModule } from '@web-app/user-profile/libs/profile';
import { DashboardComponent } from './dashboard.component';
import { DoodlesModule } from '../doodles/doodles.module';
import { FlexModule } from '@angular/flex-layout';
import { SwitcherModule } from '@wap/app-switcher/libs/switcher';
import { NotificationsModule } from '@wap/core/libs/notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [DashboardComponent],
  exports: [DashboardComponent],
    imports: [
        NotificationsModule,
        DoodlesModule,
        CommonModule,
        HttpClientModule,
        ProfileModule,
        SwitcherModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatGridListModule,
        MatCardModule,
        MatCommonModule,
        MatIconModule,
        FlexModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'core/assets/i18n/', '.json'),
              deps: [HttpClient]
            }
        })
    ],
})
export class DashboardModule {
}
