import {Component, OnInit} from '@angular/core';
import {DoodlesService} from './doodles.service';

@Component({
  selector: 'app-doodles',
  templateUrl: './doodles.component.html',
  styleUrls: ['./doodles.component.less']
})
export class DoodlesComponent implements OnInit {
  public doodleName: string;

  constructor(
    private doodlesService: DoodlesService
  ) {}

  ngOnInit(): void {
    this.doodleName = this.doodlesService.doodleModel.name;
  }

  public onDoodleClick(): void {
    this.doodlesService.resizeDoodle();
  }
}
