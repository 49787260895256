import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardErrorHandleService {

  constructor() { }

  private errorStatus = {
    401: () => this.handleError401(),
  };

  private handleError401(): void {
    // @ts-ignore
    if (!window.unitTest) {
      window.location.reload();
    }
    else {
      // @ts-ignore
      window.unitTestReload = true;
    }
  }

  public handlingErrorStatus(response): void {
    if (response) {
      const errorHandle = this.errorStatus[response.status];

      if (errorHandle) {
        errorHandle();
      }
    }
  }
}
