<svg [ngClass]="{
            'nm-he-300p': this.model.isDoodleActive,
            'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
            'nm-wi-300p': this.model.isDoodleActive,
            'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
            'fillToScreen': !this.model.isDoodleActive
        }"
     version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFF3E0;}
  .st1{fill:#FFE0B2;}
  .st2{fill:#FFCC80;}
  .st3{fill:#558B2F;}
  .st4{fill:#FF9800;}
  .st5{opacity:0.2;fill:#F96C02;}
  .st6{fill:#F29428;}
  .st7{fill:#EF6C00;}
  .st8{fill:#500400;}
  .st9{fill:#3F0302;}
  .st10{fill:#F96C02;}
</style>
  <g id="background">
	<path class="st0" d="M299.2,150.3c0,20.8-4.2,40.5-11.8,58.5c-22.8,53.8-76.1,91.5-138.2,91.5c-60.8,0-113.1-36.2-136.7-88.1
		C4,193.3-0.8,172.3-0.8,150.3c0-82.8,67.2-150,150-150S299.2,67.4,299.2,150.3z"/>
    <path class="st1" d="M274.9,230.3c-2.3,5-5.3,9.7-8.9,14.1c-27.5,34.1-69.6,55.9-116.8,55.9c-46,0-87.2-20.7-114.7-53.4
		c-4.3-5.1-7.8-10.7-10.4-16.6c-8.2-18.5-8.2-40,0.9-58.8c22.5-46.4,70.1-78.4,125.2-78.4c54,0,100.8,30.8,123.9,75.8
		C284.1,188.3,284,211,274.9,230.3z"/>
  </g>
  <ellipse id="shadow_2_" class="st2" cx="151.6" cy="243.1" rx="105.1" ry="11.5"/>
  <g id="pumpkin_1_">
	<g id="body">
		<path id="stemp" class="st3" d="M167.8,29.2l-0.7,1.4c-0.3,0.5-0.5,1-0.8,1.6c-0.5,1.1-1,2.1-1.4,3.2c-0.9,2.1-1.7,4.3-2.4,6.5
			c-1.3,4.3-2.2,8.7-2.3,12.7c-0.1,2,0,3.9,0.3,5.7c0.3,1.8,0.8,3.4,1.4,4.7c0.7,1.4,1.5,2.5,2.3,3.6c0.9,1,2,1.9,3.1,2.7
			c6.6,4.9,7.9,14.2,3,20.7s-14.2,7.9-20.7,3c-1.4-1.1-2.6-2.4-3.6-3.8l-0.9-1.5c-2-3.1-3.7-6.4-5-9.8c-1.2-3.5-2-7.1-2.2-10.6
			c-0.2-3.5,0-6.9,0.5-10.1c0.5-3.2,1.4-6.2,2.4-9.1c2.1-5.7,4.9-10.6,8-15.2c1.6-2.3,3.2-4.4,5-6.5c0.9-1,1.8-2,2.7-3
			c0.5-0.5,0.9-1,1.4-1.5l1.5-1.5c2.1-2.1,5.5-2.1,7.6,0c1.7,1.7,2,4.2,1,6.2L167.8,29.2z"/>
    <path id="body_1_" class="st4" d="M264.4,117.7c-33.3-70.6-87.3-56.2-102-50.7c-9.1-5.5-16.7-3.7-22.2-0.3
			c-15.9-5.7-68.4-18.2-101.1,51c-37.7,80,17,149.6,93.3,123.8c6.5,2.6,13.2,4,19.3,4s12.8-1.3,19.3-4
			C247.5,267.3,302.2,197.8,264.4,117.7z"/>
    <g id="side-shadows">
			<path class="st5" d="M78.1,111.3c15.8-30.1,45-38.2,62.2-44.6c-16.9-5.8-70.4-17.5-101.1,51C4.1,196.2,49.6,247,101.2,247
				C58,232.6,48.2,168.5,78.1,111.3z"/>
      <path class="st5" d="M225.4,111.3c-15.8-30.1-45-38.2-62.2-44.6c16.9-5.8,70.4-17.5,101.1,51c35.1,78.4-10.4,129.2-62,129.2
				C245.5,232.6,255.3,168.5,225.4,111.3z"/>
      <path class="st6" d="M144.2,64.8c1.5-0.4,2.9-0.8,4.4-1.1C147,63.9,145.5,64.3,144.2,64.8z"/>
		</g>
    <g id="wrinkles">
			<path class="st7" d="M139.6,243.8c-1.5-2.1-3-4.3-4.4-6.5c-8.2-12.4-15-25.8-20.1-39.8c-5-14-8.1-28.7-8.5-43.7
				c-0.2-7.5,0.3-15,1.4-22.5c0.6-3.8,1.3-7.5,2.2-11.2c0.5-1.8,0.9-3.7,1.5-5.6c0.5-1.9,1.1-3.7,1.7-5.6c-1,1.7-2,3.4-2.8,5.1
				c-0.9,1.7-1.8,3.5-2.6,5.3c-1.6,3.6-3,7.2-4.2,11c-1.2,3.8-2.2,7.6-3,11.5c-0.2,1-0.4,1.9-0.6,2.9l-0.5,2.9
				c-0.3,2-0.5,3.9-0.7,5.9c-1.5,15.9,0.5,32.1,4.9,47.5c2.2,7.7,5,15.2,8.3,22.5c3.2,7.1,6.9,13.9,11,20.5c3-0.7,6.1-1.6,9.2-2.7
				C134.9,242.4,137.2,243.2,139.6,243.8z"/>
      <path class="st7" d="M80.2,220.6c-0.8-1.4-1.5-2.8-2.3-4.1l-1.1-2.1c-0.4-0.7-0.7-1.4-1-2.1c-0.7-1.4-1.3-2.9-2-4.3l-1.7-4.4
				c-2.1-6-3.9-12.1-4.9-18.4c-2.1-12.6-2.1-25.7,0.1-38.6c1-6.4,2.6-12.8,4.6-19.1c1-3.1,2.1-6.2,3.3-9.3c1.2-3.1,2.6-6.1,4.1-9
				c-1.8,2.8-3.4,5.7-4.9,8.6c-1.5,3-2.9,6-4.2,9c-2.6,6.1-4.7,12.5-6.4,19c-3.4,13-4.7,26.6-3.7,40.2c0.5,6.8,1.7,13.6,3.5,20.3
				l1.4,5c0.5,1.6,1.1,3.3,1.7,4.9c0.3,0.8,0.6,1.6,0.9,2.4l1,2.4c0.7,1.6,1.3,3.2,2.1,4.8c3.1,6.6,6.9,12.9,11.1,19
				c6.2,1.6,12.8,2.4,19.7,2.4C93.1,239.1,85.9,230.3,80.2,220.6z"/>
      <path class="st7" d="M199.7,201.2c4.4-15.4,6.4-31.6,4.9-47.5c-0.2-2-0.4-4-0.7-5.9l-0.5-2.9c-0.2-1-0.4-2-0.6-2.9
				c-0.8-3.9-1.8-7.7-3-11.5c-1.2-3.7-2.6-7.4-4.2-11c-0.8-1.8-1.7-3.5-2.6-5.3c-0.9-1.7-1.9-3.4-2.8-5.1c0.6,1.9,1.2,3.7,1.7,5.6
				c0.6,1.8,1,3.7,1.5,5.6c0.9,3.7,1.7,7.5,2.2,11.2c1.2,7.5,1.6,15.1,1.4,22.5c-0.4,15-3.5,29.7-8.5,43.7
				c-5,14-11.9,27.3-20.1,39.8c-1.4,2.2-2.9,4.3-4.4,6.5c2.4-0.6,4.7-1.4,7.1-2.3c3.1,1.1,6.2,1.9,9.2,2.7c4.1-6.6,7.8-13.4,11-20.5
				C194.7,216.4,197.5,208.9,199.7,201.2z"/>
      <path class="st7" d="M235.2,220.9l1-2.4c0.3-0.8,0.6-1.6,0.9-2.4c0.6-1.6,1.2-3.3,1.7-4.9l1.4-5c1.7-6.7,3-13.4,3.5-20.3
				c1-13.6-0.3-27.3-3.7-40.2c-1.7-6.5-3.8-12.8-6.4-19c-1.3-3.1-2.7-6.1-4.2-9c-1.5-2.9-3.1-5.9-4.9-8.6c1.5,2.9,2.9,6,4.1,9
				c1.2,3.1,2.3,6.2,3.3,9.3c2,6.3,3.5,12.6,4.6,19.1c2.1,12.9,2.2,26,0.1,38.6c-1.1,6.3-2.8,12.5-4.9,18.4l-1.7,4.4
				c-0.6,1.4-1.3,2.9-2,4.3c-0.3,0.7-0.6,1.4-1,2.1l-1.1,2.1c-0.8,1.4-1.5,2.8-2.3,4.1c-5.8,9.7-13,18.5-21.1,26.4
				c6.9,0,13.5-0.8,19.7-2.4c4.2-6,7.9-12.3,11.1-19C233.9,224.1,234.5,222.5,235.2,220.9z"/>
		</g>
	</g>
  <g id="eyes">
		<g id="left-eye">
			<path id="left-eye-path" class="st8" d="M127.9,149.9c3.2-0.2,5.2-3.5,3.9-6.5c-5.4-12.1-17.9-38.5-25.9-39.1
				c-10.3-0.8-22.8,23.2-24.3,36.6C80.5,151.4,113.5,150.7,127.9,149.9z"/>
      <g id="left-eye-shadow">
				<path class="st9" d="M127.9,149.9c1.8-0.1,3.2-1.2,3.9-2.6c-15.3,0.7-44.9,0.8-43.8-9.1c1.1-10.4,8.9-27.2,17.2-33.8
					c-10.2,0.4-22.1,23.5-23.5,36.6C80.5,151.4,113.5,150.7,127.9,149.9z"/>
			</g>
      <g id="inside-left-eye">
      <circle class="moving-inside-eye" cx="107" cy="130" r="8"/>
      <circle class="moving-inside-eye-halo" cx="107" cy="130" r="10"/>
    </g>
  </g>
  <g id="right-eye">
  <path id="eye" class="st8" d="M175.6,149.9c-3.2-0.2-5.2-3.5-3.9-6.5c5.4-12.1,17.9-38.5,25.9-39.1c10.3-0.8,22.8,23.2,24.3,36.6
    C223,151.4,190,150.7,175.6,149.9z"/>
    <g id="shadow">
    <path class="st9" d="M175.6,149.9c-1.8-0.1-3.2-1.2-3.9-2.6c15.3,0.7,44.9,0.8,43.8-9.1c-1.1-10.4-8.9-27.2-17.2-33.8
      c10.2,0.4,22.1,23.5,23.5,36.6C223,151.4,190,150.7,175.6,149.9z"/>
    </g>
    <g id="inside-right-eye">
     <circle class="moving-inside-eye" cx="197" cy="130" r="8"/>
      <circle class="moving-inside-eye-halo" cx="197" cy="130" r="10"/>
    </g>
  </g>
  </g>
  <g id="mouth">
  <path id="smile" class="st8" d="M82.4,182.3c0.7,0,63.9,15.7,138.7,0c0,0,10,44.3-69.4,44.3S82.4,182.3,82.4,182.3z"/>
    <g id="mouth-shadow">
    <path class="st9" d="M151.8,220.1c-57.9,0-68.2-23.5-69.6-36.3c-0.9,7.7-1.1,42.8,69.6,42.8s70.5-35.1,69.6-42.8
      C220,196.6,209.7,220.1,151.8,220.1z"/>
  </g>
	</g>
    <g id="teeths">
		<polygon id="top-left-tooth-shadow" class="st9" points="128.9,202.7 116.7,199.4 115,187.4 128.9,186.5 		"/>
      <polygon id="top-right-tooth-shadow" class="st9" points="174.6,202.7 186.8,199.4 188.5,187.4 174.6,186.5 		"/>
      <polygon id="top-left-tooth" class="st4" points="128.9,200.8 116.7,197.5 114.5,184.5 128.9,184.5 		"/>
      <polygon id="top-right-tooth" class="st4" points="174.5,200.8 186.8,197.5 189.1,184.5 174.5,184.5 		"/>
      <polygon id="bottom-tooth-shadow" class="st9" points="161.1,230.9 148.9,227.6 146,214.6 161.1,214.6 		"/>
      <polygon id="bottom-tooth" class="st4" points="161.1,233.1 148.9,229.7 146.6,216.8 161.1,216.8 		"/>
	</g>
    <g id="bubbles">
		<g>
			<path class="st10" d="M134.2,94.2c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4S134.2,95.5,134.2,94.2z"/>
      <path class="st10" d="M144.9,80.3c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3c0,2.4-1.9,4.3-4.3,4.3
				C146.9,84.6,144.9,82.7,144.9,80.3z"/>
      <circle class="st10" cx="151.6" cy="92.4" r="1.6"/>
		</g>
      <g>
			<path class="st10" d="M231.3,97.1c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4
				C228.9,98.1,229.9,97,231.3,97.1z"/>
        <path class="st10" d="M219.8,96.5c-0.7-0.6-0.7-1.6-0.1-2.2c0.6-0.7,1.6-0.7,2.2-0.1c0.7,0.6,0.7,1.6,0.1,2.2
				C221.5,97,220.5,97.1,219.8,96.5z"/>
		</g>
      <g>
			<path class="st10" d="M216.6,171.8c-1,0.8-2.5,0.6-3.3-0.4c-0.8-1-0.6-2.5,0.4-3.3c1-0.8,2.5-0.6,3.3,0.4
				C217.8,169.5,217.7,171,216.6,171.8z"/>
        <path class="st10" d="M225.9,165.1c0.9,0,1.6,0.8,1.5,1.7c0,0.9-0.8,1.6-1.7,1.5s-1.6-0.8-1.5-1.7
				C224.3,165.7,225,165,225.9,165.1z"/>
		</g>
      <g>
			<path class="st10" d="M38.8,160.6c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
				C39.9,163,38.8,161.9,38.8,160.6z"/>
        <circle class="st10" cx="53.8" cy="146.7" r="4.3"/>
        <circle class="st10" cx="56.1" cy="158.8" r="1.6"/>
		</g>
      <path class="st10" d="M168.8,158.5c0.6,2.1-0.7,4.3-2.8,4.8c-2.1,0.6-4.3-0.7-4.8-2.8c-0.6-2.1,0.7-4.3,2.8-4.8
			C166.1,155.1,168.2,156.4,168.8,158.5z"/>
      <path class="st10" d="M143.8,120.5c0.2,0.8-0.3,1.7-1.1,1.9c-0.8,0.2-1.7-0.3-1.9-1.1c-0.2-0.8,0.3-1.7,1.1-1.9
			C142.7,119.1,143.5,119.6,143.8,120.5z"/>
      <path class="st10" d="M123.8,167.8c0.3,1.2-0.4,2.4-1.5,2.7c-1.2,0.3-2.4-0.4-2.7-1.5c-0.3-1.2,0.4-2.4,1.5-2.7
			C122.3,165.9,123.5,166.6,123.8,167.8z"/>
	</g>
    <g id="ghost">
      <g stroke="null" id="svg_24">
        <path stroke="#000" id="svg_14" d="m223.47681,96.29453" fill="#fff"/>
        <path stroke="#000" id="svg_18"
              d="m200.17036,113.21975c1.31923,-4.70145 1.23128,-4.93652 4.30949,-13.86928c3.07821,-8.93275 10.20207,-15.20135 14.07182,-0.31343c3.86975,14.88792 7.5636,17.47372 3.86975,14.88792c-3.69385,-2.5858 -2.90231,-6.11188 -3.69385,-2.5858c-0.79154,3.52609 1.93488,7.05217 -0.79154,3.52609c-2.72641,-3.52609 -2.19872,-5.79845 -2.72641,-3.52609c-0.52769,2.27237 2.46257,4.30966 -0.52769,2.27237c-2.99026,-2.03729 -2.63847,-5.01488 -2.99026,-2.03729c-0.3518,2.97758 4.57334,5.48502 -0.3518,2.97758c-4.92514,-2.50744 -3.86975,-5.7201 -4.92514,-2.50744c-1.05539,3.21266 1.05539,6.50367 -1.05539,3.21266c-2.11077,-3.29101 2.02282,-6.8171 -2.11077,-3.29101c-4.1336,3.52609 -4.66129,6.03353 -3.86975,3.52609c0.79154,-2.50744 -0.52769,2.42908 0.79154,-2.27237z"
              fill="#fff"/>
        <ellipse stroke="#000" ry="1.21454" rx="0.79154" id="svg_19" cy="95.94192" cx="209.14115" fill="#fff"/>
        <ellipse stroke="#000" ry="1.17536" rx="0.79154" id="svg_20" cy="95.82438" cx="213.80244" fill="#fff"/>
        <ellipse stroke="#000" ry="1.91976" rx="1.14334" id="svg_23" cy="101.11351" cx="211.86756" fill="#fff"/>
  </g>
 </g>
</g>
</svg>
