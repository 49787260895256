import {Component, OnInit} from '@angular/core';
import {DoodleModel} from '../../doodle-model';
import {DoodlesService} from '../../doodles.service';

@Component({
  selector: 'app-airplane',
  templateUrl: './airplane.component.html',
  styleUrls: ['./airplane.component.less']
})
export class AirplaneComponent implements OnInit {
  public model: DoodleModel;

  constructor(
    private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {
    this.model = this.doodlesService.doodleModel;
  }

}
