<svg [ngClass]="{
			'nm-he-300p': this.model.isDoodleActive,
			'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
			'nm-wi-300p': this.model.isDoodleActive,
			'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
			'fillToScreen': this.model.isDoodleActive === false
		}"
     version="1.1"
     id="_x34_04_1_"
     xmlns="http://www.w3.org/2000/svg"
     x="0px"
     y="0px"
     viewBox="0 0 300 300"
     enable-background="new 0 0 300 300"
     xml:space="preserve">
<ellipse id="Background"
         transform="matrix(0.5246 -0.8513 0.8513 0.5246 -56.3926 199.0113)"
         fill="#311B92"
         cx="150"
         cy="150"
         rx="144.98"
         ry="144.98"/>
  <g id="Small_Stars">

		<ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 66.8112 150.8641)"
             fill="#B39DDB"
             cx="168.49"
             cy="15.609"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 33.0149 135.7875)"
             fill="#B39DDB"
             cx="138.093"
             cy="38.332"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -2.2963 104.7745)"
             fill="#B39DDB"
             cx="92.668"
             cy="54.443"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 47.8387 185.4113)"
             fill="#B39DDB"
             cx="189.938"
             cy="49.871"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 69.9784 201.8691)"
             fill="#B39DDB"
             cx="215.744"
             cy="38.275"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 75.2345 231.1496)"
             fill="#B39DDB"
             cx="244.59"
             cy="48.209"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 29.4052 289.5368)"
             fill="#B39DDB"
             cx="273.956"
             cy="118.439"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 30.9448 235.6591)"
             fill="#B39DDB"
             cx="226.483"
             cy="90.121"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 5.3672 260.6513)"
             fill="#B39DDB"
             cx="236.073"
             cy="125.52"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -12.9372 315.2434)"
             fill="#B39DDB"
             cx="275.803"
             cy="169.206"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -48.7939 245.4184)"
             fill="#B39DDB"
             cx="195.353"
             cy="166.4"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -53.5988 296.0363)"
             fill="#B39DDB"
             cx="238.274"
             cy="196.011"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -77.0576 320.7754)"
             fill="#B39DDB"
             cx="248.696"
             cy="229.386"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -82.0972 269.0276)"
             fill="#B39DDB"
             cx="199.841"
             cy="208.024"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -121.8199 268.8799)"
             fill="#B39DDB"
             cx="179.847"
             cy="243.518"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -129.8515 299.1949)"
             fill="#B39DDB"
             cx="202.976"
             cy="265.867"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -135.026 234.0578)"
             fill="#B39DDB"
             cx="142.064"
             cy="237.932"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -126.191 159.5122)"
             fill="#B39DDB"
             cx="79.733"
             cy="192.748"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -118.2621 116.9939)"
             fill="#B39DDB"
             cx="45.626"
             cy="164.39"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -175.8675 201.6448)"
             fill="#B39DDB"
             cx="92.621"
             cy="258.296"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -161.3344 129.2214)"
             fill="#B39DDB"
             cx="35.039"
             cy="209.071"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -96.2492 128.8918)"
             fill="#B39DDB"
             cx="67.286"
             cy="150.628"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -44.5511 128.6123)"
             fill="#B39DDB"
             cx="92.885"
             cy="104.198"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -42.9374 165.9098)"
             fill="#B39DDB"
             cx="127.088"
             cy="121.401"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -118.6045 88.6689)"
             fill="#B39DDB"
             cx="20.093"
             cy="150.534"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -56.7314 97.4282)"
             fill="#B39DDB"
             cx="58.872"
             cy="99.512"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -163.0406 267.1073)"
             fill="#B39DDB"
             cx="157.65"
             cy="279.541"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -107.7468 232.5736)"
             fill="#B39DDB"
             cx="154.375"
             cy="212.764"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 25.3793 163.9622)"
             fill="#B39DDB"
             cx="159.503"
             cy="59.256"
             rx="0.799"
             ry="0.799"/>

    <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -5.7532 86.9503)"
             fill="#B39DDB"
             cx="74.979"
             cy="48.627"
             rx="0.799"
             ry="0.799"/>
</g>
  <g id="Big_Stars">
	<path fill="#EDE7F6"
        d="M64.129,60.964l-0.736-1.595l1.595-0.736c0.363-0.168,0.522-0.598,0.354-0.962
		c-0.168-0.363-0.598-0.522-0.962-0.354l-1.595,0.736l-0.736-1.595c-0.168-0.363-0.598-0.522-0.962-0.354s-0.522,0.598-0.354,0.962
		l0.736,1.595l-1.595,0.736c-0.363,0.168-0.522,0.598-0.354,0.962s0.598,0.522,0.962,0.354l1.595-0.736l0.736,1.595
		c0.168,0.363,0.598,0.522,0.962,0.354C64.139,61.758,64.297,61.328,64.129,60.964z"/>
    <path fill="#EDE7F6"
          d="M236.342,164.822l0.722-1.9l1.9,0.722c0.433,0.165,0.917-0.053,1.082-0.486
		c0.165-0.433-0.053-0.917-0.486-1.082l-1.9-0.722l0.722-1.9c0.165-0.433-0.053-0.917-0.486-1.082
		c-0.433-0.165-0.917,0.053-1.082,0.486l-0.722,1.9l-1.9-0.722c-0.433-0.165-0.917,0.053-1.082,0.486s0.053,0.917,0.486,1.082
		l1.9,0.722l-0.722,1.9c-0.165,0.433,0.053,0.917,0.486,1.082S236.177,165.255,236.342,164.822z"/>
    <path fill="#EDE7F6"
          d="M203.888,26.468l0.991-0.504l0.504,0.991c0.115,0.226,0.391,0.316,0.617,0.201
		c0.226-0.115,0.316-0.391,0.201-0.617l-0.504-0.991l0.991-0.504c0.226-0.115,0.316-0.391,0.201-0.617
		c-0.115-0.226-0.391-0.316-0.617-0.201l-0.991,0.504l-0.504-0.991c-0.115-0.226-0.391-0.316-0.617-0.201
		c-0.226,0.115-0.316,0.391-0.201,0.617l0.504,0.991l-0.991,0.504c-0.226,0.115-0.316,0.391-0.201,0.617
		C203.386,26.492,203.662,26.582,203.888,26.468z"/>
    <path fill="#EDE7F6"
          d="M229.964,264.48l-0.424-0.952l0.952-0.424c0.217-0.097,0.315-0.351,0.218-0.568
		c-0.097-0.217-0.351-0.315-0.568-0.218l-0.952,0.424l-0.424-0.952c-0.097-0.217-0.351-0.315-0.568-0.218
		c-0.217,0.096-0.315,0.351-0.218,0.568l0.424,0.952l-0.952,0.424c-0.217,0.096-0.315,0.351-0.218,0.568
		c0.097,0.217,0.351,0.315,0.568,0.218l0.952-0.424l0.424,0.952c0.097,0.217,0.351,0.315,0.568,0.218
		C229.963,264.951,230.061,264.697,229.964,264.48z"/>
    <path fill="#EDE7F6"
          d="M110.356,270.309l1.564-0.082l0.082,1.564c0.019,0.356,0.323,0.63,0.679,0.611
		c0.356-0.019,0.63-0.323,0.611-0.679l-0.082-1.564l1.564-0.082c0.356-0.019,0.63-0.323,0.611-0.679s-0.323-0.63-0.679-0.611
		l-1.564,0.082l-0.082-1.564c-0.019-0.356-0.323-0.63-0.679-0.611c-0.356,0.019-0.63,0.323-0.611,0.679l0.082,1.564l-1.564,0.082
		c-0.356,0.019-0.63,0.323-0.611,0.679C109.695,270.054,109.999,270.328,110.356,270.309z"/>
</g>
  <g id="Planet">
	<path fill="#7B1FA2"
        d="M282.92,56.194c5.24,7.044,6.479,15.832,4.116,23.636c-0.237,0.781-0.506,1.55-0.821,2.306
		c-0.499,1.225-1.082,2.417-1.766,3.554c-0.337,0.583-0.705,1.141-1.104,1.687c-1.578,2.277-3.551,4.325-5.894,6.068
		c-0.244,0.182-0.494,0.356-0.75,0.522c-0.525,0.378-1.057,0.715-1.594,1.044c-2.12,1.245-4.352,2.182-6.642,2.784
		c-0.667,0.188-1.335,0.341-2.018,0.458c-3.55,0.662-7.197,0.588-10.685-0.196c-0.988-0.213-1.967-0.493-2.917-0.829
		c-4.886-1.676-9.322-4.802-12.637-9.259c-4.264-5.731-5.877-12.617-5.056-19.178c0.128-1.055,0.32-2.088,0.573-3.118
		c0.671-2.716,1.779-5.354,3.302-7.768c0.356-0.573,0.739-1.142,1.152-1.687c1.503-2.043,3.346-3.9,5.491-5.495
		c0.404-0.301,0.815-0.594,1.234-0.859c0.528-0.357,1.066-0.686,1.613-0.987c1.18-0.676,2.398-1.239,3.642-1.702
		c0.642-0.252,1.286-0.471,1.945-0.653c3.339-0.978,6.815-1.277,10.215-0.913c0.778,0.073,1.555,0.194,2.329,0.342
		C272.934,47.168,278.799,50.653,282.92,56.194z"/>
    <path fill="#6A1B9A"
          d="M235.724,65.674c4.332,8.644,10.87,19.631,20.037,32.384c-0.988-0.213-1.967-0.493-2.917-0.829
		c-7.106-10.03-13.163-19.75-17.693-28.437C235.279,67.736,235.471,66.704,235.724,65.674z"/>
    <path fill="#6A1B9A"
          d="M239.027,57.906c0.356-0.573,0.739-1.142,1.152-1.687c2.353,3.466,5.146,7.683,8.465,12.718
		c6.303,9.572,13.44,20.409,19.427,28.345c0.131,0.176,0.261,0.351,0.394,0.513c-0.667,0.188-1.335,0.341-2.018,0.458
		c-5.019-6.699-11.37-16.081-19.391-28.265C244.392,65.947,241.614,61.719,239.027,57.906z"/>
    <path fill="#6A1B9A"
          d="M248.516,48.878c3.31,5.516,7.691,12.298,11.59,18.336c6.923,10.724,12.669,19.739,16.595,26.753
		c-0.525,0.378-1.057,0.715-1.594,1.044c-4.447-7.954-10.912-17.951-16.6-26.76c-5.019-7.766-8.861-13.775-11.603-18.386
		C247.432,49.507,247.969,49.179,248.516,48.878z"/>
    <path fill="#6A1B9A"
          d="M273.225,71.065c3.628,4.781,7.622,10.038,11.224,14.625c-0.337,0.583-0.705,1.141-1.104,1.687
		c-3.12-3.986-6.958-9.002-11.635-15.161c-5.92-7.783-13.582-17.874-19.552-25.039c0.642-0.252,1.286-0.471,1.945-0.653
		C259.043,52.463,265.431,60.811,273.225,71.065z"/>
    <path fill="#6A1B9A"
          d="M266.648,45.953c3.949,7.269,9.713,16.579,14.763,24.737c2.127,3.433,3.993,6.466,5.626,9.14
		c-0.237,0.781-0.506,1.55-0.821,2.306c-2.096-3.455-4.331-7.08-6.42-10.446c-5.764-9.31-11.638-18.799-15.477-26.079
		C265.096,45.684,265.874,45.805,266.648,45.953z"/>
    <path fill="#FFB300"
          d="M299.288,48.06c2.6,3.495-2.091,11.739-11.251,21.162c-4.733,4.873-10.66,10.052-17.399,15.065
		c-6.731,5.007-13.396,9.195-19.416,12.322c-11.666,6.071-20.91,8.194-23.51,4.698c-2.407-3.236,1.422-10.518,9.253-19.035
		c0.236,0.572,0.5,1.134,0.779,1.685c-3.836,4.644-5.559,8.32-4.298,10.014c1.289,1.732,5.435,1.09,11.153-1.374
		c6.143-2.626,14.087-7.326,22.253-13.401c8.173-6.08,14.959-12.337,19.24-17.467c4.004-4.769,5.811-8.555,4.522-10.287
		c-1.26-1.694-5.277-1.101-10.827,1.238c-0.453-0.434-0.91-0.841-1.397-1.225C288.805,46.399,296.88,44.825,299.288,48.06z"/>
    <path fill="#FFA000"
          d="M290.613,51.443c1.289,1.732-0.518,5.519-4.522,10.287c-4.281,5.129-11.067,11.386-19.24,17.467
		c-8.166,6.075-16.109,10.775-22.253,13.401c-5.719,2.464-9.864,3.106-11.153,1.374c-1.26-1.694,0.462-5.37,4.298-10.014
		c0.062,0.132,0.125,0.263,0.194,0.389l0.006,0.008c-1.191,2.048-1.548,3.641-0.872,4.549c0.647,0.87,2.169,1.019,4.324,0.553
		c5.226-1.15,14.201-5.929,23.52-12.861c9.326-6.938,16.484-14.16,19.087-18.835c1.065-1.931,1.36-3.43,0.713-4.3
		c-0.676-0.908-2.304-1.025-4.608-0.473l-0.006-0.008c-0.106-0.111-0.208-0.201-0.317-0.298
		C285.336,50.342,289.353,49.749,290.613,51.443z"/>
</g>
  <g id="Slanga">
	<path fill="#FFFFFF"
        d="M137.137,187.848l-13.019-1.24c0.473-5.109,0.668-14.729-0.703-24.634
		c-0.116-0.788-0.222-1.571-0.368-2.351c-0.529-3.295-1.258-6.598-2.213-9.721c-0.214-0.733-0.454-1.444-0.694-2.155
		c-1.91-5.477-4.596-10.319-8.326-13.654c-5.219-4.636-12.1-5.972-21.058-4.082c-0.15,0.036-0.309,0.065-0.453,0.092
		c-0.798,0.174-1.594,0.323-2.379,0.454c-0.577,0.105-1.148,0.201-1.719,0.297c-0.571,0.096-1.146,0.177-1.711,0.264
		c-1.14,0.168-2.263,0.308-3.389,0.433c-1.126,0.125-2.245,0.216-3.358,0.298c-0.108,0.01-0.208,0.026-0.311,0.026
		c-0.32,0.021-0.64,0.041-0.959,0.061c-0.148,0.011-0.296,0.023-0.447,0.019c-0.507,0.033-1.008,0.057-1.513,0.066
		c-0.565,0.023-1.124,0.037-1.692,0.045c-2.164,0.023-4.288-0.019-6.355-0.154c-0.418-0.027-0.837-0.055-1.249-0.091
		c-0.379-0.029-0.752-0.067-1.116-0.099c-0.534-0.047-1.056-0.113-1.585-0.17c-0.552-0.059-1.093-0.136-1.634-0.214
		c-0.744-0.1-1.477-0.219-2.19-0.351c-0.405-0.07-0.8-0.135-1.193-0.223c-0.481-0.092-0.947-0.187-1.417-0.297
		c-0.49-0.097-0.969-0.213-1.433-0.333c-0.445-0.108-0.885-0.225-1.325-0.343c-0.455-0.114-0.907-0.252-1.35-0.384
		c-0.434-0.127-0.862-0.262-1.285-0.408c-0.395-0.128-0.774-0.26-1.163-0.397c-0.091-0.018-0.171-0.054-0.251-0.091
		c-0.346-0.124-0.701-0.253-1.048-0.377c-0.577-0.227-1.153-0.455-1.703-0.704c-0.368-0.15-0.721-0.304-1.078-0.472
		c-0.375-0.18-0.741-0.355-1.102-0.538c-0.689-0.335-1.352-0.692-2.009-1.059c-1.084-0.63-2.126-1.285-3.116-1.984
		c-0.046-0.029-0.078-0.061-0.124-0.089c-0.165-0.127-0.345-0.251-0.519-0.384c-0.059-0.049-0.128-0.104-0.202-0.15
		c-0.028-0.017-0.046-0.029-0.059-0.049c-0.065-0.04-0.124-0.089-0.174-0.133c-0.152-0.106-0.289-0.216-0.42-0.336
		c-0.283-0.226-0.561-0.461-0.829-0.69c-0.287-0.241-0.577-0.496-0.852-0.756c-0.832-0.768-1.607-1.566-2.321-2.403
		c-5.128-5.988-6.592-13.29-4.04-20.048c0.876-2.314,2.195-4.496,3.921-6.529c0.476-0.564,0.994-1.115,1.537-1.664
		c6.613-6.599,17.871-11.473,32.922-14.153c0.736-0.135,1.491-0.259,2.239-0.373c0.938-0.151,1.885-0.297,2.851-0.432
		c17.151-2.37,28.106-4.988,35.095-7.336c0.807-0.271,1.565-0.546,2.263-0.807c8.289-3.109,9.767-5.653,10.03-6.412
		c0.021-0.076,0.043-0.153,0.055-0.235c0.019-0.052,0.029-0.11,0.024-0.164c0.006-0.073,0.013-0.146,0.01-0.224
		c0.006-0.073-0.002-0.142-0.014-0.226c-0.01-0.109-0.026-0.208-0.045-0.322c-0.021-0.09-0.043-0.18-0.064-0.27
		c-0.032-0.135-0.07-0.261-0.117-0.392c-0.054-0.161-0.123-0.319-0.192-0.477c-0.04-0.101-0.089-0.208-0.148-0.321
		c-0.066-0.143-0.131-0.286-0.212-0.425c-0.071-0.134-0.152-0.273-0.232-0.412c-0.584-1.025-1.395-2.139-2.325-3.288
		c-0.272-0.347-0.554-0.7-0.857-1.04c-0.287-0.343-0.584-0.693-0.896-1.038c-0.133-0.159-0.271-0.308-0.413-0.472
		c-0.765-0.856-1.561-1.704-2.325-2.52c-0.537-0.561-1.061-1.102-1.564-1.616c-0.404-0.403-0.785-0.803-1.148-1.168
		c-2.794-2.86-5.681-5.817-8.071-8.813c-5.183-6.521-6.658-14.034-4.267-21.737c0.093-0.275,0.171-0.547,0.273-0.817l0.006-0.009
		c3.18-1.214,6.385-2.324,9.627-3.308c0.945-0.288,1.9-0.57,2.852-0.828c1.104-0.318,2.212-0.62,3.333-0.902
		c1.207-0.318,2.412-0.612,3.639-0.88c-0.493,0.489-0.957,0.972-1.385,1.437c-0.195,0.213-0.379,0.407-0.559,0.616
		c-0.636,0.721-1.222,1.423-1.736,2.091c-0.237,0.302-0.459,0.6-0.657,0.9c-0.207,0.295-0.409,0.58-0.595,0.862
		c-1.172,1.799-1.884,3.369-2.308,4.721c-1.096,3.523-0.465,6.612,2.004,9.707c1.966,2.479,4.505,5.067,7.192,7.81
		c0.668,0.681,1.337,1.361,2.009,2.057c0.506,0.529,1.012,1.058,1.517,1.588c6.849,7.266,13.034,15.339,9.96,24.336
		c-0.457,1.344-1.109,2.631-1.976,3.85c-0.057,0.093-0.129,0.189-0.196,0.276c-0.135,0.198-0.274,0.382-0.427,0.569
		c-0.139,0.183-0.281,0.352-0.438,0.524c-0.148,0.178-0.305,0.35-0.462,0.522c-0.25,0.281-0.504,0.547-0.781,0.811
		c-0.246,0.233-0.497,0.474-0.767,0.705c-0.293,0.268-0.61,0.533-0.931,0.783c-0.317,0.265-0.653,0.519-1.003,0.777
		c-0.345,0.248-0.705,0.5-1.074,0.746c-0.369,0.246-0.753,0.496-1.156,0.734c-0.269,0.167-0.538,0.334-0.841,0.493
		c-0.222,0.132-0.459,0.267-0.705,0.397c-0.026,0.022-0.068,0.048-0.119,0.068c-0.237,0.136-0.504,0.278-0.765,0.411
		c-0.261,0.133-0.538,0.27-0.814,0.407c-0.297,0.15-0.588,0.29-0.895,0.434c-0.119,0.068-0.241,0.12-0.368,0.183
		c-0.271,0.128-0.556,0.259-0.851,0.384c-0.214,0.098-0.438,0.191-0.676,0.287c-0.331,0.142-0.661,0.284-1.001,0.42
		c-1.326,0.552-2.758,1.09-4.291,1.605c-0.388,0.132-0.771,0.254-1.159,0.386c-0.66,0.22-1.345,0.438-2.048,0.645
		c-0.598,0.182-1.226,0.37-1.858,0.544c-0.763,0.221-1.556,0.449-2.377,0.66c-0.611,0.161-1.246,0.32-1.888,0.488
		c-0.485,0.123-0.965,0.237-1.46,0.355c-0.851,0.218-1.73,0.419-2.618,0.614c-0.645,0.153-1.293,0.291-1.956,0.433
		c-0.564,0.126-1.137,0.246-1.714,0.352c-0.738,0.159-1.48,0.304-2.237,0.452c-0.552,0.107-1.09,0.211-1.652,0.313
		c-1.12,0.218-2.244,0.422-3.401,0.618c-0.577,0.105-1.163,0.205-1.749,0.304c-3.546,0.605-7.306,1.181-11.307,1.735
		c-1.089,0.148-2.136,0.309-3.164,0.482c-0.764,0.118-1.509,0.247-2.236,0.388c-2.139,0.397-4.143,0.826-6.025,1.304
		c-0.345,0.082-0.68,0.169-1.016,0.257c-0.842,0.224-1.659,0.449-2.434,0.689c-0.296,0.086-0.583,0.178-0.88,0.264
		c-2.018,0.638-3.848,1.302-5.486,2.007l-0.688,0.306c-0.167,0.063-0.325,0.133-0.473,0.207c-0.316,0.138-0.607,0.279-0.889,0.425
		c-1.257,0.607-2.384,1.231-3.389,1.866c-0.263,0.158-0.497,0.308-0.727,0.474c-0.478,0.319-0.918,0.638-1.333,0.958
		c-0.183,0.131-0.362,0.276-0.526,0.418c-0.343,0.287-0.672,0.571-0.967,0.863c-0.111,0.098-0.219,0.21-0.327,0.323
		c-0.117,0.107-0.225,0.22-0.318,0.329c-0.134,0.135-0.265,0.285-0.39,0.425c-0.006,0.009-0.017,0.028-0.038,0.041
		c-0.136,0.159-0.264,0.324-0.385,0.479c-0.11,0.137-0.211,0.28-0.311,0.422c-0.044,0.05-0.078,0.106-0.112,0.161
		c-0.034,0.056-0.078,0.106-0.112,0.161l-0.12,0.195c-0.057,0.093-0.12,0.195-0.168,0.293c-0.214,0.367-0.379,0.739-0.523,1.098
		c-0.324,0.837-1.279,3.384,1.738,6.92c1.84,2.145,4.716,4.122,8.495,5.695c0.835,0.348,1.721,0.676,2.648,0.979
		c7.591,2.489,18.041,3.413,30.459,1.184c0.775-0.137,1.566-0.277,2.364-0.45c0.144-0.026,0.279-0.058,0.423-0.085
		c13.151-2.775,24.077-0.38,32.489,7.132c2.955,2.64,5.405,5.788,7.431,9.25c0.553,0.93,1.075,1.891,1.56,2.868
		c3.038,6.057,4.959,12.846,6.157,19.459l0.363,2.131C138.445,173.182,137.231,186.844,137.137,187.848z"/>
    <path fill="#CFD8DC"
          d="M117.471,8.73c-0.493,0.489-0.957,0.972-1.385,1.437c-0.195,0.213-0.379,0.407-0.559,0.616
		c-0.636,0.721-1.222,1.423-1.736,2.091c-0.237,0.302-0.459,0.6-0.657,0.9c-0.207,0.295-0.409,0.58-0.595,0.862
		c-1.172,1.799-1.884,3.369-2.308,4.721c-0.044-1.819,0.385-3.679,1.219-5.571c0.273-0.651,0.6-1.307,0.982-1.967
		c0.403-0.737,0.87-1.473,1.4-2.209C115.039,9.292,116.244,8.998,117.471,8.73z"/>
    <path fill="#CFD8DC"
          d="M120.54,60.636c0.021-0.076,0.043-0.153,0.055-0.235c0.014-0.043,0.024-0.101,0.024-0.164
		c0.016-0.067,0.013-0.146,0.01-0.224c0.006-0.073-0.002-0.142-0.014-0.226c-0.001-0.103-0.016-0.202-0.045-0.322
		c-0.012-0.084-0.033-0.174-0.064-0.27c-0.032-0.135-0.07-0.261-0.117-0.392c-0.054-0.161-0.123-0.319-0.192-0.477
		c-0.04-0.101-0.089-0.208-0.148-0.321c-0.066-0.143-0.131-0.286-0.212-0.425c-0.071-0.134-0.152-0.273-0.232-0.412
		c-0.584-1.025-1.395-2.139-2.325-3.288c-0.272-0.347-0.554-0.7-0.857-1.04c-0.287-0.343-0.584-0.693-0.896-1.038
		c-0.133-0.159-0.28-0.314-0.413-0.472c-0.765-0.856-1.561-1.704-2.325-2.52c-0.537-0.561-1.061-1.102-1.564-1.616
		c-0.404-0.403-0.785-0.803-1.148-1.168c-2.794-2.86-5.681-5.817-8.071-8.813c3.573,2.509,7.651,5.611,11.175,8.781
		c0.562,0.5,1.113,1.019,1.651,1.516C120.344,52.772,123.859,57.971,120.54,60.636z"/>
    <path fill="#CFD8DC"
          d="M130.938,68.706c-0.057,0.093-0.129,0.189-0.196,0.276c-0.135,0.198-0.274,0.382-0.427,0.569
		c-0.139,0.183-0.281,0.352-0.438,0.524c-0.148,0.178-0.305,0.35-0.462,0.522c-0.25,0.281-0.504,0.547-0.781,0.811
		c-0.246,0.233-0.497,0.474-0.767,0.705c-0.293,0.268-0.61,0.533-0.931,0.783c-0.317,0.265-0.653,0.519-1.003,0.777
		c-0.345,0.248-0.705,0.5-1.074,0.746c-0.369,0.246-0.753,0.496-1.156,0.734c-0.269,0.167-0.538,0.334-0.841,0.493
		c-0.222,0.132-0.459,0.267-0.705,0.397c-0.026,0.022-0.068,0.048-0.119,0.068c-0.243,0.145-0.504,0.278-0.765,0.411
		c-0.261,0.133-0.538,0.27-0.814,0.407c-0.297,0.15-0.588,0.29-0.895,0.434c-0.119,0.068-0.241,0.12-0.368,0.183
		c-0.271,0.128-0.556,0.259-0.851,0.384c-0.214,0.098-0.438,0.191-0.676,0.287c-0.331,0.142-0.661,0.284-1.001,0.42
		c-1.326,0.552-2.758,1.09-4.291,1.605c-0.388,0.132-0.771,0.254-1.159,0.386c-0.66,0.22-1.345,0.438-2.048,0.645
		c-0.598,0.182-1.226,0.37-1.858,0.544c-0.763,0.221-1.556,0.449-2.377,0.66c-0.611,0.161-1.246,0.32-1.888,0.488
		c-0.485,0.123-0.965,0.237-1.46,0.355c-0.851,0.218-1.73,0.419-2.618,0.614c-0.645,0.153-1.293,0.291-1.956,0.433
		c-0.564,0.126-1.137,0.246-1.714,0.352c-0.738,0.159-1.48,0.304-2.237,0.452c-0.552,0.107-1.09,0.211-1.652,0.313
		c-1.12,0.218-2.244,0.422-3.401,0.618c-0.577,0.105-1.163,0.205-1.749,0.304c-3.546,0.605-7.306,1.181-11.307,1.735
		c-1.089,0.148-2.136,0.309-3.164,0.482c-0.764,0.118-1.509,0.247-2.236,0.388c-2.139,0.397-4.143,0.826-6.025,1.304
		c-0.345,0.082-0.68,0.169-1.016,0.257c-0.842,0.224-1.659,0.449-2.434,0.689c-0.306,0.08-0.593,0.172-0.88,0.264
		c-2.018,0.638-3.848,1.302-5.486,2.007c-0.244,0.105-0.473,0.207-0.688,0.306c-0.167,0.063-0.325,0.133-0.473,0.207
		c-0.306,0.144-0.607,0.279-0.889,0.425c-1.257,0.607-2.384,1.231-3.389,1.866c-0.248,0.154-0.497,0.308-0.727,0.474
		c-0.478,0.319-0.918,0.638-1.333,0.958c-0.179,0.145-0.353,0.282-0.526,0.418c-0.343,0.287-0.672,0.571-0.967,0.863
		c-0.117,0.107-0.225,0.22-0.327,0.323c-0.117,0.107-0.225,0.22-0.318,0.329c-0.134,0.135-0.265,0.285-0.39,0.425
		c-0.011,0.019-0.032,0.031-0.038,0.041c-0.136,0.159-0.264,0.324-0.385,0.479c-0.11,0.137-0.211,0.28-0.311,0.422
		c-0.044,0.05-0.078,0.106-0.112,0.161c-0.034,0.056-0.078,0.106-0.112,0.161c0.334-0.626,0.73-1.227,1.148-1.801
		c4.756-6.514,14.011-10.192,24.947-12.987c0.72-0.171,1.436-0.357,2.17-0.531c14.84-3.564,32.361-5.745,45.968-11.066
		c0.832-0.332,1.654-0.67,2.455-1.034c5.389-2.349,10.038-5.282,13.499-9.113C132.457,66.2,131.804,67.487,130.938,68.706z"/>
    <path fill="#CFD8DC"
          d="M111.813,134.093c-5.219-4.636-12.1-5.972-21.058-4.082c-0.15,0.036-0.309,0.065-0.453,0.092
		c-0.798,0.174-1.594,0.323-2.379,0.454c-0.577,0.105-1.148,0.201-1.719,0.297c-0.571,0.096-1.146,0.177-1.711,0.264
		c-1.14,0.168-2.263,0.308-3.389,0.433c-1.126,0.125-2.245,0.216-3.358,0.298c-0.108,0.01-0.208,0.026-0.311,0.026
		c-0.32,0.021-0.64,0.041-0.959,0.061c-0.148,0.011-0.296,0.023-0.447,0.019c-0.507,0.033-1.008,0.057-1.513,0.066
		c-0.565,0.023-1.124,0.037-1.692,0.045c-2.161,0.038-4.288-0.019-6.355-0.154c-0.418-0.027-0.837-0.055-1.249-0.091
		c-0.379-0.029-0.752-0.067-1.116-0.099c-0.534-0.047-1.056-0.113-1.585-0.17c-0.552-0.059-1.093-0.136-1.634-0.214
		c-0.744-0.1-1.477-0.219-2.19-0.351c-0.405-0.07-0.8-0.135-1.193-0.223c-0.481-0.092-0.947-0.187-1.417-0.297
		c-0.49-0.097-0.969-0.213-1.433-0.333c-0.445-0.108-0.885-0.225-1.325-0.343c-0.455-0.114-0.907-0.252-1.35-0.384
		c-0.434-0.127-0.862-0.262-1.285-0.408c-0.395-0.128-0.774-0.26-1.163-0.397c-0.091-0.018-0.171-0.054-0.251-0.091
		c-0.346-0.124-0.701-0.253-1.048-0.377c-0.577-0.227-1.153-0.455-1.703-0.704c-0.368-0.15-0.721-0.304-1.078-0.472
		c-0.375-0.18-0.741-0.355-1.102-0.538c-0.689-0.335-1.358-0.683-2.009-1.059c-1.084-0.63-2.126-1.285-3.116-1.984
		c-0.046-0.029-0.078-0.061-0.124-0.089c-0.165-0.127-0.345-0.251-0.519-0.384c-0.059-0.049-0.128-0.104-0.202-0.15
		c-0.028-0.017-0.046-0.029-0.059-0.049c-0.056-0.034-0.115-0.084-0.174-0.133c-0.152-0.106-0.289-0.216-0.42-0.336
		c-0.283-0.226-0.561-0.461-0.829-0.69c-0.287-0.241-0.577-0.496-0.852-0.756c-0.832-0.768-1.607-1.566-2.321-2.403
		c1.697,1.046,3.394,1.989,5.07,2.842c1.025,0.517,2.053,1.009,3.064,1.466c17.445,7.806,33.747,6.295,46.51,5.521
		c0.767-0.04,1.518-0.076,2.246-0.114C100.381,127.599,107.841,127.934,111.813,134.093z"/>
    <path fill="#CFD8DC"
          d="M113.791,12.875c-0.237,0.302-0.459,0.6-0.657,0.9c-0.207,0.295-0.409,0.58-0.595,0.862
		c-0.346-0.29-0.707-0.576-1.089-0.85c-1.132-0.864-2.419-1.682-3.804-2.446c0.945-0.288,1.9-0.57,2.852-0.828
		c0.677,0.417,1.328,0.857,1.934,1.307C112.91,12.165,113.358,12.518,113.791,12.875z"/>
    <path fill="#CFD8DC"
          d="M122.954,40.52c-2.205,2.353-4.957,4.867-8.12,6.988c-0.652,0.456-1.341,0.889-2.043,1.301
		c-0.537-0.561-1.061-1.102-1.564-1.616c0.67-0.381,1.312-0.779,1.956-1.201c3.253-2.142,6.081-4.738,8.253-7.059
		C121.942,39.461,122.448,39.991,122.954,40.52z"/>
    <path fill="#CFD8DC"
          d="M122.861,75.644c-0.222,0.132-0.459,0.267-0.705,0.397c-0.026,0.022-0.068,0.048-0.119,0.068
		c-0.237,0.136-0.504,0.278-0.765,0.411c-0.261,0.133-0.538,0.27-0.814,0.407c-1.187-0.565-2.357-1.222-3.498-1.925
		c-3.315-2.056-6.37-4.616-8.713-7.148c0.807-0.271,1.565-0.546,2.263-0.807c2.408,2.469,5.528,5.006,8.905,6.92
		C120.547,74.602,121.695,75.169,122.861,75.644z"/>
    <path fill="#CFD8DC"
          d="M71.788,88.62c-0.764,0.118-1.509,0.247-2.236,0.388c-0.282-0.788-0.523-1.602-0.73-2.408
		c-0.921-3.614-1.126-7.374-0.76-10.604c0.736-0.135,1.491-0.259,2.239-0.373c-0.392,3.086-0.232,6.857,0.691,10.447
		C71.214,86.935,71.48,87.79,71.788,88.62z"/>
    <path fill="#CFD8DC"
          d="M44.075,99.698c-0.134,0.135-0.265,0.285-0.39,0.425c-0.006,0.009-0.017,0.028-0.038,0.041
		c-0.136,0.159-0.264,0.324-0.385,0.479c-0.116,0.146-0.216,0.289-0.311,0.422c-0.044,0.05-0.078,0.106-0.112,0.161
		c-0.044,0.05-0.078,0.106-0.112,0.161c-3.684-2.142-6.759-5.688-9.124-9.577c0.476-0.564,0.994-1.115,1.537-1.664
		c0.425,0.723,0.865,1.442,1.331,2.139c1.737,2.581,4.214,5.477,7.403,7.301C43.94,99.628,44.01,99.658,44.075,99.698z"/>
    <path fill="#CFD8DC"
          d="M54.796,116.569c-3.824,2.302-8.295,4.717-12.94,6.129c-0.88,0.264-1.751,0.495-2.634,0.681
		c-0.046-0.029-0.078-0.061-0.124-0.089c-0.18-0.124-0.354-0.256-0.519-0.384c-0.074-0.046-0.143-0.101-0.202-0.15
		c-0.028-0.017-0.046-0.029-0.059-0.049c-0.065-0.04-0.124-0.089-0.174-0.133c-0.152-0.106-0.289-0.216-0.42-0.336
		c-0.283-0.226-0.561-0.461-0.829-0.69c0.629-0.086,1.269-0.19,1.897-0.316c4.682-0.993,9.323-3.291,13.357-5.642
		C52.983,115.938,53.869,116.266,54.796,116.569z"/>
    <path fill="#CFD8DC"
          d="M90.302,130.103c-0.798,0.174-1.594,0.323-2.379,0.454c0.208-0.794,0.355-1.573,0.442-2.338
		c0.407-3.589-0.492-6.907-3.111-10.466c0.775-0.137,1.566-0.277,2.364-0.45c2.498,3.651,3.369,7.118,2.993,10.803
		C90.553,128.761,90.45,129.427,90.302,130.103z"/>
    <path fill="#CFD8DC"
          d="M123.446,147.865c-0.815,0.803-1.692,1.479-2.612,2.038c-0.214-0.733-0.454-1.444-0.694-2.155
		c0.633-0.404,1.229-0.894,1.787-1.446c2.974-2.915,4.935-7.594,6.035-12.701c0.553,0.93,1.075,1.891,1.56,2.868
		C128.326,140.838,126.399,144.962,123.446,147.865z"/>
    <path fill="#CFD8DC"
          d="M136.041,158.058c-3.984-0.036-8.397,1.519-12.626,3.917c-0.116-0.788-0.222-1.571-0.368-2.351
		c4.632-2.47,8.99-3.624,12.631-3.696L136.041,158.058z"/>
</g>
  <g id="Astronautas"
     class="upDown">
	<g id="Left_Leg">
		<path fill="#FFFFFF"
          d="M115.94,206.022c-1.421,0.417-2.821,0.923-4.189,1.501c-5.108,2.163-9.784,5.451-13.621,9.767
			c-1.503,1.671-4.237,0.666-4.305-1.591c-0.208-7.244,1.642-14.627,5.729-21.26c0.543-0.881,1.117-1.73,1.714-2.553
			c2.773-3.794,6.141-6.954,9.887-9.419C110.204,190.636,111.89,198.907,115.94,206.022z"/>
    <path fill="#CFD8DC"
          d="M102.942,216.882l-0.46,3.917c-0.158,1.342-1.221,2.325-2.369,2.19l-13.089-1.537
			c-1.148-0.135-1.945-1.336-1.788-2.678l0.132-1.125c0.339-2.889,2.613-4.992,5.085-4.702l10.692,1.255
			C102.293,214.337,103.101,215.529,102.942,216.882z"/>
    <path fill="#FFFFFF"
          d="M102.942,216.882l-0.356,3.03c-2.571,0.4-5.581,0.453-8.756,0.08c-3.175-0.373-6.081-1.12-8.49-2.105
			l0.028-0.238c0.339-2.889,2.613-4.992,5.085-4.702l10.692,1.255C102.293,214.337,103.101,215.529,102.942,216.882z"/>
    <path fill="#FFFFFF"
          d="M89.101,220.709l-0.114,0.974l-1.833-0.215l0.114-0.974c0.07-0.595,0.542-1.022,1.042-0.964
			C88.82,219.59,89.171,220.114,89.101,220.709z"/>
    <path fill="#FFFFFF"
          d="M92.711,221.133l-0.114,0.974l-1.833-0.215l0.114-0.974c0.07-0.595,0.533-1.023,1.042-0.964
			C92.43,220.014,92.781,220.538,92.711,221.133z"/>
    <path fill="#FFFFFF"
          d="M96.322,221.557l-0.114,0.974l-1.833-0.215l0.114-0.974c0.07-0.595,0.533-1.023,1.042-0.964
			C96.031,220.437,96.391,220.962,96.322,221.557z"/>
    <path fill="#FFFFFF"
          d="M99.919,222.012l-0.111,0.941l-1.824-0.214l0.111-0.941c0.069-0.584,0.532-1.013,1.041-0.953
			C99.636,220.904,99.987,221.428,99.919,222.012z"/>
    <path opacity="0.6"
          fill="#CFD8DC"
          d="M115.94,206.022c-1.421,0.417-2.821,0.923-4.189,1.501
			c-4.675-4.148-8.346-9.468-10.483-15.636c2.773-3.794,6.141-6.954,9.887-9.419C110.204,190.636,111.89,198.907,115.94,206.022z"/>
	</g>
    <g id="Right_Leg">
		<path fill="#FFFFFF"
          d="M141.119,220.053c-4.087,6.632-9.86,11.598-16.424,14.669c-2.046,0.953-4.174-1.036-3.347-3.125
			c2.152-5.444,2.975-11.183,2.577-16.79c-0.096-1.403-0.27-2.802-0.519-4.184c8.176,0.418,16.322-1.793,23.19-6.315
			c-0.481,4.208-1.684,8.382-3.622,12.358C142.423,217.811,141.805,218.94,141.119,220.053z"/>
      <path fill="#CFD8DC"
            d="M119.543,227.112l-3.292,2.172c-1.128,0.744-1.528,2.136-0.892,3.1l7.257,11.001
			c0.636,0.965,2.068,1.137,3.196,0.393l0.946-0.624c2.428-1.602,3.284-4.578,1.914-6.656l-5.928-8.986
			C122.108,226.547,120.68,226.362,119.543,227.112z"/>
      <path fill="#FFFFFF"
            d="M119.543,227.112l-2.547,1.68c0.799,2.477,2.104,5.188,3.865,7.857c1.76,2.669,3.734,4.928,5.697,6.637
			l0.2-0.132c2.428-1.602,3.284-4.578,1.914-6.656l-5.928-8.986C122.108,226.547,120.68,226.362,119.543,227.112z"/>
      <path fill="#FFFFFF"
            d="M122.347,241.195l-0.819,0.54l1.016,1.54l0.819-0.54c0.5-0.33,0.669-0.944,0.392-1.364
			C123.474,240.944,122.848,240.865,122.347,241.195z"/>
      <path fill="#FFFFFF"
            d="M120.346,238.161l-0.819,0.54l1.016,1.54l0.819-0.54c0.5-0.33,0.675-0.936,0.392-1.364
			C121.472,237.91,120.846,237.831,120.346,238.161z"/>
      <path fill="#FFFFFF"
            d="M118.344,235.127l-0.819,0.54l1.016,1.54l0.819-0.54c0.5-0.33,0.675-0.936,0.392-1.364
			C119.475,234.883,118.844,234.797,118.344,235.127z"/>
      <path fill="#FFFFFF"
            d="M116.32,232.119l-0.791,0.522l1.011,1.533l0.791-0.522c0.491-0.324,0.665-0.93,0.383-1.358
			C117.437,231.873,116.811,231.795,116.32,232.119z"/>
      <path opacity="0.6"
            fill="#CFD8DC"
            d="M142.975,216.666c-0.268,0.04-0.53,0.07-0.801,0.095l-1.712,0.161
			c-5.725,0.568-11.359-0.217-16.537-2.115c-0.096-1.403-0.27-2.802-0.519-4.184c8.176,0.418,16.322-1.793,23.19-6.315
			C146.116,208.517,144.913,212.69,142.975,216.666z"/>
	</g>
    <g id="Left_Arm">
		<path fill="#FFFFFF"
          d="M150.117,161.109c-4.814-2.966-9.976-4.932-15.225-5.926c-4.439-0.854-8.935-1.014-13.36-0.541
			c-2.039,0.215-4.068,0.578-6.058,1.066l-0.231,0.063c-0.555,0.132-1.106,0.278-1.644,0.446l-0.251,0.075
			c-1.678,0.502-3.341,1.102-4.949,1.801c-0.676,0.287-1.334,0.586-1.984,0.915c-1.754,0.852-3.454,1.826-5.091,2.904
			c-0.333,0.23-0.682,0.463-1.021,0.702c-0.626,0.434-1.233,0.879-1.823,1.359c-1.233,0.942-2.421,1.976-3.555,3.069
			c-0.129-0.745-0.358-1.372-0.653-1.848c-0.031-0.198-0.053-0.391-0.09-0.58c-0.176-1.043-0.518-1.88-0.98-2.46
			c-0.497-0.626-1.017-0.78-1.366-0.816c-0.859-0.081-2.432,0.562-3.881,2.165c-0.712,0.803-2.026,2.54-2.475,5.117
			c-0.026,0.125-0.042,0.255-0.055,0.401c-1.855-1.975-2.535-4.838-2.437-4.996c2.391-3.735,5.452-7.186,8.988-10.267
			c1.484-1.287,3.051-2.522,4.689-3.663c0.663-0.474,1.345-0.937,2.04-1.379c2.586-1.683,5.311-3.177,8.146-4.476
			c0.705-0.334,1.417-0.637,2.138-0.935c4.937-2.051,10.129-3.498,15.298-4.203l0.015-0.004c7.983-1.096,15.935-0.419,22.867,2.496
			c1.599,0.665,3.139,1.448,4.614,2.357C169.602,154.93,162.676,168.848,150.117,161.109z"/>
      <path fill="#CFD8DC"
            d="M115.474,155.708l-0.231,0.063l-1.644,0.446l-0.251,0.075c-0.604-4.711-3.558-9.283-6.497-12.053
			c0.705-0.334,1.417-0.637,2.138-0.935C111.979,146.362,114.758,150.928,115.474,155.708z"/>
      <path fill="#CFD8DC"
            d="M134.892,155.182c-4.439-0.854-8.935-1.014-13.36-0.541c-7.576,0.809-14.905,3.524-21.229,7.971
			c-0.626,0.434-1.233,0.879-1.823,1.359l-0.656-0.161c18.141-11.205,20.248-23.575,26.463-24.71l0.015-0.004
			c7.983-1.096,15.935-0.419,22.867,2.496C145.737,145.599,138.282,151.487,134.892,155.182z"/>
      <path fill="#CFD8DC"
            d="M101.323,161.911c-0.333,0.23-0.682,0.463-1.021,0.702c-0.626,0.434-1.233,0.879-1.823,1.359
			c-1.233,0.942-2.421,1.976-3.555,3.069c-0.129-0.745-0.358-1.372-0.653-1.848c-0.031-0.198-0.053-0.391-0.09-0.58
			c-0.176-1.043-0.518-1.88-0.98-2.46c-0.497-0.626-1.017-0.78-1.366-0.816c-0.859-0.081-2.432,0.562-3.881,2.165
			c-0.712,0.803-2.026,2.54-2.475,5.117c-0.026,0.125-0.042,0.255-0.055,0.401c-1.855-1.975-2.535-4.838-2.437-4.996
			c2.391-3.735,5.452-7.186,8.988-10.267c0.191,0.002,0.375,0.014,0.56,0.025c2.567,0.238,4.893,1.53,6.576,3.617
			c0.44,0.553,0.888,1.238,1.308,2.073C100.759,160.168,101.078,160.979,101.323,161.911z"/>
      <path fill="#FFFFFF"
            d="M97.785,174.755c-1.795-0.223-3.063-1.862-2.837-3.642c0.198-1.567,0.184-2.958-0.025-4.072
			c-0.15-0.899-0.448-1.62-0.844-2.12c-0.427-0.532-0.88-0.67-1.18-0.702c-0.737-0.07-2.093,0.489-3.341,1.87
			c-0.613,0.684-1.745,2.188-2.128,4.409c-0.196,1.044-0.219,2.245,0.047,3.6c0.342,1.772-0.809,3.494-2.572,3.841
			c-0.847,0.169-1.672-0.007-2.349-0.424c-0.751-0.463-1.311-1.217-1.492-2.148c-0.932-4.759,0.205-9.472,3.137-13.066
			c0.165-0.205,0.334-0.396,0.508-0.596c2.522-2.784,5.81-4.278,8.798-3.998c2.212,0.211,4.217,1.319,5.666,3.121
			c0.383,0.479,0.767,1.061,1.128,1.783c0.978,1.985,1.677,4.962,1.134,9.312C101.21,173.704,99.58,174.978,97.785,174.755z"/>
      <path fill="#CFD8DC"
            d="M108.398,158.092c-0.676,0.287-1.334,0.586-1.984,0.915c-2.546-4.384-6.377-7.18-9.75-8.913
			c0.663-0.474,1.345-0.937,2.04-1.379C102.142,150.654,105.865,153.626,108.398,158.092z"/>
	</g>
    <g id="Right_Arm">
		<path fill="#FFFFFF"
          d="M148.153,159.899c4.814,2.966,8.89,6.694,12.139,10.935c2.759,3.581,4.923,7.525,6.49,11.691
			c0.725,1.918,1.312,3.893,1.771,5.891l0.048,0.234c0.132,0.555,0.248,1.113,0.341,1.669l0.046,0.258
			c0.307,1.725,0.518,3.48,0.616,5.23c0.047,0.733,0.076,1.455,0.075,2.183c0.027,1.949-0.078,3.907-0.305,5.853
			c-0.055,0.401-0.107,0.817-0.168,1.228c-0.106,0.754-0.231,1.496-0.395,2.24c-0.287,1.525-0.677,3.051-1.143,4.555
			c0.724-0.219,1.386-0.298,1.945-0.248c0.191-0.061,0.373-0.128,0.559-0.18c1.011-0.311,1.912-0.383,2.638-0.23
			c0.783,0.162,1.154,0.557,1.343,0.853c0.459,0.73,0.592,2.425-0.189,4.44c-0.397,0.997-1.358,2.951-3.458,4.511
			c-0.1,0.079-0.209,0.153-0.333,0.23c2.598,0.769,5.461,0.089,5.558-0.069c2.261-3.815,3.968-8.101,5.13-12.644
			c0.482-1.904,0.881-3.86,1.164-5.835c0.125-0.806,0.232-1.623,0.315-2.442c0.341-3.066,0.45-6.173,0.336-9.289
			c-0.019-0.779-0.068-1.552-0.126-2.33c-0.387-5.332-1.429-10.62-3.124-15.554l-0.004-0.015c-2.61-7.623-6.79-14.422-12.51-19.304
			c-1.313-1.129-2.705-2.153-4.18-3.062C144.913,139.716,135.594,152.16,148.153,159.899z"/>
      <path fill="#CFD8DC"
            d="M168.553,188.415l0.048,0.234l0.341,1.669l0.046,0.258c4.479-1.578,9.891-0.995,13.687,0.384
			c-0.019-0.779-0.068-1.552-0.126-2.33C178.471,187.335,173.143,186.905,168.553,188.415z"/>
      <path fill="#CFD8DC"
            d="M160.292,170.834c2.759,3.581,4.923,7.525,6.49,11.691c2.683,7.131,3.554,14.898,2.424,22.546
			c-0.106,0.754-0.231,1.496-0.395,2.24l0.439,0.514c1.853-21.242,11.955-28.685,10.175-34.747l-0.004-0.015
			c-2.61-7.623-6.79-14.422-12.51-19.304C163.976,156.838,162.068,166.144,160.292,170.834z"/>
      <path fill="#CFD8DC"
            d="M169.374,203.844c-0.055,0.401-0.107,0.817-0.168,1.228c-0.106,0.754-0.231,1.496-0.395,2.24
			c-0.287,1.525-0.677,3.051-1.143,4.555c0.724-0.219,1.386-0.298,1.945-0.248c0.191-0.061,0.373-0.128,0.559-0.18
			c1.011-0.311,1.912-0.383,2.638-0.23c0.783,0.162,1.154,0.557,1.343,0.853c0.459,0.73,0.592,2.425-0.189,4.44
			c-0.397,0.997-1.358,2.951-3.458,4.511c-0.1,0.079-0.209,0.153-0.333,0.23c2.598,0.769,5.461,0.089,5.558-0.069
			c2.261-3.815,3.968-8.101,5.13-12.644c-0.088-0.169-0.181-0.329-0.275-0.489c-1.366-2.186-3.567-3.682-6.187-4.248
			c-0.692-0.145-1.505-0.236-2.44-0.236C171.184,203.564,170.316,203.643,169.374,203.844z"/>
      <path fill="#FFFFFF"
            d="M159.491,212.778c1.006,1.503,3.04,1.899,4.528,0.896c1.311-0.881,2.559-1.495,3.648-1.809
			c0.87-0.27,1.648-0.328,2.273-0.199c0.667,0.142,0.994,0.485,1.157,0.739c0.394,0.627,0.504,2.09-0.169,3.825
			c-0.335,0.856-1.17,2.543-2.982,3.883c-0.845,0.644-1.907,1.205-3.237,1.576c-1.736,0.491-2.757,2.294-2.275,4.024
			c0.229,0.832,0.758,1.491,1.435,1.908c0.751,0.463,1.676,0.623,2.589,0.367c4.67-1.307,8.368-4.442,10.261-8.676
			c0.109-0.24,0.204-0.476,0.304-0.721c1.353-3.505,1.209-7.113-0.385-9.656c-1.183-1.881-3.074-3.174-5.335-3.658
			c-0.6-0.127-1.293-0.208-2.1-0.206c-2.213,0.019-5.187,0.733-8.828,3.173C158.89,209.247,158.485,211.275,159.491,212.778z"/>
      <path fill="#CFD8DC"
            d="M169.603,195.807c0.047,0.733,0.076,1.455,0.075,2.183c5.061,0.303,9.281,2.468,12.345,4.702
			c0.125-0.806,0.232-1.623,0.315-2.442C179.06,198.051,174.732,196.062,169.603,195.807z"/>
	</g>
    <g id="Neck">
		<polygon fill="#CFD8DC"
             points="168.655,157.002 143.492,141.497 149.851,135.332 171.303,148.551 		"/>
      <polygon fill="#FFFFFF"
               points="147.399,146.817 145.745,145.797 152.789,136.443 154.443,137.462 		"/>

      <rect x="149.422"
            y="143.836"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -49.4757 201.0141)"
            fill="#FFFFFF"
            width="11.659"
            height="1.943"/>

      <rect x="154.58"
            y="147.015"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -49.7295 206.9158)"
            fill="#FFFFFF"
            width="11.659"
            height="1.943"/>
      <polygon fill="#FFFFFF"
               points="163.8,156.923 162.145,155.903 167.334,145.406 168.989,146.425 		"/>
	</g>
    <g id="Body">
		<path fill="#FFFFFF"
          d="M137.947,213.465l-1.338,0.152c-13.644,1.546-26.459-6.829-30.521-19.945l0,0
			c-1.815-5.859-1.696-12.147,0.338-17.934l6.985-19.874c5.281-15.025,23.091-21.33,36.649-12.976l14.393,8.869
			c13.559,8.355,15.933,27.098,4.887,38.57l-14.022,14.562C150.702,209.684,144.559,212.716,137.947,213.465z"/>
      <path fill="#CFD8DC"
            d="M121.113,205.895c-6.219-3.832-8.549-11.7-5.42-18.3l5.064-10.682c1.897-4.001,5.421-7.018,9.667-8.276
			c4.246-1.258,8.844-0.647,12.615,1.677c3.771,2.323,6.383,6.156,7.169,10.514c0.786,4.359-0.325,8.862-3.046,12.356l-7.265,9.326
			C135.407,208.272,127.332,209.727,121.113,205.895z M141.895,172.169c-3.244-1.999-7.199-2.524-10.853-1.442
			c-3.653,1.082-6.685,3.677-8.316,7.12l-5.064,10.682c-2.652,5.594-0.678,12.263,4.594,15.511
			c5.272,3.248,12.116,2.014,15.921-2.87l7.265-9.326c2.342-3.005,3.297-6.88,2.621-10.629
			C147.387,177.465,145.139,174.168,141.895,172.169z"/>
      <path fill="#CFD8DC"
            d="M130.13,193.339l1.597-2.591l-1.855-1.143l-1.597,2.591c-0.316,0.512-0.156,1.183,0.356,1.499l0,0
			C129.143,194.011,129.814,193.851,130.13,193.339z"/>
      <path fill="#CFD8DC"
            d="M149.428,162.021l1.334-2.165c0.316-0.512,0.156-1.184-0.356-1.499c-0.512-0.316-1.183-0.156-1.499,0.356
			l-1.334,2.165L149.428,162.021z"/>

      <rect x="130.96"
            y="185.472"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -95.5878 201.9421)"
            fill="#CFD8DC"
            width="4.134"
            height="2.179"/>

      <rect x="134.271"
            y="180.097"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -89.4382 202.2066)"
            fill="#CFD8DC"
            width="4.134"
            height="2.179"/>

      <rect x="137.583"
            y="174.723"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -83.2885 202.471)"
            fill="#CFD8DC"
            width="4.134"
            height="2.179"/>

      <rect x="144.206"
            y="163.975"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -70.9892 202.9999)"
            fill="#CFD8DC"
            width="4.134"
            height="2.179"/>

      <rect x="140.895"
            y="169.349"
            transform="matrix(0.5246 -0.8513 0.8513 0.5246 -77.1388 202.7355)"
            fill="#CFD8DC"
            width="4.134"
            height="2.179"/>
	</g>

    <ellipse id="Red_Light"
             class="blink1"
             transform="matrix(0.5246 -0.8513 0.8513 0.5246 -72.1376 223.1236)"
             fill="#E53935"
             cx="163.718"
             cy="176.154"
             rx="1.659"
             ry="1.659"/>

    <ellipse id="Green_Light"
             class="blink2"
             transform="matrix(0.5246 -0.8513 0.8513 0.5246 -71.9205 218.0747)"
             fill="#4CAF50"
             cx="159.306"
             cy="173.436"
             rx="1.659"
             ry="1.659"/>

    <ellipse id="Yellow_Light"
             class="blink3"
             transform="matrix(0.5246 -0.8513 0.8513 0.5246 -71.8498 213.1346)"
             fill="#FFEB3B"
             cx="154.918"
             cy="170.902"
             rx="1.659"
             ry="1.659"/>
    <g id="Helmet">
		<path fill="#FFFFFF"
          d="M158.956,82.858l-14.153,20.663l-2.638-1.805c-0.955-0.652-1.628-1.553-1.999-2.55
			c-0.66-1.712-0.432-3.722,0.788-5.287l9.319-12.008c1.226-1.574,3.33-2.082,5.114-1.341c0.27,0.102,0.535,0.253,0.789,0.422
			L158.956,82.858z"/>
      <path fill="#FFFFFF"
            d="M207.972,140.948c-1.066,0.111-2.183-0.091-3.195-0.651l-2.798-1.545l12.094-21.932l2.952,1.627
			c0.265,0.151,0.519,0.32,0.736,0.53c1.469,1.251,1.952,3.353,1.098,5.156l-6.527,13.729
			C211.482,139.655,209.795,140.753,207.972,140.948z"/>
      <path fill="#CFD8DC"
            d="M156.176,80.952l2.78,1.905l-14.153,20.663l-2.638-1.805c-0.955-0.652-1.628-1.553-1.999-2.55
			c0.126-0.204,0.261-0.402,0.411-0.604l6.919-9.775c2.254-3.18,4.922-5.951,7.89-8.256
			C155.656,80.632,155.922,80.783,156.176,80.952z"/>
      <path fill="#CFD8DC"
            d="M207.972,140.948c-1.066,0.111-2.183-0.091-3.195-0.651l-2.798-1.545l12.094-21.932l2.952,1.627
			c0.265,0.151,0.519,0.32,0.736,0.53c-0.724,3.688-1.994,7.307-3.822,10.75l-5.621,10.576
			C208.211,140.519,208.089,140.738,207.972,140.948z"/>
      <g>
			<path fill="#FFFFFF"
            d="M167.179,149.437l-16.27-10.026c-13.784-8.494-17.53-26.863-8.173-40.076l6.534-9.226
				c9.289-13.117,26.003-18.71,41.316-13.825l0,0c5.733,1.829,10.932,5.032,15.143,9.331l0,0
				c11.248,11.482,13.766,28.926,6.226,43.12l-5.304,9.985C199.055,153.018,180.962,157.93,167.179,149.437z"/>
        <path fill="#5E35B1"
              d="M175.984,143.242l-23.505-14.484c-1.994-1.229-3.588-2.838-4.755-4.683
				c-3.128-4.935-3.184-11.51,0.482-16.609l8.28-11.548c5.573-7.777,15.993-10.315,24.521-5.981l7.413,3.775
				c0.945,0.48,1.855,1.04,2.709,1.669l6.714,4.931c0.046,0.029,0.087,0.066,0.128,0.104c3.706,2.77,6.168,6.668,7.2,10.912
				c0.2,0.776,0.34,1.566,0.43,2.376c0.118,0.93,0.157,1.863,0.128,2.805c-0.092,2.849-0.811,5.72-2.213,8.388l-6.583,12.594
				C192.934,145.125,183.312,147.758,175.984,143.242z"/>
        <path opacity="0.1"
              fill="#FFFFFF"
              d="M203.074,125.739c-6.036,0.99-12.248,1.309-16.333-0.287
				c-10.366-4.071-4.497-13.7-13.698-18.103c-7.057-3.376-12.552-6.007-14.814-13.531c5.82-6.102,15.07-7.799,22.779-3.881
				l7.413,3.775c0.945,0.48,1.855,1.04,2.709,1.669l6.714,4.931c7.705,5.669,10.113,16.112,5.672,24.586L203.074,125.739z"/>

        <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -18.6797 195.8615)"
                 opacity="0.1"
                 fill="#FFFFFF"
                 cx="166.036"
                 cy="114.657"
                 rx="1.522"
                 ry="1.522"/>

        <ellipse transform="matrix(0.5246 -0.8513 0.8513 0.5246 -24.8271 190.2484)"
                 opacity="0.1"
                 fill="#FFFFFF"
                 cx="157.936"
                 cy="117.355"
                 rx="5.48"
                 ry="5.48"/>
        <path opacity="0.1"
              fill="#FFFFFF"
              d="M205.172,111.329l-45.893,21.62l-6.799-4.19c-1.994-1.229-3.588-2.838-4.755-4.683
				l50.247-23.659C201.678,103.186,204.14,107.084,205.172,111.329z"/>
        <path opacity="0.1"
              fill="#FFFFFF"
              d="M205.729,116.509l-41.45,19.519l-2.616-1.612l43.939-20.712
				C205.719,114.635,205.759,115.568,205.729,116.509z"/>
		</g>
	</g>
</g>
  <g>

		<rect x="38.839"
          y="244.845"
          transform="matrix(0.9502 -0.3118 0.3118 0.9502 -77.5894 30.262)"
          fill="#1565C0"
          width="34.027"
          height="25.907"/>

    <rect x="54.395"
          y="260.553"
          transform="matrix(-0.3118 -0.9502 0.9502 -0.3118 -166.1943 401.7263)"
          opacity="0.2"
          fill="#ECEFF1"
          width="16"
          height="1"/>

    <rect x="36.332"
          y="245.246"
          transform="matrix(0.9502 -0.3118 0.3118 0.9502 -75.3327 29.0998)"
          fill="#ECEFF1"
          width="34.027"
          height="9.827"/>
    <polygon opacity="0.2"
             fill="#ECEFF1"
             points="49.541,256.851 51.879,263.978 41.131,267.504 41.443,268.454 52.191,264.928
		54.529,272.054 55.479,271.742 50.491,256.54 	"/>
    <path fill="#AAD3EF"
          d="M76.687,265.121l-33.281,10.92l-8.388-25.565l33.281-10.92L76.687,265.121z M44.044,274.779l31.381-10.297
		l-7.765-23.665l-31.381,10.297L44.044,274.779z"/>

    <rect x="37.864"
          y="254.328"
          transform="matrix(0.9502 -0.3118 0.3118 0.9502 -76.7119 29.8101)"
          fill="#AAD3EF"
          width="34.027"
          height="1"/>

    <rect x="70.445"
          y="245.283"
          transform="matrix(0.9502 -0.3118 0.3118 0.9502 -72.5178 37.9538)"
          fill="#AAD3EF"
          width="24"
          height="1"/>

    <rect x="48.763"
          y="237.709"
          transform="matrix(-0.3118 -0.9502 0.9502 -0.3118 -154.4995 364.5077)"
          fill="#AAD3EF"
          width="12"
          height="1"/>

    <rect x="15.31"
          y="263.374"
          transform="matrix(0.9502 -0.3118 0.3118 0.9502 -80.9059 21.6664)"
          fill="#AAD3EF"
          width="24"
          height="1"/>
</g>
</svg>
