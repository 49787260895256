<svg [ngClass]="{
			'nm-he-300p': this.model.isDoodleActive,
			'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
			'nm-wi-300p': this.model.isDoodleActive,
			'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
			'fillToScreen': this.model.isDoodleActive === false
		}"
     version="1.1"
     id="auto-motive"
     xmlns="http://www.w3.org/2000/svg"
     x="0px"
     y="0px"
     viewBox="0 0 300 300"
     style="enable-background:new 0 0 300 300;"
     xml:space="preserve">
        <g id="background_1_">
        <path id="background"
              class="st0"
              d="M300,150c0,17.98-3.16,35.22-8.96,51.19c-1.97,5.42-4.24,10.7-6.8,15.81
            c-0.67,1.35-1.36,2.68-2.07,4c-0.73,1.35-1.47,2.68-2.23,4c-0.78,1.35-1.58,2.68-2.41,4c-0.11,0.18-0.23,0.37-0.34,0.55l-0.36,0.57
            c-2.62,4.15-5.44,8.15-8.45,12.01C240.93,277.34,198.11,300,150,300c-42.28,0-80.47-17.49-107.74-45.64
            c-1.65-1.7-3.26-3.44-4.82-5.21c-5.52-6.27-10.54-13.01-14.96-20.15c-0.56-0.89-1.11-1.8-1.65-2.71L20.08,225
            c-0.11-0.2-0.23-0.4-0.34-0.6c-0.64-1.12-1.28-2.26-1.89-3.4c-0.72-1.32-1.41-2.65-2.07-4c-2.57-5.11-4.85-10.38-6.82-15.81
            c-4.48-12.32-7.38-25.4-8.47-39c-0.04-0.5-0.08-0.99-0.11-1.49C0.12,157.17,0,153.59,0,150c0-1.61,0.03-3.21,0.08-4.81
            c0.05-1.54,0.12-3.07,0.22-4.59C5.15,62.14,70.32,0,150,0c68.41,0,126.13,45.8,144.15,108.42c1.77,6.11,3.15,12.38,4.13,18.78
            c0.35,2.33,0.65,4.67,0.89,7.03c0,0.01,0.01,0.01,0.01,0.01C299.72,139.42,300,144.68,300,150z"/>
          <path id="back-mountains"
                class="st1"
                d="M300,150c0,17.98-3.16,35.22-8.96,51.19c-3.63,9.99-8.28,19.49-13.85,28.36l-0.36,0.57
            c-2.62,4.15-5.44,8.15-8.45,12.01L42.26,254.36c-1.65-1.7-3.26-3.44-4.82-5.21c-6.22-7.06-11.79-14.71-16.61-22.86
            c-0.37-0.63-0.74-1.26-1.09-1.89c-4.23-7.37-7.84-15.12-10.78-23.21c-4.48-12.32-7.38-25.4-8.47-39c-0.04-0.5-0.08-0.99-0.11-1.49
            C0.12,157.17,0,153.59,0,150c0-1.61,0.03-3.21,0.08-4.81c0.05-1.54,0.12-3.07,0.22-4.59c27.9-4.4,14.39-87.68,65.24-91.66
            c47-3.69,52.95,101.35,82.11,99.35c29.14-1.99,30.03-34.83,48.38-50.13c18.35-15.3,48.32-11.42,79.2,12.77
            c8.52,6.67,16.43,15,23.94,23.3c0,0.01,0.01,0.01,0.01,0.01C299.72,139.42,300,144.68,300,150z"/>
          <path id="front-mountains"
                class="st2"
                d="M300,150c0,17.98-3.16,35.22-8.96,51.19c-3.63,9.99-8.28,19.49-13.85,28.36l-0.36,0.57
            L19.74,224.4c-4.23-7.37-7.84-15.12-10.78-23.21c-4.48-12.32-7.38-25.4-8.47-39c8.47-4.57,14.56-4.18,27.01,3.81
            c16.75,10.75,31.85-28.45,52.3-49.1c20.45-20.65,40.3-23.96,52.45-10.9s12.74,41.1,32.04,42.8
            c19.31,1.7,23.252-87.967,54.366-84.823c35.569,3.593,23.594,82.693,46.594,78.233c18.85-3.65,15.87-24.41,28.9-33.79
            c1.77,6.11,3.15,12.38,4.13,18.78C299.41,134.63,300,142.25,300,150z"/>
          <path id="road"
                class="st3"
                d="M291.04,201.19C270.11,258.83,214.86,300,150,300S29.89,258.83,8.96,201.19H291.04z"/>
          <g id="lines">
            <g>
                <path class="st4"
                      d="M284.24,217c-0.67,1.35-1.36,2.68-2.07,4H17.85c-0.72-1.32-1.41-2.65-2.07-4H284.24z"/>
            </g>
            <g>
                <path class="st4"
                      d="M279.94,225c-0.78,1.35-1.58,2.68-2.41,4H22.48c-0.56-0.89-1.11-1.8-1.65-2.71L20.08,225H279.94z"/>
            </g>
        </g>
    </g>
  <g id="retro_1_">
            <g id="car">
                <g id="seat">
                    <path class="st5"
                          d="M117.888,119.054v4.298c0,3.251,2.679,5.865,5.929,5.784l4.506-0.112l-3.771-6.561L117.888,119.054z"/>
                  <path class="st6"
                        d="M146.15,129.385l1.896-2.195c0.274-0.317,0.424-0.721,0.424-1.14l0,0c0-0.936-0.739-1.705-1.675-1.742
                        l-11.104-0.438c-4.404-0.174-8.579-2.009-11.685-5.136l-1.988-2.002c-0.495-0.498-1.168-0.779-1.871-0.779h-1.613
                        c-1.385,0-2.507,1.122-2.507,2.507v0c0,1.127,0.742,2.156,1.839,2.416c4.069,0.964,6.75,5.056,8.286,9.076
                        C127.91,134.551,146.15,129.385,146.15,129.385z"/>
                </g>
              <g id="window">
                    <polygon class="st6"
                             points="174.541,130.212 183.808,111.374 186.022,111.591 176.229,131.486 			"/>
                <path class="st6"
                      d="M187.149,129.77l-2.439-0.068l-2.004-36.582c-0.057-1.047,0.776-1.928,1.825-1.928h0
                        c0.994,0,1.806,0.795,1.827,1.788L187.149,129.77z"/>
                <circle class="st7"
                        cx="184.926"
                        cy="111.374"
                        r="2.987"/>
                <circle class="st8"
                        cx="184.926"
                        cy="111.374"
                        r="1.118"/>
                </g>
              <g id="roof">
                    <path class="st9"
                          d="M92.713,127.9v-2.425c0-1.701-1.379-3.081-3.081-3.081h-6.631l-0.034,6.743L92.713,127.9z"/>
                <polygon class="st9"
                         points="83.001,122.394 69.845,120.466 49.388,115.3 49.388,126.083 59.306,126.083 81.692,128.387 			"/>
                <g>

                            <rect x="75.627"
                                  y="117.981"
                                  transform="matrix(0.1085 -0.9941 0.9941 0.1085 -54.0677 184.8804)"
                                  class="st7"
                                  width="0.827"
                                  height="9.206"/>
                    </g>
                <g>
                        <path class="st7"
                              d="M80.559,126.158c-0.603,0-2.485-0.224-9.144-1.015l0.098-0.82c3.464,0.411,8.742,1.025,9.088,1.032
                            l-0.006,0.39l0.295,0.33C80.835,126.124,80.796,126.158,80.559,126.158z"/>
                    </g>
                <g>

                            <rect x="57.254"
                                  y="109.757"
                                  transform="matrix(0.2335 -0.9724 0.9724 0.2335 -71.2885 147.1136)"
                                  class="st7"
                                  width="0.827"
                                  height="18.034"/>
                    </g>
                <g>

                            <rect x="57.133"
                                  y="112.599"
                                  transform="matrix(0.1578 -0.9875 0.9875 0.1578 -71.3699 159.0324)"
                                  class="st7"
                                  width="0.827"
                                  height="17.514"/>
                    </g>
                <g>

                            <rect x="57.03"
                                  y="115.871"
                                  transform="matrix(0.0366 -0.9993 0.9993 0.0366 -68.9975 177.2681)"
                                  class="st7"
                                  width="0.827"
                                  height="17.1"/>
                    </g>
                <g>
                        <path class="st4"
                              d="M70.273,127.591h-3.35c-0.406,0-0.786-0.17-1.071-0.48c-0.284-0.309-0.437-0.719-0.431-1.154
                            c0.01-0.748,0.04-1.335,0.09-1.746c0.096-0.793,0.13-1.224,0.193-2.023l0.056-0.691c0.064-0.791,0.648-1.406,1.388-1.463
                            l1.709-0.131c0.318-0.022,0.623,0.057,0.892,0.233l1.411,0.929c0.458,0.299,0.733,0.845,0.716,1.422l-0.103,3.547
                            C71.748,126.905,71.089,127.591,70.273,127.591z M68.966,120.784c-0.016,0-0.033,0-0.049,0.002l-1.709,0.131
                            c-0.333,0.025-0.595,0.301-0.624,0.656l-0.055,0.69c-0.065,0.814-0.1,1.254-0.198,2.062c-0.045,0.372-0.074,0.941-0.083,1.645
                            c-0.003,0.196,0.066,0.38,0.193,0.519c0.128,0.138,0.299,0.215,0.482,0.215h3.35c0.367,0,0.663-0.308,0.674-0.701l0.103-3.547
                            c0.008-0.26-0.115-0.505-0.321-0.639l-1.411-0.929C69.211,120.82,69.09,120.784,68.966,120.784z"/>
                    </g>
                <circle class="st4"
                        cx="68.86"
                        cy="120.393"
                        r="1.619"/>
                <path class="st4"
                      d="M49.388,115.3l-1.653-0.344l0.321,11.312c0.011,0.388,0.408,0.643,0.766,0.492l1.599-0.676L49.388,115.3z"/>
                <path class="st10"
                      d="M48.056,126.267l-0.337-0.231c-0.626-0.43-1.489-0.103-1.674,0.634h0c-0.063,0.25-0.257,0.44-0.51,0.488
                        c-1.998,0.383-11.116,1.779-22.814-1.904c-0.835-0.263-1.376-1.101-1.212-1.962c1.137-5.97-2.262-10.895-1.05-12.746
                        c1.934-2.952,27.276,4.408,27.276,4.408L48.056,126.267z"/>
                </g>
              <g id="steering-wheel">
                    <path class="st8"
                          d="M152.251,110.662l20.881,19.721h-5.114l-17.833-17.581c-0.572-0.564-0.593-1.48-0.048-2.069l0,0
                        C150.699,110.126,151.649,110.094,152.251,110.662z"/>
                <g>
                        <path class="st9"
                              d="M154.885,119.083c-0.476,0-0.862-0.164-1.109-0.477c-0.311-0.394-0.341-0.947-0.084-1.558
                            c0.223-0.529,0.645-1.057,1.191-1.487c0.545-0.43,1.157-0.718,1.723-0.812c0.651-0.111,1.185,0.049,1.496,0.444
                            c0.311,0.395,0.341,0.948,0.084,1.559c-0.223,0.529-0.646,1.056-1.191,1.486c-0.545,0.43-1.157,0.718-1.723,0.812
                            C155.137,119.073,155.008,119.083,154.885,119.083z M156.987,115.543c-0.077,0-0.159,0.007-0.246,0.022
                            c-0.425,0.07-0.915,0.306-1.346,0.646c-0.431,0.34-0.774,0.763-0.941,1.159c-0.131,0.311-0.142,0.582-0.029,0.725
                            c0.113,0.144,0.379,0.196,0.713,0.14c0.425-0.07,0.915-0.306,1.346-0.646c0.431-0.34,0.774-0.762,0.941-1.158
                            c0.131-0.312,0.142-0.583,0.029-0.726C157.369,115.6,157.202,115.543,156.987,115.543z"/>
                    </g>
                <path class="st9"
                      d="M162.534,104.163c0,0.008,0.008,0.017,0.017,0.033c0.008,0.017,0.016,0.017,0.016,0.017v-0.008
                        c0,0,0,0,0-0.008C162.551,104.188,162.543,104.172,162.534,104.163z M162.534,104.163c0,0.008,0.008,0.017,0.017,0.033
                        c0.008,0.017,0.016,0.017,0.016,0.017v-0.008c0,0,0,0,0-0.008C162.551,104.188,162.543,104.172,162.534,104.163z
                         M164.072,103.229c-0.058-0.099-0.074-0.107-0.083-0.124c-0.008-0.008-0.017-0.025-0.025-0.033
                        c-0.025-0.017-0.033-0.033-0.033-0.033l-0.074-0.091c-0.016,0-0.132-0.132-0.355-0.248c-0.256-0.132-0.595-0.174-0.909-0.149
                        c-0.628,0.058-1.24,0.331-1.942,0.711c-1.389,0.785-3.133,2.058-5.125,3.711c-1.19,0.992-2.447,2.124-3.744,3.381
                        c0.165,0.074,0.331,0.174,0.471,0.306l0.893,0.843c1.19-1.215,2.364-2.314,3.455-3.273c1.876-1.653,3.595-2.868,4.835-3.472
                        c0.364-0.182,0.694-0.314,0.942-0.38c0.05-0.132,0.074-0.231,0.083-0.289c0.008-0.008,0.008-0.025,0.008-0.033
                        c0.008-0.008,0.017,0.008,0.05,0.074c0,0,0,0,0.008,0.008l0.041,0.058c-0.016-0.008-0.025-0.025-0.033-0.033
                        c0,0.008,0.008,0.017,0.017,0.033c0.008,0.017,0.016,0.017,0.016,0.017v-0.008l0.025,0.017c0,0,0.041,0.041,0.107,0.074
                        c0.074,0.033,0.091,0.017,0.016,0.017c-0.066,0-0.19,0.017-0.339,0.066c-0.083,0.231-0.24,0.562-0.471,0.926
                        c-0.694,1.165-2.083,2.728-3.843,4.472c-0.884,0.868-1.843,1.81-2.86,2.81c-0.149,0.14-0.298,0.289-0.446,0.438
                        c-0.703,0.686-1.438,1.389-2.19,2.124c-0.19,0.174-0.38,0.364-0.57,0.546c-1.116,1.075-2.273,2.182-3.505,3.207
                        c-0.612,0.504-1.248,1-1.893,1.397c-0.157,0.099-0.628,0.364-0.463,0.281c0.066-0.149,0.141-0.306,0.215-0.455
                        c0.347-0.686,0.785-1.372,1.24-2.042c0.901-1.339,1.934-2.595,2.959-3.777c0.182-0.207,0.355-0.413,0.546-0.612l-0.909-0.893
                        c-0.149-0.141-0.256-0.314-0.339-0.496c-0.231,0.248-0.471,0.496-0.703,0.744c-1.099,1.182-2.19,2.455-3.248,3.819
                        c-0.521,0.686-1.033,1.397-1.496,2.19c-0.231,0.397-0.455,0.818-0.636,1.314c-0.091,0.256-0.182,0.521-0.223,0.884
                        c-0.017,0.083-0.017,0.19-0.017,0.306c0,0.099,0.017,0.223,0.041,0.355c0.008,0.099,0.083,0.339,0.198,0.537l0.074,0.124
                        l0.025,0.033l0.05,0.066c0.124,0.132,0.141,0.149,0.141,0.149c0.008,0,0.008,0.008,0.017,0.017l0.05,0.041l0.107,0.091
                        c0.074,0.058,0.157,0.107,0.223,0.132c0.149,0.074,0.314,0.141,0.43,0.157c0.141,0.033,0.265,0.05,0.372,0.05
                        c0.107,0.008,0.215,0.008,0.298,0c0.38-0.025,0.653-0.107,0.917-0.182c0.512-0.165,0.951-0.364,1.372-0.57
                        c0.835-0.413,1.595-0.86,2.339-1.331c1.48-0.934,2.835-1.951,4.091-3c0.256-0.215,0.504-0.438,0.752-0.661
                        c0.802-0.711,1.554-1.438,2.248-2.174c0.141-0.141,0.273-0.273,0.397-0.413c1.025-1.091,1.909-2.199,2.686-3.257
                        c1.546-2.133,2.645-4.025,3.339-5.488c0.322-0.752,0.57-1.372,0.603-2.017C164.295,103.882,164.295,103.61,164.072,103.229z
                         M162.534,104.163c0,0.008,0.008,0.017,0.017,0.033c0.008,0.017,0.016,0.017,0.016,0.017v-0.008c0,0,0,0,0-0.008
                        C162.551,104.188,162.543,104.172,162.534,104.163z"/>
                <path id="gear-knob"
                      class="st4"
                      d="M161.437,129.77l-6.107-5.444c-0.525-0.468-1.204-0.727-1.907-0.727l0,0
                        c-0.766,0-1.388,0.621-1.388,1.388l0,0c0,0.643,0.441,1.201,1.066,1.35l1.49,0.355c0.699,0.166,1.355,0.478,1.927,0.914
                        l4.093,3.123L161.437,129.77z"/>
                </g>
              <g id="body">
                    <path class="st7"
                          d="M225.65,144.88l-11.27,1.76l-18.79,6.61l-8.44,7.29l-10.57,9.14l-6.62,2.3l-64.88-0.65l-3.83-13.42
                        l-9.75-10.06l-12.41-5.64l-15.86-1.05l-3.92-7.85v-7.23l33.89,0.98l0.82,0.02l27.18,0.79l0.83,0.02l25.53,0.74l0.83,0.02
                        l23.25,0.67l0.83,0.03l11.13,0.32l3.55,0.1l29.41,0.85l6.53,0.19c0.4,0.01,0.74,0.31,0.79,0.72l1.71,12.92L225.65,144.88z"/>
                <path class="st8"
                      d="M227.819,144.832v-3.358l3.177-3.177h3.865c0.59,0,1.069,0.478,1.069,1.069v6.758L227.819,144.832z"/>
                <path class="st8"
                      d="M224.541,145.503l-12.737,1.756l-10.642,3.874l-12.088,6.871l-11.623,11.468l3.374,3.412
                        c0.61,0.617,1.612,0.598,2.197-0.042l1.271-1.388c0.473-0.517,1.141-0.811,1.842-0.811h14.371c0.35,0,0.65-0.251,0.712-0.596l0,0
                        c0.079-0.443-0.262-0.85-0.712-0.85H186.36l1.747-1.908c0.353-0.386,0.852-0.606,1.376-0.606h41.775
                        c2.58,0,4.672-2.092,4.672-4.672v-15.32v-0.827L224.541,145.503z"/>
                <polygon class="st7"
                         points="227.819,145.813 227.819,135.636 222.55,135.636 222.55,145.658 			"/>
                <g id="front-grill">
                        <path class="st9"
                              d="M216.558,130.621v-0.809l0.969-0.672v-1.459l-0.969-0.62v-1.266l0.723-0.781
                            c0.156-0.169,0.376-0.265,0.606-0.265h1.499c0.456,0,0.827,0.37,0.827,0.827v0.452h0.542v0.788l-0.555,0.039v0.831l-0.788,0.293
                            v1.847h1.007l0.336,1.266L216.558,130.621z"/>
                  <path class="st4"
                        d="M218.27,145.119c1.804-8.197,0.349-11.505-1.712-14.581l6.566,0.188c0.408,0.012,0.747,0.317,0.8,0.722
                            l1.719,12.998L218.27,145.119z"/>
                    </g>
                <g id="details">
                        <g>

                                <rect x="145.737"
                                      y="116.802"
                                      transform="matrix(0.005 -1 1 0.005 -12.3827 303.1509)"
                                      class="st8"
                                      width="0.827"
                                      height="81.993"/>
                        </g>
                  <g>

                                <rect x="205.046"
                                      y="121.798"
                                      transform="matrix(0.0192 -0.9998 0.9998 0.0192 68.9606 335.4708)"
                                      class="st8"
                                      width="0.827"
                                      height="21.581"/>
                        </g>
                  <g>
                            <rect x="198.115"
                                  y="139.018"
                                  class="st8"
                                  width="19.941"
                                  height="0.827"/>
                        </g>
                  <g>
                            <path class="st8"
                                  d="M172.92,147.8c0.04,1.49-0.5,2.9-1.5,3.97c-1.01,1.08-2.36,1.68-3.8,1.7l-14.06,0.18h-0.07
                                c-2.88,0-5.28-2.39-5.37-5.36l-0.56-19.66l0.83,0.02l0.56,19.61c0.07,2.52,2.1,4.54,4.54,4.54h0.06l14.06-0.18
                                c1.22-0.02,2.36-0.53,3.21-1.44c0.85-0.91,1.3-2.1,1.27-3.36l-0.45-18.5l0.83,0.03L172.92,147.8z"/>
                        </g>
                  <g>
                            <path class="st8"
                                  d="M121.2,127.87l0.42,16.86c0.05,1.94-0.66,3.78-1.99,5.17c-1.33,1.39-3.12,2.15-5.03,2.15h-6.94
                                c-7.52,0-13.64-6.21-13.64-13.85v-11.12l-0.82-0.02v11.14c0,8.1,6.49,14.69,14.46,14.69h6.94c2.13,0,4.13-0.85,5.62-2.41
                                c1.49-1.55,2.28-3.6,2.23-5.77l-0.42-16.82L121.2,127.87z"/>
                        </g>
                    </g>
                <polygon class="st4"
                         points="187.149,129.288 187.149,160.542 183.603,160.542 183.603,129.188 			"/>
                <path class="st11"
                      d="M242.242,145.951l-1.029,2.821c-33.889-4.058-48.585,8.133-63.967,25.425h-73.422l-0.851-5.587h70.67
                        c15.827-17.251,34.076-28.36,67.928-23.766C242.094,144.915,242.423,145.455,242.242,145.951z"/>
                <path class="st10"
                      d="M73.029,139.96H48.561c-0.456,0-0.827,0.37-0.827,0.827v1.343c0,0.456,0.37,0.827,0.827,0.827h14.418
                        l3.767,1.256l15.394,3.639l12.398,7.003l6.096,19.346h6.189c0.002-0.146,0.011-0.289,0.011-0.435
                        C106.834,155.095,91.699,139.96,73.029,139.96z"/>
                <path class="st8"
                      d="M103.841,173.767c0,0.141-0.017,0.289-0.017,0.43h-3.19l-5.621-9.514l-8.637-8.084l-10.539-3.554v-2.686
                        H63.158c-1.975,0-3.124,0-3.124,0l-5.687-7.406h18.68c0.703,0,1.405,0.025,2.099,0.074c0.289,0.017,0.579,0.041,0.86,0.066
                        c1.248,0.116,2.48,0.314,3.678,0.579c0.273,0.058,0.546,0.124,0.818,0.19c1.099,0.273,2.174,0.603,3.215,0.984
                        c0.265,0.099,0.529,0.198,0.785,0.314c1.132,0.446,2.223,0.959,3.281,1.537c0.248,0.132,0.479,0.264,0.719,0.405
                        c1.215,0.703,2.372,1.488,3.472,2.347c0.223,0.174,0.438,0.347,0.653,0.521c0.984,0.81,1.909,1.678,2.777,2.595
                        c0.19,0.198,0.372,0.397,0.562,0.603c1.281,1.43,2.438,2.984,3.438,4.637c0.149,0.24,0.298,0.488,0.43,0.736
                        c0.43,0.744,0.818,1.504,1.182,2.281c0.116,0.256,0.24,0.521,0.347,0.777C102.948,165.336,103.841,169.444,103.841,173.767z"/>
                <g>
                        <polygon class="st7"
                                 points="58.703,142.953 64.191,150.359 63.158,150.359 57.678,142.953 				"/>
                  <polygon class="st7"
                           points="67.58,150.359 66.53,150.359 60.893,142.953 61.927,142.953 				"/>
                  <polygon class="st7"
                           points="65.514,142.953 69.985,150.359 69.018,150.359 64.555,142.953 				"/>
                  <polygon class="st7"
                           points="72.787,150.359 71.853,150.359 67.812,142.953 68.762,142.953 				"/>
                  <polygon class="st7"
                           points="72.358,142.953 74.697,150.359 73.821,150.359 71.498,142.953 				"/>
                  <path class="st7"
                        d="M75.837,151.029h1.182l-1.893-8.001c0.289,0.017,0.579,0.041,0.86,0.066l2.075,8.761h-2.223V151.029z"/>
                  <path class="st7"
                        d="M84.483,145.16l-1.132,8.77l-4.149-1.141l0.463-9.117c0.273,0.058,0.546,0.124,0.818,0.19l-0.422,8.307
                            l2.587,0.711l1.05-8.034C83.962,144.945,84.227,145.044,84.483,145.16z"/>
                  <path class="st7"
                        d="M88.641,156.219l-4.364-2.215l3.488-7.307c0.248,0.132,0.479,0.264,0.719,0.405l-3.108,6.53l2.967,1.513
                            l3.612-5.695c0.223,0.174,0.438,0.347,0.653,0.521L88.641,156.219z"/>
                  <path class="st7"
                        d="M99.816,158.542l-7.555,2.414l-3.637-3.397l6.761-4.992c0.19,0.198,0.372,0.397,0.562,0.603l-6.034,4.455
                            l2.562,2.389l6.91-2.207C99.535,158.046,99.683,158.294,99.816,158.542z"/>
                  <path class="st7"
                        d="M101.345,161.6l-7.662,1.405l-0.149-0.81l7.464-1.372C101.113,161.079,101.237,161.344,101.345,161.6z"/>
                    </g>
                <path class="st10"
                      d="M241.214,148.772c-33.889-4.058-48.585,8.133-63.967,25.425
                        C187.281,159.245,210.011,141.474,241.214,148.772z"/>
                </g>
              <g id="front-lantern">
                    <polygon class="st12"
                             points="193.35,139.432 190.034,139.432 191.308,141.328 193.35,141.328 195.392,141.328 196.666,139.432
                                    "/>
                <polygon class="st9"
                         points="190.034,139.432 191.308,138.488 195.45,138.488 196.666,139.432 			"/>
                <rect x="192.776"
                      y="135.727"
                      class="st9"
                      width="0.924"
                      height="3.326"/>
                <rect x="191.695"
                      y="133.072"
                      class="st13"
                      width="2.13"
                      height="2.801"/>
                <rect x="191.345"
                      y="127.325"
                      class="st9"
                      width="4.152"
                      height="2.334"/>
                <path class="st4"
                      d="M195.984,137.73h-5.019c-0.444,0-0.809-0.351-0.826-0.795l-0.291-7.616h6.962l0,7.565
                        C196.822,137.348,196.449,137.73,195.984,137.73z"/>
                <path class="st12"
                      d="M195.05,126.945h-3.44c-0.295,0-0.534-0.239-0.534-0.534v-0.237c0-0.295,0.239-0.534,0.534-0.534h3.44
                        c0.295,0,0.534,0.239,0.534,0.534v0.237C195.584,126.706,195.345,126.945,195.05,126.945z"/>
                <path class="st12"
                      d="M194.431,125.64h-2.202c-0.295,0-0.534-0.239-0.534-0.534v-0.237c0-0.295,0.239-0.534,0.534-0.534h2.202
                        c0.295,0,0.534,0.239,0.534,0.534v0.237C194.965,125.401,194.726,125.64,194.431,125.64z"/>
                <path class="st12"
                      d="M194.176,124.336h-1.691c-0.436,0-0.789-0.353-0.789-0.789v-0.85c0-0.436,0.353-0.789,0.789-0.789h1.691
                        c0.436,0,0.789,0.353,0.789,0.789v0.85C194.965,123.982,194.612,124.336,194.176,124.336z"/>
                <polygon class="st4"
                         points="189.789,126.635 191.511,127.909 195.352,127.909 197.024,126.635 			"/>
                <polygon class="st4"
                         points="190.623,124.044 191.518,124.613 195.359,124.613 196.205,124.044 			"/>
                </g>
              <g id="roof-detail">
                    <circle class="st8"
                            cx="82.966"
                            cy="124.733"
                            r="3.167"/>
                <circle class="st4"
                        cx="82.966"
                        cy="124.733"
                        r="1.65"/>
                </g>
            </g>
    <g id="back-wheel">
                <path class="st14"
                      d="M75.837,150.359c-14.037,0-25.416,11.379-25.416,25.416c0,14.037,11.379,25.416,25.416,25.416
                    c14.037,0,25.416-11.379,25.416-25.416C101.254,161.738,89.875,150.359,75.837,150.359z M75.837,191.893
                    c-8.902,0-16.118-7.216-16.118-16.118c0-8.902,7.216-16.118,16.118-16.118c8.902,0,16.118,7.216,16.118,16.118
                    C91.955,184.677,84.739,191.893,75.837,191.893z"/>
      <path class="st15"
            d="M75.837,156.602c-10.589,0-19.173,8.584-19.173,19.173c0,10.589,8.584,19.173,19.173,19.173
                    c10.589,0,19.173-8.584,19.173-19.173C95.011,165.186,86.427,156.602,75.837,156.602z M75.837,191.893
                    c-8.902,0-16.118-7.216-16.118-16.118c0-8.902,7.216-16.118,16.118-16.118c8.902,0,16.118,7.216,16.118,16.118
                    C91.955,184.677,84.739,191.893,75.837,191.893z"/>
      <circle class="st14"
              cx="75.837"
              cy="175.776"
              r="7.163"/>
      <circle class="st15"
              cx="75.837"
              cy="175.776"
              r="3.794"/>
      <path class="st16"
            d="M75.837,159.245c-9.115,0-16.531,7.416-16.531,16.531c0,9.115,7.416,16.531,16.531,16.531
                    s16.531-7.416,16.531-16.531C92.368,166.66,84.952,159.245,75.837,159.245z M84.387,188.931l-4.252-7.365l-1.432,0.827
                    l4.251,7.364c-1.907,0.975-4.035,1.563-6.291,1.682v-8.499h-1.653v8.499c-2.255-0.118-4.384-0.707-6.291-1.682l4.251-7.364
                    l-1.432-0.827l-4.252,7.365c-1.838-1.198-3.407-2.768-4.606-4.606l7.365-4.252l-0.827-1.432l-7.364,4.252
                    c-0.975-1.907-1.563-4.035-1.682-6.291h8.499v-1.653h-8.499c0.118-2.255,0.707-4.384,1.682-6.291l7.364,4.252l0.827-1.432
                    l-7.365-4.252c1.198-1.838,2.768-3.407,4.606-4.606l4.252,7.365l1.432-0.827l-4.251-7.364c1.907-0.975,4.035-1.563,6.291-1.682
                    v8.499h1.653v-8.499c2.255,0.118,4.384,0.707,6.291,1.682l-4.251,7.364l1.432,0.827l4.252-7.365
                    c1.838,1.198,3.407,2.768,4.606,4.606l-7.365,4.252l0.827,1.432l7.364-4.252c0.975,1.907,1.563,4.035,1.682,6.291h-8.499v1.653
                    H91.5c-0.118,2.255-0.707,4.384-1.682,6.291l-7.364-4.252l-0.827,1.432l7.365,4.252C87.794,186.163,86.225,187.733,84.387,188.931
                    z"/>
            </g>
    <g id="front-wheel_1_">
                <path class="st14"
                      d="M228.336,150.359c-14.037,0-25.416,11.379-25.416,25.416c0,14.037,11.379,25.416,25.416,25.416
                    c14.037,0,25.416-11.379,25.416-25.416C253.752,161.738,242.373,150.359,228.336,150.359z M228.336,191.893
                    c-8.902,0-16.118-7.216-16.118-16.118c0-8.902,7.216-16.118,16.118-16.118c8.902,0,16.118,7.216,16.118,16.118
                    C244.454,184.677,237.237,191.893,228.336,191.893z"/>
      <path class="st15"
            d="M228.336,156.602c-10.589,0-19.173,8.584-19.173,19.173c0,10.589,8.584,19.173,19.173,19.173
                    c10.589,0,19.173-8.584,19.173-19.173C247.509,165.186,238.925,156.602,228.336,156.602z M228.336,191.893
                    c-8.902,0-16.118-7.216-16.118-16.118c0-8.902,7.216-16.118,16.118-16.118c8.902,0,16.118,7.216,16.118,16.118
                    C244.454,184.677,237.237,191.893,228.336,191.893z"/>
      <circle class="st14"
              cx="228.336"
              cy="175.776"
              r="7.163"/>
      <circle class="st15"
              cx="228.336"
              cy="175.776"
              r="3.794"/>
      <path class="st16"
            d="M228.336,159.245c-9.115,0-16.531,7.416-16.531,16.531c0,9.115,7.416,16.531,16.531,16.531
                    s16.531-7.416,16.531-16.531C244.867,166.66,237.451,159.245,228.336,159.245z M236.886,188.931l-4.252-7.365l-1.432,0.827
                    l4.251,7.364c-1.907,0.975-4.035,1.563-6.291,1.682v-8.499h-1.653v8.499c-2.255-0.118-4.384-0.707-6.291-1.682l4.251-7.364
                    l-1.432-0.827l-4.252,7.365c-1.838-1.198-3.407-2.768-4.606-4.606l7.365-4.252l-0.827-1.432l-7.364,4.252
                    c-0.975-1.907-1.563-4.035-1.682-6.291h8.499v-1.653h-8.499c0.118-2.255,0.707-4.384,1.682-6.291l7.364,4.252l0.827-1.432
                    l-7.365-4.252c1.198-1.838,2.768-3.407,4.606-4.606l4.252,7.365l1.432-0.827l-4.251-7.364c1.907-0.975,4.035-1.563,6.291-1.682
                    v8.499h1.653v-8.499c2.255,0.118,4.384,0.707,6.291,1.682l-4.251,7.364l1.432,0.827l4.252-7.365
                    c1.838,1.198,3.407,2.768,4.606,4.606l-7.365,4.252l0.827,1.432l7.364-4.252c0.975,1.907,1.563,4.035,1.682,6.291h-8.499v1.653
                    h8.499c-0.118,2.255-0.707,4.384-1.682,6.291l-7.364-4.252l-0.827,1.432l7.365,4.252
                    C240.293,186.163,238.723,187.733,236.886,188.931z"></path>
            </g>
        </g>
</svg>
