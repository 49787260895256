import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardResourceService {
  private readonly apiRouteConfig = '/webapp/';

  constructor(
    private http: HttpClient
  ) {}

  public getApps(): Observable<any> {
    return this.http.get(this.apiRouteConfig + 'api/core/apps');
  }

  public getLicenseInfo(): Observable<any> {
    return this.http.get(this.apiRouteConfig + 'api/core/twc/license/info');
  }
}
