import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DashboardModel} from './model/dashboard-model';
import {DashboardResourceService} from './resource/dashboard-resource.service';
import {DashboardErrorHandleService} from './service/dashboard-error-handle.service';
import {DoodlesService} from '../doodles/doodles.service';
import {NotificationsService} from '@wap/core/libs/notifications';
import {ErrorMessage, MessageService} from '@wap/core/libs/message';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private _dashboardModel: DashboardModel;

    constructor(
        private dashboardResource: DashboardResourceService,
        private doodleService: DoodlesService,
        private notificationService: NotificationsService,
        private dashboardErrorHandleService: DashboardErrorHandleService,
        private translate: TranslateService,
        private messageService: MessageService
    ) {
        this.dashboardModel = new DashboardModel();
    }

    public init(): void {
        this.doodleService.init();
        this.dashboardResource.getLicenseInfo().subscribe((res) => this.getLicenseInfoSuccess(res), (err) => this.getLicenseInfoError(err));
        this.dashboardResource.getApps().subscribe((res) => this.handlingSuccess(res), (err) => this.handlingError(err));
    }

    private handlingErrorContext(data): void {
        if (data.errorContext) {
            const errorContext = data.errorContext;

            if (typeof errorContext.reason !== 'undefined') {
                this.notificationService.error(this.translate.instant(errorContext.reason));
            }
        }
    }

    private handlingSuccess(response): void {
        this.dashboardModel.isLoading = false;
        this.dashboardModel.apps = response.apps;
        this.updateViewVariables();
        this.handlingErrorContext(response);
    }

    private handlingError(response): void {
        this.dashboardModel.isLoading = false;

        this.dashboardErrorHandleService.handlingErrorStatus(response);

        if (response && response.reason) {
            this.notificationService.error(this.translate.instant(response.reason));
        }
        else {
            this.notificationService.error(this.translate.instant('CORE.DASHBOARD.ERROR.LOAD_APP'));
        }
    }

    private getLicenseInfoSuccess(response): void {
      this.dashboardModel.license = response.data;
    }

    private getLicenseInfoError(response): void {
      if (response.status === 401) {
        this.messageService.serverErrorWithoutHandlers(response);
      }

      this.messageService.error({
        status: response.code || response.status,
        text: this.translate.instant(response.reason || response?.error?.reason),
        reason: response.reason || response?.error?.reason
      } as ErrorMessage);
    }

    public updateViewVariables(targetWidth?: any) {
        const isMobile = this.isMobile(targetWidth);
        this.dashboardModel.gridColSpanOfApps = this.getGridColSpanOfApps(isMobile);
        this.dashboardModel.gridRowHeight = isMobile ? '40px' : '50px';
    }

    public isMobile(targetWidth?: any): boolean {
        return (targetWidth || window.innerWidth) <= 960;
    }

    private getGridColSpanOfApps(isMobile?: boolean): number {
        const apps = this.dashboardModel && this.dashboardModel.apps || [];

        if (isMobile) {
            return 6;
        }
        if (this.dashboardModel.isLoading) {
            return 2;
        }
        if (apps.length === 4) {
            return 3;
        }
        if (apps.length >= 3) {
            return 2;
        }

        return apps.length > 1 ? 3 : 6;
    }

    get dashboardModel(): DashboardModel {
        return this._dashboardModel;
    }

    set dashboardModel(value: DashboardModel) {
        this._dashboardModel = value;
    }

}

