import { Component, HostListener, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { DashboardModel } from './model/dashboard-model';
import { DashboardProfileService, UserProfile } from './service/dashboard-profile.service';
import { DoodlesService } from '../doodles/doodles.service';
import { DoodleModel } from '../doodles/doodle-model';
import { AuthenticationService } from '@wap/core/libs/authentication';
import { ErrorMessage, MessageService } from '@wap/core/libs/message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  public currentUser = {} as UserProfile;
  public model = {} as DashboardModel;
  public doodleModel = {} as DoodleModel;

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private dashboardProfileService: DashboardProfileService,
    private doodlesService: DoodlesService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.dashboardService.updateViewVariables(event.target.innerWidth);
  }

  ngOnInit() {
    this.loadData();
  }

  public openApp(app): void {
    window.open(app.route, '_self');
  }

  public isAcademicLicense(): boolean {
    return this.model.license && this.model.license.licenseInfoMap && this.model.license.licenseInfoMap.academic;
  }

  public trackByFn(index, app): any {
    return app ? app.id : index;
  }

  public handleProfileSingOut(): void {
    this.dashboardProfileService.signOut();
  }

  public isMobile(): boolean {
    return this.dashboardService.isMobile();
  }

  showHeader(): boolean {
    return !!Object.keys(this.model).length;
  }

  private loadData() {
    const subscription = {
      next: (user: any) => {
        this.dashboardService.init();
        this.model = this.dashboardService.dashboardModel;
        this.doodleModel = this.doodlesService.doodleModel;
        this.currentUser = {
          name: user.userName ? user.userName : this.authenticationService.subject(),
          fullName: user.otherAttributes && user.otherAttributes.name,
          isGuest: user.isGuest
        };

        this.dashboardService.updateViewVariables();
      },
      error: (response: any) => {
        const errorMessage = {
          status: response.code || response.status,
          text: this.translate.instant(response.reason || response?.error?.reason),
          reason: response.reason || response?.error?.reason
        } as ErrorMessage;

        this.messageService.error(errorMessage);
      }
    };

    this.dashboardProfileService.getCurrentUser().subscribe(subscription);
  }
}
