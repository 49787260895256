<svg [ngClass]="{
            'nm-he-300p': this.model.isDoodleActive,
            'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
            'nm-wi-300p': this.model.isDoodleActive,
            'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
            'fillToScreen': !this.model.isDoodleActive
        }"
     version="1.1"
     id="Layer_1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px"
     y="0px"
     viewBox="0 0 300 300"
     style="enable-background:new 0 0 300 300;"
     xml:space="preserve">
<style type="text/css">
	.st0{fill:#263238;}
    .st1{fill:#FFFFFF;}
    .st2{fill:#B0BEC5;}
    .st3{fill:#EF5350;}
    .st4{fill:#E6E6E6;}
    .st5{fill:#999999;}
    .st6{fill:#808080;}
    .st7{fill:#B3B3B3;}
    .st8{fill:#CCCCCC;}
    .st9{fill:#666666;}
    .st10{fill:#ED1C24;}
    .st11{opacity:0.2;}
    .st12{fill:#64B5F6;}
    .st13{fill:#F2F2F2;}
</style>
    <circle id="background" class="st0" cx="150" cy="150" r="150"/>
    <g id="stars">
	<path id="star_4_"
          class="st1 blink1" d="M259.9,157c0.1-0.2-1.3-0.7-3-1.3c0.5-1.8,0.7-3.2,0.5-3.3c-0.2-0.1-0.7,1.3-1.3,3
		c-1.8-0.5-3.2-0.7-3.3-0.5c-0.1,0.2,1.3,0.7,3,1.3c-0.5,1.8-0.7,3.2-0.5,3.3s0.7-1.3,1.3-3C258.4,156.9,259.8,157.2,259.9,157z"/>
        <path id="star_8_" class="st1 blink2" d="M92.8,235.4c0.1-0.2-1.3-0.7-3-1.3c0.5-1.8,0.7-3.2,0.5-3.3s-0.7,1.3-1.3,3
		c-1.8-0.5-3.2-0.7-3.3-0.5c-0.1,0.2,1.3,0.7,3,1.3c-0.5,1.8-0.7,3.2-0.5,3.3c0.2,0.1,0.7-1.3,1.3-3
		C91.3,235.3,92.7,235.6,92.8,235.4z"/>
        <path id="star_3_" class="st1 blink3" d="M73,139.5c0-0.1-0.5-0.3-1.1-0.5c0.2-0.7,0.3-1.2,0.2-1.2c-0.1,0-0.3,0.5-0.5,1.1
		c-0.7-0.2-1.2-0.3-1.2-0.2c0,0.1,0.5,0.3,1.1,0.5c-0.2,0.7-0.3,1.2-0.2,1.2c0.1,0,0.3-0.5,0.5-1.1C72.5,139.5,73,139.6,73,139.5z"
        />
        <path id="star_2_" class="st1 blink4" d="M111.8,90.5c0-0.1-1-0.2-2.2-0.1c-0.1-1.2-0.3-2.2-0.4-2.2c-0.1,0-0.2,1-0.1,2.2
		c-1.2,0.1-2.2,0.3-2.2,0.4c0,0.1,1,0.2,2.2,0.1c0.1,1.2,0.3,2.2,0.4,2.2c0.1,0,0.2-1,0.1-2.2C110.8,90.8,111.8,90.6,111.8,90.5z"/>
        <path id="star_1_" class="st1 blink5" d="M170.8,177.6c0-0.1-1-0.2-2.2-0.1c-0.1-1.2-0.3-2.2-0.4-2.2c-0.1,0-0.2,1-0.1,2.2
		c-1.2,0.1-2.2,0.3-2.2,0.4c0,0.1,1,0.2,2.2,0.1c0.1,1.2,0.3,2.2,0.4,2.2s0.2-1,0.1-2.2C169.8,177.9,170.8,177.7,170.8,177.6z"/>
        <path id="star_7_" class="st1 blink6" d="M49.5,182.7c0-0.1-1-0.2-2.2-0.1c-0.1-1.2-0.3-2.2-0.4-2.2s-0.2,1-0.1,2.2
		c-1.2,0.1-2.2,0.3-2.2,0.4c0,0.1,1,0.2,2.2,0.1c0.1,1.2,0.3,2.2,0.4,2.2s0.2-1,0.1-2.2C48.6,183.1,49.6,182.9,49.5,182.7z"/>
        <path id="star_6_" class="st1 blink1" d="M240.3,82.5c0-0.1-1-0.2-2.2-0.1c-0.1-1.2-0.3-2.2-0.4-2.2c-0.1,0-0.2,1-0.1,2.2
		c-1.2,0.1-2.2,0.3-2.2,0.4c0,0.1,1,0.2,2.2,0.1c0.1,1.2,0.3,2.2,0.4,2.2c0.1,0,0.2-1,0.1-2.2C239.4,82.8,240.4,82.6,240.3,82.5z"/>
        <path id="star" class="st1 blink2" d="M168.3,95.2c0-0.1-0.8-0.5-1.8-0.9c0.4-1.1,0.6-2,0.5-2s-0.5,0.8-0.9,1.8c-1.1-0.4-2-0.6-2-0.5
		c0,0.1,0.8,0.5,1.8,0.9c-0.4,1.1-0.6,2-0.5,2s0.5-0.8,0.9-1.8C167.4,95.1,168.3,95.3,168.3,95.2z"/>
        <path id="star_9_" class="st1 blink3" d="M120.3,56.7c0-0.1-0.8-0.5-1.8-0.9c0.4-1.1,0.6-2,0.5-2c-0.1,0-0.5,0.8-0.9,1.8
		c-1.1-0.4-2-0.6-2-0.5c0,0.1,0.8,0.5,1.8,0.9c-0.4,1.1-0.6,2-0.5,2c0.1,0,0.5-0.8,0.9-1.8C119.4,56.6,120.3,56.8,120.3,56.7z"/>
        <path id="star_5_" class="st1 blink4" d="M218.2,126.6c0-0.1-0.8-0.5-1.8-0.9c0.4-1.1,0.6-2,0.5-2s-0.5,0.8-0.9,1.8c-1.1-0.4-2-0.6-2-0.5
		c0,0.1,0.8,0.5,1.8,0.9c-0.4,1.1-0.6,2-0.5,2c0.1,0,0.5-0.8,0.9-1.8C217.3,126.5,218.2,126.7,218.2,126.6z"/>
        <circle id="snow_16_" class="st2 blink5" cx="63.5" cy="121.5" r="1"/>
        <circle id="snow_18_" class="st2 blink6" cx="224.1" cy="97.5" r="1"/>
        <circle id="snow_22_" class="st2 blink1" cx="216.8" cy="188.2" r="1"/>
        <circle id="snow_15_" class="st2 blink2" cx="100.7" cy="157.3" r="1"/>
        <circle id="snow_21_" class="st2 blink3" cx="197.3" cy="227.9" r="1"/>
        <circle id="snow_20_" class="st2 blink4" cx="148.2" cy="178.8" r="1"/>
        <circle id="snow_14_" class="st2 blink5" cx="138.9" cy="135.1" r="1"/>
        <circle id="snow_13_" class="st2 blink6" cx="133.4" cy="181.9" r="1"/>
        <circle id="snow_12_" class="st2 blink1" cx="161" cy="213.2" r="1"/>
        <circle id="snow_11_" class="st2 blink2" cx="228.4" cy="166.4" r="1"/>
        <circle id="snow_10_" class="st2 blink3" cx="261.9" cy="191.9" r="1"/>
        <circle id="snow_9_" class="st2 blink4" cx="92.3" cy="96.5" r="1"/>
        <circle id="snow_8_" class="st2 blink5" cx="104.8" cy="121.9" r="1"/>
        <circle id="snow_7_" class="st2 blink6" cx="171.8" cy="68.2" r="1"/>
        <circle id="snow_6_" class="st2 blink1" cx="136" cy="87.2" r="1"/>
        <circle id="snow_5_" class="st2 blink2" cx="175.4" cy="136.6" r="1"/>
        <circle id="snow_17_" class="st2 blink3" cx="199.2" cy="152.4" r="1"/>
        <circle id="snow_19_" class="st2 blink4" cx="125" cy="211.6" r="1"/>
        <circle id="snow_4_" class="st2 blink5" cx="187.9" cy="187.2" r="1"/>
        <circle id="snow_3_" class="st2 blink6" cx="256.5" cy="130.2" r="1"/>
        <circle id="snow_2_" class="st2 blink1" cx="67.2" cy="164.9" r="1"/>
        <circle id="snow_1_" class="st2 blink2" cx="83.6" cy="187.2" r="1"/>
        <circle id="snow" class="st2 blink3" cx="38.1" cy="156.3" r="1"/>
</g>
    <g id="red-lightsaber">
	<g>
		<g>
			<path class="st3" d="M56.5,54.7L56.5,54.7c1.3-1.3,3.3-1.3,4.6,0l130.4,130.4l-4.6,4.6L56.5,59.3C55.2,58,55.2,56,56.5,54.7z"/>
			<path class="st3 light-saber vader" d="M56.5,54.7L56.5,54.7c1.3-1.3,3.3-1.3,4.6,0l130.4,130.4l-4.6,4.6L56.5,59.3C55.2,58,55.2,56,56.5,54.7z"/>
		</g>
	</g>
        <g>
		<polygon class="st4" points="238.6,229.8 229.1,239.3 189.1,200.1 199.5,189.7 		"/>

            <rect x="229.1" y="234.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -97.6548 234.8136)" class="st5" width="11" height="2.1"/>

            <rect x="185.7" y="187.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -79.6798 191.4181)" class="st6" width="11" height="8.6"/>
            <path class="st4" d="M245.3,239.3l-6.7,6.7c-0.3,0.3-0.8,0.4-1.2,0.1l-7.3-4.9l10.4-10.4l4.9,7.3
			C245.7,238.5,245.6,239,245.3,239.3z"/>
            <path class="st7" d="M243.2,245.4l1.5-1.5c1.1-1.1,1.1-2.9,0-4l0,0l-5.4,5.4l0,0C240.3,246.4,242.1,246.4,243.2,245.4z"/>
            <circle class="st5" cx="217.7" cy="227.1" r="4.5"/>
            <circle class="st8" cx="216.8" cy="227.9" r="4.5"/>
            <path class="st5" d="M212.1,215.1c1.8,1.8,1.8,4.6,0,6.4c-1.8,1.8-4.6,1.8-6.4,0c-1.8-1.8-1.8-4.6,0-6.4
			C207.5,213.4,210.4,213.4,212.1,215.1z"/>
            <path class="st8" d="M211.2,216c1.8,1.8,1.8,4.6,0,6.4c-1.8,1.8-4.6,1.8-6.4,0c-1.8-1.8-1.8-4.6,0-6.4
			C206.6,214.3,209.5,214.3,211.2,216z"/>
            <path class="st8" d="M206.9,208.8l5.8-5.8l-9.1-9.1l-5.8,5.8c-0.8,0.8-0.8,2.2,0,3l6,6C204.6,209.6,206,209.6,206.9,208.8z"/>
            <path class="st8" d="M197.1,188.3l-9.4,9.4c-0.3,0.3-0.3,0.7,0,1l0,0c0.3,0.3,0.7,0.3,1,0l9.4-9.4c0.3-0.3,0.3-0.7,0-1l0,0
			C197.8,188,197.3,188,197.1,188.3z"/>
            <path class="st8" d="M195.5,186.8l-9.5,9.5c-0.3,0.3-0.3,0.7,0,0.9l0,0c0.3,0.3,0.7,0.3,0.9,0l9.5-9.5c0.3-0.3,0.3-0.7,0-0.9l0,0
			C196.2,186.5,195.8,186.5,195.5,186.8z"/>
            <path class="st8" d="M194,185.2l-9.4,9.4c-0.3,0.3-0.3,0.7,0,1l0,0c0.3,0.3,0.7,0.3,1,0l9.4-9.4c0.3-0.3,0.3-0.7,0-1l0,0
			C194.7,185,194.2,185,194,185.2z"/>
            <path class="st9" d="M186.8,187.2l-6.7,6.7c-0.3,0.3,0,0.9,0.4,0.8l2.8-0.4l5.3-5.3l5.3-5.3l0.4-2.8c0.1-0.4-0.5-0.7-0.8-0.4
			L186.8,187.2z"/>
            <path class="st10" d="M202.3,200.5c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0c0.5,0.5,0.5,1.4,0,1.9
			C203.7,201,202.8,201,202.3,200.5z"/>
            <path class="st5" d="M206,204.3c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0c0.5,0.5,0.5,1.4,0,1.9
			C207.5,204.8,206.6,204.8,206,204.3z"/>
	</g>
        <g class="st11">
		<path d="M193.8,183.6l0.4-2.8c0.1-0.4-0.5-0.7-0.8-0.4l-6.7,6.7l-0.1,0.1l57.3,57.3l0.7-0.7c1.1-1.1,1.1-2.9,0-4l0.6-0.6
			c0.3-0.3,0.4-0.8,0.1-1.2l-4.9-7.3l-1.3,1.3l-1.5-1.5l0.9-0.9l-26.1-26.7l0.2-0.2l-9.1-9.1l0,0l-4.1-4.2l-1.3,1.3l-0.9-0.9
			l0.8-0.8c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-0.8,0.8l-0.6-0.6l0.8-0.8c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-0.8,0.8
			l-0.6-0.6l0.8-0.8c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-0.8,0.8l-0.9-0.9L193.8,183.6z"/>
	</g>
</g>
    <g id="blue-lightsaber">
	<g>
		<g>
			<path class="st12" d="M246.3,53.7L246.3,53.7c1.3,1.3,1.3,3.3,0,4.6l-112,112l-4.6-4.6l112-112C243,52.4,245.1,52.4,246.3,53.7z"/>
			<path class="st12 light-saber yoda" d="M246.3,53.7L246.3,53.7c1.3,1.3,1.3,3.3,0,4.6l-112,112l-4.6-4.6l112-112C243,52.4,245.1,52.4,246.3,53.7z"/>
		</g>
	</g>
        <g>
		<path class="st5" d="M82.6,207.6c-1,1-2.7,1-3.7,0c-1-1-1-2.7,0-3.7c1-1,2.7-1,3.7,0S83.6,206.5,82.6,207.6z"/>
            <path class="st7" d="M61.3,246l-7.2-7.2c-1.7-1.7-1.7-4.5,0-6.3l0,0L67.5,246l0,0C65.8,247.7,63,247.7,61.3,246z"/>

            <rect x="89.6" y="168" transform="matrix(0.7071 0.7071 -0.7071 0.7071 172.3215 -8.2433)" class="st5" width="13" height="71.9"/>
            <path class="st6" d="M103.7,207.4l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C104.1,208.2,104.1,207.7,103.7,207.4z"/>
            <path class="st6" d="M105.7,205.4l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C106,206.3,106,205.8,105.7,205.4z"/>
            <path class="st6" d="M107.6,203.5l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C108,204.3,108,203.8,107.6,203.5z"/>
            <path class="st6" d="M109.6,201.5l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C109.9,202.4,109.9,201.9,109.6,201.5z"/>
            <path class="st6" d="M95.9,215.2l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C96.3,216,96.3,215.5,95.9,215.2z"/>
            <path class="st6" d="M97.9,213.2l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C98.2,214.1,98.2,213.6,97.9,213.2z"/>
            <path class="st6" d="M99.8,211.3l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C100.2,212.1,100.2,211.6,99.8,211.3z"/>
            <path class="st6" d="M101.8,209.3l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
			l0,0C102.1,210.2,102.1,209.7,101.8,209.3z"/>
            <g>
			<path class="st6" d="M88.1,223L77,211.9c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2L87,224.2c0.3,0.3,0.8,0.3,1.2,0l0,0
				C88.5,223.8,88.5,223.3,88.1,223z"/>
                <path class="st6" d="M90.1,221L79,209.9c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0l0,0
				C90.4,221.9,90.4,221.4,90.1,221z"/>
                <path class="st6" d="M92,219.1L80.9,208c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0l0,0
				C92.4,219.9,92.4,219.4,92,219.1z"/>
                <path class="st6" d="M94,217.1L82.9,206c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0l0,0
				C94.3,218,94.3,217.5,94,217.1z"/>
		</g>
            <g>
			<path class="st6" d="M80.5,230.6l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
				l0,0C80.8,231.5,80.8,231,80.5,230.6z"/>
                <path class="st6" d="M82.5,228.7l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
				l0,0C82.8,229.5,82.8,229,82.5,228.7z"/>
                <path class="st6" d="M84.4,226.7l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2l11.1,11.1c0.3,0.3,0.8,0.3,1.2,0
				l0,0C84.7,227.6,84.7,227.1,84.4,226.7z"/>
                <path class="st6" d="M86.4,224.8l-11.1-11.1c-0.3-0.3-0.8-0.3-1.2,0l0,0c-0.3,0.3-0.3,0.8,0,1.2L85.2,226c0.3,0.3,0.8,0.3,1.2,0
				l0,0C86.7,225.6,86.7,225.1,86.4,224.8z"/>
		</g>
            <path class="st13" d="M111.9,200.9l1.1-1.1c4-4,5.3-10,3.4-15.3l-3.7-10.2l-11.1,11.1l-2.6,2.6l-33,33l-1.2,1.2l-6.8,6.8L70.9,242
			l8.1-8.1l-8.1-8.1l33-33L111.9,200.9z"/>
            <path class="st13" d="M127.8,172.2l-6.5-6.5c-1.5-1.5-4-1-4.8,1l-1.5,3.7c-0.4,1.1-0.2,2.3,0.7,3.1l5.4,5.4l5.4,5.4
			c0.8,0.8,2.1,1.1,3.1,0.7l3.7-1.5c2-0.8,2.5-3.3,1-4.8L127.8,172.2z"/>
            <path class="st8" d="M131.8,176.1l-7.9-7.9c-0.5-0.5-0.5-1.4,0-1.9l0,0c0.5-0.5,1.4-0.5,1.9,0l7.9,7.9c0.5,0.5,0.5,1.4,0,1.9l0,0
			C133.2,176.6,132.3,176.6,131.8,176.1z"/>
            <path class="st7" d="M135.2,175.7l-11-11c-0.5-0.5-0.5-1.4,0-1.9l0,0c0.5-0.5,1.4-0.5,1.9,0l11,11c0.5,0.5,0.5,1.4,0,1.9l0,0
			C136.6,176.3,135.8,176.3,135.2,175.7z"/>
            <path class="st5" d="M139.3,176L124,160.7c-0.5-0.5-0.5-1.4,0-1.9l0,0c0.5-0.5,1.4-0.5,1.9,0l15.3,15.3c0.5,0.5,0.5,1.4,0,1.9l0,0
			C140.7,176.5,139.9,176.5,139.3,176z"/>
            <path class="st8" d="M67.2,245.6l-12.8-12.8c-0.7-0.7-0.7-1.8,0-2.5l1.6-1.6c0.7-0.7,1.8-0.7,2.5,0l12.8,12.8
			c0.7,0.7,0.7,1.8,0,2.5l-1.6,1.6C69,246.3,67.9,246.3,67.2,245.6z"/>
	</g>
        <g class="st11">
		<path d="M134.3,178.7l-6.5-6.5l-70.2,70.2l3.6,3.6c1.7,1.7,4.5,1.7,6.3,0l0.1,0.1c0.5,0.5,1.2,0.5,1.6,0l2.4-2.4
			c0.5-0.5,0.5-1.2,0-1.6l-0.4-0.4l7.6-7.6l-1.9-1.9l1.3-1.3l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1
			c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1
			c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.6-0.6l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1
			c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1
			c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1
			c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0l0,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8
			l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8
			l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l0.8-0.8
			l1,1c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1-1l1.5-1.5l1.9,1.9l1.1-1.1c2.2-2.2,3.5-4.9,4-7.8l8.6-8.6l0.8,0.8
			c0.8,0.8,2.1,1.1,3.1,0.7l3.7-1.5C135.3,182.7,135.8,180.2,134.3,178.7z"/>
            <path d="M133.7,176.1c0.5-0.5,0.5-1.4,0-1.9l1.5,1.5c0.5,0.5,1.4,0.5,1.9,0l0,0c0.5-0.5,0.5-1.4,0-1.9l2.2,2.2
			c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9l-7.6-7.6l-5.8,5.8l3.9,3.9C132.3,176.6,133.2,176.6,133.7,176.1z"/>
	</g>
</g>
</svg>
