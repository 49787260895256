import { Injectable } from '@angular/core';
import { DoodleModel } from './doodle-model';
import { DoodleResourceService } from './resource/doodle-resource.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@wap/core/libs/notifications';

@Injectable({
  providedIn: 'root'
})
export class DoodlesService {
  private _doodleModel: DoodleModel;

  constructor(
    private doodlesResource: DoodleResourceService,
    private notificationsService: NotificationsService,
    private translate: TranslateService
  ) {
    this.doodleModel = new DoodleModel();
  }

  private handlingDoodleSuccess(response): void {
    this.doodleModel.name = response.name;
    this.doodleModel.isLoading = false;
  }

  private handlingDoodleError(response): void {
    if (!!response.message && !!response.reason) {
      this.notificationsService.error(this.translate.instant(response.reason));
    }
    else {
      this.notificationsService.error(this.translate.instant('CORE.DOODLE.ERROR'));
    }
  }

  public resizeDoodle(): void {
    this.doodleModel.isDoodleActive = !this.doodleModel.isDoodleActive;
  }

  public init(): void {
    this.doodlesResource.getDoodle().subscribe((res) => this.handlingDoodleSuccess(res), (err) => this.handlingDoodleError(err));
  }

  get doodleModel(): DoodleModel {
    return this._doodleModel;
  }

  set doodleModel(value: DoodleModel) {
    this._doodleModel = value;
  }
}
