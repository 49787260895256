import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wap/core/libs/authentication';
import { Observable, Subscriber, take } from 'rxjs';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authenticationServer: AuthenticationService) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.isUserAuthenticated();
  }

  public isUserAuthenticated(): Observable<boolean> | boolean {
    if (this.authenticationServer.model.authenticated) {
      return true;
    }

    return new Observable((observer: Subscriber<boolean>) => {
      this.authenticationServer.authenticationUpdated$.pipe( take(1) as any).subscribe(() => {
        observer.next(this.authenticationServer.model.authenticated);
      });
    });
  }
}
