import { NgModule } from '@angular/core';
import { DoodlesComponent } from './doodles.component';
import { AstronautComponent } from './components/astronaut/astronaut.component';
import { CarComponent } from './components/car/car.component';
import { AirplaneComponent } from './components/airplane/airplane.component';
import { WolfComponent } from './components/wolf/wolf.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DoodlesService } from './doodles.service';
import { StarWarsComponent } from './components/star-wars/star-wars.component';
import { DeveloperComponent } from './components/developer/developer.component';
import { HalloweenComponent } from './components/halloween/halloween.component';
import { LithuaniaDayComponent } from './components/lithuania-day/lithuania-day.component';
import { WinterChristmasComponent } from './components/winter-christmas/winter-christmas.component';

@NgModule({
  declarations: [
    DoodlesComponent,
    WolfComponent,
    AirplaneComponent,
    AstronautComponent,
    CarComponent,
    StarWarsComponent,
    DeveloperComponent,
    HalloweenComponent,
    LithuaniaDayComponent,
    WinterChristmasComponent
  ],
  providers: [DoodlesService],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [DoodlesComponent]
})
export class DoodlesModule {
}

