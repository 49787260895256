import { Component, OnInit } from '@angular/core';
import { DoodleModel } from '../../doodle-model';
import { DoodlesService } from '../../doodles.service';

@Component({
  selector: 'app-star-wars',
  templateUrl: './star-wars.component.html',
  styleUrls: ['./star-wars.component.less']
})
export class StarWarsComponent implements OnInit {
  public model: DoodleModel;

  constructor(
      private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {
    this.model = this.doodlesService.doodleModel;
  }

}
