<svg [ngClass]="{
            'nm-he-300p': this.model.isDoodleActive,
            'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
            'nm-wi-300p': this.model.isDoodleActive,
            'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
            'fillToScreen': !this.model.isDoodleActive
        }"
     version="1.1" id="developer-svg-container" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#ECEFF1;}
    .st1{fill:#CFD8DC;}
    .st2{fill:#B0BEC5;}
    .st3{fill:#90A4AE;}
    .st4{fill:#FAFAFA;}
    .st5{fill:#BBDEFB;}
    .st6{fill:#64B5F6;}
    .st7{fill:#9B9B9B;}
    .st8{fill:#FB8C00;}
    .st9{fill:#FFFFFF;}
    .st10{fill:#FBC39D;}
    .st11{fill:#2196F3;}
    .st12{fill:#F9AC7F;}
    .st13{fill:#263238;}
    .st14{fill:#546E7A;}
    .st15{fill:#37474F;}
    .st16{opacity:0.36;fill:url(#SVGID_1_);}
</style>
    <defs>
        <linearGradient id="gradient-ltu-flag" x1="0" x2="0" y1="0" y2="1">
            <stop stop-color="yellow" offset="30%"/>
            <stop stop-color="green" offset="50%"/>
            <stop stop-color="red" offset="80%"/>
      </linearGradient>
    </defs>
    <g id="background">
	<path class="st0" d="M299.2,150.3c0,20.8-4.2,40.5-11.8,58.5c-1.3,3.2-2.8,6.3-4.4,9.4c-1.2,2.5-2.5,4.9-3.9,7.2
		c-6.1,10.5-13.5,20.3-21.9,29c-2.5,2.6-5.1,5.1-7.7,7.5c-25.2,22.6-58,36.8-94.2,38.3c-2,0.1-4,0.1-6.1,0.1c-3,0-6-0.1-8.9-0.3
		c-36.6-2.1-69.7-17.4-94.6-41.2c-2.7-2.5-5.3-5.2-7.7-7.9c-7-7.8-13.3-16.3-18.6-25.4c-1.4-2.4-2.7-4.8-3.9-7.2c-1-2-2-4-2.9-6
		C4,193.3-0.8,172.3-0.8,150.3C-0.8,67.7,66,0.6,148.5,0.3h0.7c0.6,0,1.1,0,1.7,0C233,1.2,299.2,68,299.2,150.3z"/>
        <path class="st1" d="M274.9,230.3c-2.3,5-5.3,9.7-8.9,14.1c-27.5,34.1-69.6,55.9-116.8,55.9c-46,0-87.2-20.7-114.7-53.4
		c-4.3-5.1-7.8-10.7-10.4-16.6c-8.2-18.5-8.2-40,0.9-58.8c22.5-46.4,70.1-78.4,125.2-78.4c54,0,100.8,30.8,123.9,75.8
		C284.1,188.3,284,211,274.9,230.3z"/>
</g>
    <g id="desk">
	<path id="desk_1_" class="st2" d="M283,218.1c-1.2,2.5-2.5,4.9-3.9,7.2H19.4c-1.4-2.4-2.7-4.8-3.9-7.2H283z"/>
        <path id="left-leg" class="st3" d="M45.7,225.4v33.4c-2.7-2.5-5.3-5.2-7.7-7.9v-25.4H45.7z"/>
        <path id="right-leg" class="st3" d="M257.2,225.4v29c-2.5,2.6-5.1,5.1-7.7,7.5v-36.5H257.2z"/>
</g>
    <g id="monitors">
	<g id="right-monitor">
		<path id="frame_1_" class="st4" d="M260,206.3H154.3c-1.8,0-3.3-1.5-3.3-3.3v-60.5c0-1.8,1.5-3.3,3.3-3.3H260
			c1.8,0,3.3,1.5,3.3,3.3v60.5C263.4,204.8,261.9,206.3,260,206.3z"/>
        <path id="stand_1_" class="st3" d="M219.9,215c-0.4-1.1-0.6-2.2-0.6-3.4v-5.4h-12.1h-0.6h-10.8v5.4c0,1.2-0.2,2.3-0.6,3.4
			c-0.6,1.5-1.6,3.2-3.6,3.2h15h0.6h16.3C221.5,218.2,220.4,216.5,219.9,215z"/>
        <path id="frame-black_1_" d="M260,195.6H154.3c-1.8,0-3.3-1.5-3.3-3.3v-60.5c0-1.8,1.5-3.3,3.3-3.3H260c1.8,0,3.3,1.5,3.3,3.3
			v60.5C263.4,194.1,261.9,195.6,260,195.6z"/>
        <rect id="screen_1_" x="155.3" y="132.1" class="st5" width="103.8" height="59"/>
        <g>
			<path class="st4" d="M236.3,183.7h-56.6c-3.5,0-6.3-2.8-6.3-6.3v-32.1c0-3.5,2.8-6.3,6.3-6.3h56.6c3.5,0,6.3,2.8,6.3,6.3v32.1
				C242.6,180.9,239.8,183.7,236.3,183.7z"/>
            <g id="code-right-monitor">
                <rect x="182.8" y="143.9" class="st6" width="3.8" height="2.6"/>
                <rect x="189.1" y="143.9" class="st6" width="3.8" height="2.6"/>
                <rect x="195.3" y="143.9" class="st6" width="3.8" height="2.6"/>

                <rect x="182.8" y="157.3" class="st6" width="4.7" height="2.6"/>
                <rect x="197.9" y="157.3" class="st6" width="11.9" height="2.6"/>
                <rect x="214.6" y="157.3" class="st6" width="10.1" height="2.6"/>

                <rect x="182.8" y="163.1" class="st6" width="4.7" height="2.6"/>
                <rect x="197.9" y="163.1" class="st6" width="16.7" height="2.6"/>
                <rect x="220" y="163.1" class="st6" width="9.9" height="2.6"/>

                <rect x="182.8" y="168.9" class="st6" width="4.7" height="2.6"/>
                <rect x="197.9" y="168.9" class="st6" width="11.6" height="2.6"/>
                <rect x="214.6" y="168.9" class="st6" width="20.3" height="2.6"/>

                <rect x="182.8" y="174.7" class="st6" width="4.7" height="2.6"/>
                <rect x="197.9" y="174.7" class="st6" width="6.8" height="2.6"/>
                <polygon class="st6" points="209.5,177.3 220,177.3 220,177.3 220,174.7 209.5,174.7 				"/>
                <rect x="224.7" y="174.7" class="st6" width="10.1" height="2.6"/>
			</g>
		</g>
	</g>
        <g id="left-monitor">
		<path id="frame" class="st4" d="M142,206.3H36.3c-1.8,0-3.3-1.5-3.3-3.3v-60.5c0-1.8,1.5-3.3,3.3-3.3H142c1.8,0,3.3,1.5,3.3,3.3
			v60.5C145.4,204.8,143.9,206.3,142,206.3z"/>
            <path id="frame-black" d="M142,195.6H36.3c-1.8,0-3.3-1.5-3.3-3.3v-60.5c0-1.8,1.5-3.3,3.3-3.3H142c1.8,0,3.3,1.5,3.3,3.3v60.5
			C145.4,194.1,143.9,195.6,142,195.6z"/>
            <rect id="screen" x="37.3" y="132.1" class="st4" width="103.8" height="59"/>

            <g id="left-monitor-code">
			<rect x="47.3" y="144.9" class="st6 flag-ltu" width="16.3" height="8.3"/>
                <rect x="67.7" y="144.9" class="st6" width="44.5" height="8.3"/>
                <rect x="47.3" y="157.3" class="st6" width="64.9" height="2.4"/>
                <rect x="47.3" y="163.8" class="st6" width="64.9" height="2.4"/>
                <rect x="47.3" y="170.2" class="st6" width="33.7" height="2.4"/>
                <rect x="47.3" y="176.7" class="st6" width="33.7" height="2.4"/>
                </g>
		</g>
            <path id="stand" class="st7" d="M101.9,215c-0.4-1.1-0.6-2.2-0.6-3.4v-5.4H89.2h-0.6H77.8v5.4c0,1.2-0.2,2.3-0.6,3.4
			c-0.6,1.5-1.6,3.2-3.6,3.2h15h0.6h16.3C103.5,218.2,102.4,216.5,101.9,215z"/>
        <path id="cursor" d="M96.7,180.2c0.1,0,0.2,0,0.3-0.1l2-2l1.5,2.8c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0l1.2-0.6
			c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2l-1.4-2.6h2.6c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.2-0.1-0.3l-6.6-6
			c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.2,0.3v8.8C96.5,180,96.6,180.2,96.7,180.2z"/>
        <g id="code">
	</g>
</g>
    <g id="coffee-mug">
	<g id="mug">
		<path class="st8" d="M275.4,193.5h-20.5c-0.4,0-0.7,0.3-0.7,0.7v15.5c0,4.5,3.7,8.2,8.2,8.2h5.4c4.5,0,8.2-3.7,8.2-8.2v-15.5
			C276.1,193.8,275.8,193.5,275.4,193.5z"/>
        <path class="st8" d="M273,215.2c4.6-2.6,12.6-10.3,11.2-15.7c-0.4-1.4-1.2-2.5-2.6-3.2c-3.1-1.4-7.4,0.3-7.9,0.5l1.5,3
			c1.1-0.4,3.6-1.1,4.9-0.6c0.2,0.1,0.5,0.3,0.7,0.9c0.8,3.2-5.4,9.8-9.6,12.2L273,215.2z"/>
	</g>
        <path id="steam" class="st9" d="M264.6,187.6c0.7-0.2,1.4-0.6,2-1.1c0.6-0.4,1.1-1,1.4-1.6c0.3-0.6,0.2-1.2-0.1-1.7
		c-0.3-0.5-0.9-0.9-1.5-1.2c-0.6-0.3-1.2-0.6-1.9-0.9c-0.3-0.1-0.7-0.3-1.1-0.4c-0.4-0.2-0.8-0.4-1.2-0.6c-1.5-0.9-2.9-2.1-4-3.5
		c-1.1-1.5-2-3.1-2.5-5c-0.5-1.9-0.7-3.9-0.4-6c0.2-1.1,0.5-2.2,1.1-3.3c0.6-1.1,1.4-2.2,2.4-3c1-0.9,2.3-1.5,3.6-1.9
		c1.3-0.4,2.6-0.6,3.8-0.6l1.3,0c3,0,5.4,2.3,5.5,5.3c0,3-2.3,5.4-5.3,5.5c-0.9,0-1.7-0.2-2.4-0.5c-0.6-0.3-1-0.5-1.4-0.5
		c-0.4,0-1.1,0.3-1.5,1.1c-0.5,0.8-0.8,1.9-0.8,3.1c0,1.2,0.1,2.4,0.6,3.6c0.4,1.2,1.1,2.3,2.1,3.2c0.2,0.2,0.5,0.5,0.7,0.7
		c0.3,0.2,0.5,0.4,0.9,0.7c0.7,0.5,1.3,1,1.9,1.6c0.6,0.6,1.2,1.3,1.5,2.2c0.2,0.5,0.2,1,0.1,1.4c-0.1,0.5-0.3,0.9-0.5,1.3
		c-0.5,0.7-1.2,1.2-1.8,1.6c-0.7,0.4-1.4,0.8-2.1,1.1c-0.2,0.1-0.3,0-0.4-0.1C264.4,187.9,264.4,187.7,264.6,187.6
		C264.6,187.6,264.6,187.6,264.6,187.6L264.6,187.6z"/>
</g>
    <g id="developer">
	<g id="dev">
		<path id="left-arm" class="st10" d="M158,215l-59.4,3.8c-5.1,0.3-9.5-3.6-9.8-8.7l0,0c-0.3-5.1,3.6-9.5,8.7-9.8l59.4-3.8c3.1-0.2,5.8,2.2,6,5.3
			l0.5,7.2C163.5,212.1,161.1,214.8,158,215z"/>
        <path class="st11" d="M120.1,179.7l-29.4,20.7c-3.9,2.7-4.9,8.1-2.3,12.1l0,0c2,3,5.6,4.6,9.2,3.9c12.9-2.6,42.7-8.7,42.4-9.7
			c-0.2-0.8-3.5-12.5-6.2-22C132,178.7,125.1,176.2,120.1,179.7z"/>
        <path id="right-arm" class="st10" d="M140,215l59.4,3.8c5.1,0.3,9.5-3.6,9.8-8.7l0,0c0.3-5.1-3.6-9.5-8.7-9.8l-59.4-3.8c-3.1-0.2-5.8,2.2-6,5.3
			l-0.5,7.2C134.5,212.1,136.9,214.8,140,215z"/>
        <path class="st11" d="M177.9,179.7l29.4,20.7c3.9,2.7,4.9,8.1,2.3,12.1l0,0c-2,3-5.6,4.6-9.2,3.9c-12.9-2.6-42.7-8.7-42.4-9.7
			c0.2-0.8,3.5-12.5,6.2-22C166,178.7,172.9,176.2,177.9,179.7z"/>
        <g>
			<path class="st12" d="M126,141.8c-3.2,0-5.8,2.7-5.8,6c0,3.3,2.6,6,5.8,6s5.8-2.7,5.8-6C131.7,144.5,129.2,141.8,126,141.8z"/>
            <path class="st12" d="M169.5,141.8c-3.2,0-5.8,2.7-5.8,6c0,3.3,2.6,6,5.8,6c3.2,0,5.8-2.7,5.8-6
				C175.2,144.5,172.7,141.8,169.5,141.8z"/>
            <path class="st10" d="M145.9,179.8L145.9,179.8c0.6,0.1,1.2,0.1,1.8,0.1l0,0c1.8,0,3.6-0.2,5.3-0.7c0,0,0,0,0,0
				c9.4-2.5,16.4-11.3,16.4-21.9v-26.4c0-12.5-9.7-10.5-21.7-10.5l0,0c-12,0-21.7-2-21.7,10.5v26.4
				C126,169.2,134.8,178.9,145.9,179.8z"/>
            <path class="st13" d="M165,148.6v7.1h-7.7c0,0-1.9-5.2-9.6-5.2c-7.7,0-9.6,5.2-9.6,5.2h-7.7v-7.1c0-2.8-2.6-3.7-4.4-4v12.7
				c0,11.8,8.8,21.6,19.9,22.5l0,0c0.6,0.1,1.2,0.1,1.8,0.1c0.6,0,1.2,0,1.8-0.1l0,0c11.2-0.9,19.9-10.6,19.9-22.5v-12.7
				C167.7,144.9,165,145.8,165,148.6z"/>
            <path class="st13" d="M163.7,111.7c-5.9-0.8-15.2-0.9-21.9,4.6c-0.2,0.1-0.3,0.3-0.4,0.4H136c-5.5,0-10,3.9-10,8.7v4.9v11.3v13.7
				c1,0,1.8-0.9,1.8-1.9v-19.3c0,0,0.3-6.1,5.9-6.1h12.6h0.1h14.4c5.9,0,6.6,5.4,6.7,6.1v19.4c0,1.1,0.8,1.9,1.8,1.9v-13.7v-11.3
				V126l0.1-7.5C169.5,115.1,167,112.2,163.7,111.7z"/>
		</g>
        <rect x="127.1" y="124.8" class="st13" width="42" height="36.3"/>
	</g>
        <g id="chair">
		<path class="st14" d="M159.5,293.3h-23.7c-16.6,0-30-14.5-30-32.4l7.5-64.8c2.6-17.3,18.6-28.4,34.8-28.4h0
			c16.4,0,32.5,11.4,34.9,28.9l6.6,64.3C189.5,278.8,176.1,293.3,159.5,293.3z"/>
            <path class="st2" d="M155.3,224.3v75.8c-2,0.1-4,0.1-6.1,0.1c-3,0-6-0.1-8.9-0.3v-75.7c0-1.5,1.2-2.7,2.7-2.7h9.6
			C154.1,221.6,155.3,222.8,155.3,224.3z"/>
            <path class="st15" d="M155.7,229.6h-16.4c-2.9,0-5.2-2.3-5.2-5.2v-5.6c0-2.9,2.3-5.2,5.2-5.2h16.4c2.9,0,5.2,2.3,5.2,5.2v5.6
			C160.9,227.3,158.5,229.6,155.7,229.6z"/>
	</g>
</g>
    <g id="light">
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="148.1153" y1="57.8735" x2="148.1153" y2="182.6508">
		<stop  offset="0" style="stop-color:#FFFFFF"/>
        <stop  offset="0.8606" style="stop-color:#FAFAFA;stop-opacity:0"/>
	</linearGradient>
        <polygon id="lamp-light" class="st16" points="122,57.9 67.8,181.7 228.4,182.7 177.2,57.9 	"/>
        <circle class="st9" cx="149.7" cy="57.7" r="7.5"/>
        <g>
		<path class="st8" d="M149.7,30.1c-15.3,0-27.6,12.4-27.6,27.6h55.2C177.3,42.4,165,30.1,149.7,30.1z"/>
	</g>
        <path d="M150.9,0.3v29.8h-2.3V0.3h0.7C149.8,0.3,150.3,0.3,150.9,0.3z"/>
</g>
</svg>
