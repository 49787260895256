import {Component, OnInit} from '@angular/core';
import {DoodlesService} from '../../doodles.service';
import {DoodleModel} from '../../doodle-model';

@Component({
  selector: 'app-astronaut',
  templateUrl: './astronaut.component.html',
  styleUrls: ['./astronaut.component.less']
})
export class AstronautComponent implements OnInit {
  public model: DoodleModel;

  constructor(
    private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {
    this.model = this.doodlesService.doodleModel;
  }

}
