<svg [ngClass]="{
            'nm-he-300p': this.model.isDoodleActive,
            'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
            'nm-wi-300p': this.model.isDoodleActive,
            'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
            'fillToScreen': !this.model.isDoodleActive
        }"
     version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
  <!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<circle fill="#ECEFF1" cx="150" cy="150" r="150"/>
  <g id="frame">
	<path id="black-frame" d="M161.323,246.204l-6.678,2.504c-2.995,1.123-6.295,1.123-9.29,0l-6.678-2.504
		C97.367,230.713,70,191.221,70,147.102V55.771c0-2.939,2.382-5.321,5.321-5.321h149.358c2.939,0,5.321,2.382,5.321,5.321v91.331
		C230,191.221,202.633,230.713,161.323,246.204z"/>
    <path id="white-frame" fill="#FFFFFF" d="M150,242.551c-0.75,0-1.485-0.134-2.187-0.397l-6.678-2.504
		C102.774,225.265,77,188.072,77,147.103V57.45h146v89.653c0,40.97-25.774,78.162-64.136,92.547l-6.678,2.504
		C151.485,242.417,150.75,242.551,150,242.551z"/>
</g>
  <g id="flag">
	<path id="green" fill="#4CAF50" d="M220,120v27.1c0,11.25-2,22.2-5.73,32.45c-0.05,0.15-0.1,0.3-0.16,0.45H85.89
		c-0.06-0.15-0.11-0.3-0.16-0.45C82,169.3,80,158.35,80,147.1V120H220z"/>
    <path id="red" fill="#EF5350" d="M214.27,179.55c-0.05,0.15-0.1,0.3-0.16,0.45c-9.48,25.73-29.79,46.9-56.3,56.84l-6.68,2.5
		c-0.36,0.14-0.74,0.21-1.13,0.21s-0.77-0.07-1.13-0.21l-6.68-2.5c-26.51-9.94-46.82-31.11-56.3-56.84
		c-0.06-0.15-0.11-0.3-0.16-0.45H214.27z"/>
    <rect id="yellow" x="80" y="60.45" fill="#FFCA28" width="140" height="60"/>
</g>
  <g id="symbol">
	<path fill="#FFFFFF" d="M137.408,186.75c-1.183,0-2.366-0.285-3.419-0.824l-30.414-15.614c-2.513-1.295-4.075-3.852-4.075-6.672
		V91.25h13v69.03l18.167,9.326V119.75H143.5v-28.5h13v28.5h12.833v49.857l18.167-9.327V91.25h13v72.39
		c0,2.818-1.557,5.373-4.063,6.666l-30.421,15.616c-1.06,0.541-2.244,0.828-3.425,0.828H137.408z M143.667,173.75h12.667v-41
		h-12.667V173.75z"/>
    <path d="M199.5,92.25v71.39c0,2.443-1.349,4.656-3.517,5.775l-30.419,15.615c-0.921,0.471-1.949,0.719-2.972,0.719h-25.184
		c-1.026,0-2.05-0.247-2.962-0.714l-30.412-15.613c-2.18-1.123-3.534-3.339-3.534-5.784V92.25h11v67.42v1.221l1.087,0.558
		l16.167,8.299l2.913,1.496v-3.275V120.75H142.5h2v-2v-26.5h11v26.5v2h2h10.833v47.22v3.275l2.913-1.496l16.167-8.3l1.087-0.558
		v-1.221V92.25H199.5 M142.667,174.75h2h10.666h2v-2v-39v-2h-2h-10.666h-2v2v39V174.75 M201.5,90.25h-15v69.42l-16.167,8.3v-49.22
		H157.5v-28.5h-15v28.5h-12.833v49.219L113.5,159.67V90.25h-15v73.39c0,3.196,1.766,6.092,4.618,7.562l30.415,15.614
		c1.194,0.611,2.534,0.935,3.875,0.935h25.184c1.337,0,2.674-0.321,3.882-0.938l30.423-15.617c2.84-1.466,4.604-4.36,4.604-7.555
		V90.25L201.5,90.25z M144.667,172.75v-39h10.666v39H144.667L144.667,172.75z"/>
</g>
  <g id="yellow-circles">
	<circle id="yellow-circle" fill="#FFCA28" cx="50.908" cy="92.25" r="3"/>
    <circle id="yellow-circle_2_" fill="#FFCA28" cx="149.083" cy="33.113" r="3"/>
    <circle id="yellow-circle_4_" fill="#FFCA28" cx="203.5" cy="30.113" r="3"/>
    <circle id="yellow-circle_5_" fill="#FFCA28" cx="268.571" cy="102.977" r="3"/>
    <circle id="yellow-circle_6_" fill="#FFCA28" cx="245.33" cy="90.45" r="3"/>
    <circle id="yellow-circle_3_" fill="#FFCA28" cx="116.138" cy="23.33" r="3"/>
    <circle id="yellow-circle_1_" fill="#FFCA28" cx="56.908" cy="68.578" r="3"/>
</g>
  <g id="green-circles">
	<circle id="green-circle" fill="#4CAF50" cx="51.982" cy="148.945" r="3"/>
    <circle id="green-circle_3_" fill="#4CAF50" cx="30.101" cy="186.75" r="3"/>
    <circle id="green-circle_2_" fill="#4CAF50" cx="22.697" cy="118.78" r="3"/>
    <circle id="green-circle_1_" fill="#4CAF50" cx="274.571" cy="131.75" r="3"/>
    <circle id="green-circle_4_" fill="#4CAF50" cx="278.225" cy="183.75" r="3"/>
    <circle id="green-circle_5_" fill="#4CAF50" cx="249.674" cy="154.945" r="3"/>
</g>
  <g id="red-circles">
	<circle id="red-circle" fill="#EF5350" cx="63.982" cy="205.586" r="3"/>
    <circle id="red-circle_2_" fill="#EF5350" cx="239.33" cy="205.586" r="3"/>
    <circle id="red-circle_4_" fill="#EF5350" cx="243.674" cy="236.551" r="3"/>
    <circle id="red-circle_5_" fill="#EF5350" cx="161.766" cy="269.887" r="3"/>
    <circle id="red-circle_6_" fill="#EF5350" cx="204.5" cy="244.126" r="3"/>
    <circle id="red-circle_1_" fill="#EF5350" cx="57.982" cy="242.551" r="3"/>
    <circle id="red-circle_3_" fill="#EF5350" cx="96.5" cy="260.138" r="3"/>
</g>
</svg>
