<svg [ngClass]="{
			'nm-he-300p': this.model.isDoodleActive,
			'nm-he-220p_ma-wi-960p': this.model.isDoodleActive,
			'nm-wi-300p': this.model.isDoodleActive,
			'nm-wi-220p_ma-wi-960p': this.model.isDoodleActive,
			'fillToScreen': this.model.isDoodleActive === false
		}"

     version="1.1"
     id="airplane_1_"
     xmlns="http://www.w3.org/2000/svg"
     x="0px"
     y="0px"
     width="300px"
     height="300px"
     viewBox="0 0 300 300"
     style="enable-background:new 0 0 300 300;"
     xml:space="preserve">

	<circle id="background"
          class="airplane-st0"
          cx="150"
          cy="150"
          r="150"/>

  <g id="airplane">
	<g id="engine">
		<path class="airplane-st1"
          d="M197.625,157.883c5.783-0.361,19.217-1.622,19.522-1.839c0.304-0.217-1.609-5.348-1.609-5.348l-17.043,3.594
			l-3.333,1.537L197.625,157.883z"/>
    <polygon class="airplane-st2"
             points="220.755,157.478 218.799,158.87 215.321,158.87 215.321,155.579 220.755,155.579 		"/>
    <path class="airplane-st3"
          d="M234.429,147.913h-3.543v-2.391h3.543c0.66,0,1.196,0.535,1.196,1.196l0,0
			C235.625,147.378,235.09,147.913,234.429,147.913z"/>
    <path class="airplane-st4"
          d="M220.755,156.348H208.99c-0.898,0-1.626-0.728-1.626-1.626v-19.331l13.391,1.205V156.348z"/>
    <path class="airplane-st1"
          d="M230.299,152.087L230.299,152.087c-0.444,0-0.804-0.36-0.804-0.804V140.63l0,0
			c0.888,0,1.609,0.72,1.609,1.609v9.043C231.103,151.727,230.743,152.087,230.299,152.087z"/>
    <g>
			<path class="airplane-st2"
            d="M220.321,164.826h-2.783c0-2.253,1.834-4.087,4.087-4.087h5.786c0.383,0,0.693-0.31,0.693-0.693v-3.003
				h1.612c0.647,0,1.171,0.524,1.171,1.171v1.832c0,1.919-1.556,3.475-3.475,3.475h-5.786
				C220.906,163.522,220.321,164.107,220.321,164.826z"/>
		</g>
    <path class="airplane-st5"
          d="M220.755,135.392v22.082c0,0.82,0.558,1.536,1.353,1.735l4.688,1.177c1.679,0.422,3.306-0.848,3.306-2.579
			v-22.119c0-1.426-1.253-2.528-2.667-2.346l-5.518,0.71C221.251,134.148,220.755,134.718,220.755,135.392z"/>
    <g>
			<path class="airplane-st5"
            d="M210.147,141.326c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C210.842,141.015,210.531,141.326,210.147,141.326z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M212.842,141.326c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C213.538,141.015,213.227,141.326,212.842,141.326z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M215.538,141.326c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C216.234,141.015,215.922,141.326,215.538,141.326z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M218.234,141.326c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				c0.384,0,0.696,0.311,0.696,0.696v2.761C218.929,141.015,218.618,141.326,218.234,141.326z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M210.147,152.772c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C210.842,152.461,210.531,152.772,210.147,152.772z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M212.842,152.772c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C213.538,152.461,213.227,152.772,212.842,152.772z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M215.538,152.772c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C216.234,152.461,215.922,152.772,215.538,152.772z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M218.234,152.772c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				c0.384,0,0.696,0.311,0.696,0.696v2.761C218.929,152.461,218.618,152.772,218.234,152.772z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M215.538,146.87c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				s0.696,0.311,0.696,0.696v2.761C216.234,146.559,215.922,146.87,215.538,146.87z"/>
		</g>
    <g>
			<path class="airplane-st5"
            d="M218.234,146.87c-0.384,0-0.696-0.311-0.696-0.696v-2.761c0-0.385,0.311-0.696,0.696-0.696
				c0.384,0,0.696,0.311,0.696,0.696v2.761C218.929,146.559,218.618,146.87,218.234,146.87z"/>
		</g>
	</g>
    <g id="body">
		<g>
			<path class="airplane-st1"
            d="M62.364,152.043c0.304-0.13,9.043-5.348,9.043-5.348l8.478,0.115l-9.043,2.754L62.364,152.043z"/>
      <path class="airplane-st5"
            d="M67.538,144.435v2.493c0,0.524,0.457,0.931,0.978,0.87l9.761-1.146L67.538,144.435z"/>
      <circle class="airplane-st3"
              cx="62.364"
              cy="152.043"
              r="1.109"/>
		</g>
      <path class="airplane-st6"
            d="M103.246,144H60.825c-3.972,0-6.825-3.489-6.825-7.461v-26.344c0-2.268,0.731-4.313,2.213-5.795
			c1.482-1.482,3.433-2.4,5.701-2.4c2.31,0,4.468,0.981,6.026,2.692l10.766,11.917l0.614,0.826L103.246,144z"/>
      <path class="airplane-st7"
            d="M207.364,135.391v18.901c-9.016,2.922-22.129,3.833-36.87,3.534c-3.179-0.063-6.435-0.181-9.739-0.348
			c-45.224-2.31-99.868-13.684-99.868-13.684s87.332-24.981,121.301-21.503c5.913,0.925,8.974,1.503,8.974,1.503l7.304,6.031
			l6.17,5.099L207.364,135.391z"/>
      <g>
			<rect x="202.973"
            y="140.283"
            class="airplane-st8"
            width="4.391"
            height="0.696"/>
		</g>
      <g>
			<rect x="155.132"
            y="141.783"
            class="airplane-st8"
            width="52.232"
            height="0.696"/>
		</g>
      <polygon class="airplane-st9"
               points="206.668,137.304 203.19,140.957 185.799,140.957 185.799,134.911 190.668,129.826 198.467,129.826
			204.637,134.925 		"/>
      <g>
			<path class="airplane-st1"
            d="M195.481,140.843l-9.638-0.247v-3.639h10.159l2.206-6.846l0.662,0.213l-2.137,6.632h9.501v0.696h-9.726
				L195.481,140.843z M186.538,139.926l8.402,0.325l0.837-2.599h-9.239V139.926z"/>
		</g>
      <path class="airplane-st1"
            d="M204.637,134.925l-6.17-5.099h-7.798l-4.87,5.085v6.045h17.391l3.478-3.652L204.637,134.925z
			 M202.892,140.261h-16.397v-5.071l4.471-4.668h7.251l5.931,4.901l1.585,1.856L202.892,140.261z"/>
      <g>
			<path class="airplane-st5"
            d="M192.329,137.307l-24.528-0.175c-0.432-0.003-0.536-0.604-0.13-0.752l11.813-4.304l-12.038-5.104l0.272-0.64
				l12.725,5.395l12.129-4.419l0.238,0.654l-11.428,4.164l11.051,4.685C192.693,136.921,192.612,137.309,192.329,137.307z
				 M169.514,136.448l20.631,0.148l-9.722-4.122L169.514,136.448z"/>
		</g>
      <rect x="166.321"
            y="126"
            class="airplane-st8"
            width="1.855"
            height="12.522"/>
      <rect x="191.364"
            y="126"
            class="airplane-st8"
            width="2.174"
            height="11.304"/>
      <polygon class="airplane-st5"
               points="205.234,128.217 205.234,126.348 205.732,126.348 205.732,125.652 195.161,125.652 195.161,126.348
			204.538,126.348 204.538,128.217 203.847,128.217 203.847,128.913 206.929,128.913 206.929,128.217 		"/>
      <path class="airplane-st6"
            d="M155.107,126.123l30.376,2.81c1.415,0.131,2.842-0.009,4.205-0.411l5.062-1.495
			c2.05-0.605,1.941-3.545-0.147-3.997l-7.977-1.729c-2.898-0.628-5.894-0.668-8.808-0.116l-22.741,4.306
			C154.713,125.559,154.738,126.088,155.107,126.123z"/>
      <g>

				<rect x="175.016"
              y="150.886"
              transform="matrix(0.5186 -0.855 0.855 0.5186 -53.8108 228.1701)"
              class="airplane-st5"
              width="1.392"
              height="21.967"/>
		</g>
      <ellipse class="airplane-st10"
               cx="193.538"
               cy="174.435"
               rx="8.435"
               ry="7.565"/>
      <polygon class="airplane-st8"
               points="183,152.783 183,159.043 186,160.957 186,171 196,171 196,159.739 198,157.883 198,152.783
			195.016,153 186.495,153 		"/>
      <path class="airplane-st10"
            d="M192.69,158.609L192.69,158.609c-0.756,0-1.37,0.613-1.37,1.37v4.304h2.739v-4.304
			C194.06,159.222,193.447,158.609,192.69,158.609z"/>
      <path class="airplane-st7"
            d="M174.408,171.217c0,0,9.304,6.957,18.957,6.957s13.739-4.696,13.913-6.957c0.174-2.261-3.652-9.13-14-8.609
			C182.929,163.13,174.408,171.217,174.408,171.217z"/>
      <g id="plain">
			<path class="airplane-st11"
            d="M174.855,147.411c0.568-0.045,0.874,0.171,0.999,0.511c0.034,0.091,0.034,0.17-0.114,0.17
				c-0.567-0.011-1.815,0.273-2.473,0.443c-0.273,0.068-0.386,0.068-0.511-0.114c-0.102-0.147-0.238-0.351-0.295-0.488
				c-0.079-0.192-0.079-0.363,0.034-0.658c0.545-1.407,2.417-4.845,3.109-5.81c0.102-0.136,0.204-0.125,0.272,0.023
				c0.136,0.284,0.182,0.759-0.284,1.599c-0.59,1.078-1.622,2.825-2.462,4.641C173.777,147.558,174.424,147.445,174.855,147.411z"/>
        <path class="airplane-st11"
              d="M176.537,146.877c0.284-0.828,1.032-2.168,1.452-2.757c0.102-0.137,0.182-0.17,0.273-0.068
				c0.17,0.182,0.261,0.67-0.012,1.237c-0.238,0.499-0.715,1.27-0.953,1.838c-0.273,0.659-0.25,1.101,0.113,1.078
				c0.511-0.022,1.135-0.771,1.475-1.554c0.056-0.103,0.136-0.091,0.182,0c0.091,0.181,0.056,0.419-0.079,0.703
				c-0.34,0.703-1.056,1.18-1.691,1.18C176.435,148.534,176.219,147.808,176.537,146.877z M179.067,143.916
				c-0.148,0.159-0.466,0.125-0.636-0.17c-0.182-0.307-0.125-0.647,0.057-0.84s0.51-0.057,0.658,0.249
				C179.294,143.451,179.215,143.757,179.067,143.916z"/>
        <path class="airplane-st11"
              d="M179.477,143.655c-0.114-0.159-0.046-0.238,0.125-0.226c0.318,0.011,0.635-0.012,0.942-0.045
				c0.352-0.625,0.669-1.158,0.862-1.407c0.102-0.148,0.216-0.137,0.273,0c0.125,0.318,0.125,0.692-0.147,1.281
				c0.442-0.011,0.613,0.193,0.703,0.341c0.079,0.136,0.034,0.215-0.136,0.215c-0.25-0.034-0.601,0-0.885,0.045
				c-0.443,0.76-1.089,1.861-1.521,2.814c-0.374,0.829-0.249,1.532,0.227,1.555c0.51,0.022,1.157-0.783,1.577-1.634
				c0.068-0.136,0.193-0.159,0.238,0c0.057,0.182,0,0.386-0.114,0.647c-0.147,0.329-0.896,1.328-1.804,1.293
				c-0.749-0.022-1.35-0.862-0.806-2.168c0.238-0.567,0.715-1.52,1.202-2.416C179.829,143.95,179.568,143.78,179.477,143.655z"/>
        <path class="airplane-st11"
              d="M181.702,148.512c-0.806-0.057-0.828-0.897-0.578-1.645c0.249-0.749,0.828-1.861,1.566-2.894
				c0.091-0.125,0.204-0.114,0.295-0.011c0.249,0.374,0.193,0.885-0.216,1.532c-0.42,0.669-0.647,1.123-0.885,1.668
				c-0.238,0.556-0.204,0.965,0.057,0.988c0.443,0.034,1.067-0.76,1.679-1.748c0.715-1.157,1.044-1.94,1.407-2.428
				c0.114-0.159,0.193-0.181,0.284-0.068c0.17,0.205,0.261,0.704-0.011,1.271c-0.25,0.511-0.817,1.362-1.078,1.94
				c-0.295,0.647-0.249,1.123,0.159,1.1c0.477-0.022,1.055-0.771,1.498-1.565c0.057-0.103,0.159-0.114,0.204-0.012
				c0.046,0.091,0.057,0.397-0.102,0.715c-0.329,0.636-1.032,1.214-1.702,1.214c-0.738,0-1.032-0.465-0.942-1.123
				C182.939,147.978,182.337,148.557,181.702,148.512z"/>
        <path class="airplane-st11"
              d="M189.784,143.984c0.102-0.147,0.193-0.17,0.272-0.067c0.17,0.204,0.261,0.692-0.012,1.259
				c-0.249,0.511-0.817,1.362-1.089,1.963c-0.284,0.635-0.295,1.123,0.091,1.1c0.465-0.023,1.089-0.795,1.487-1.577
				c0.056-0.103,0.136-0.114,0.182-0.011c0.045,0.102,0.045,0.385-0.102,0.703c-0.295,0.624-1.01,1.248-1.634,1.214
				c-0.613-0.034-0.931-0.397-0.931-0.93c-0.363,0.466-0.874,0.885-1.418,0.885c-1.055,0-1.271-1.441-0.329-2.814
				c0.919-1.339,1.816-1.85,2.383-1.85c0.488,0,0.715,0.375,0.692,0.818C189.523,144.404,189.659,144.166,189.784,143.984z
				 M188.876,144.449c-0.363-0.034-1.191,0.703-1.815,1.713c-0.601,0.977-0.749,1.974-0.306,1.986
				c0.465,0.011,1.056-0.715,1.634-1.714C189.103,145.187,189.205,144.484,188.876,144.449z"/>
        <path class="airplane-st11"
              d="M193.791,143.916c0.375,0.056,0.726,0.613,0.443,1.203c-0.238,0.499-0.794,1.339-1.055,1.928
				c-0.295,0.659-0.227,1.192,0.182,1.169c0.465-0.022,1.021-0.771,1.452-1.565c0.057-0.103,0.136-0.103,0.193-0.012
				c0.079,0.125,0.057,0.386-0.102,0.715c-0.306,0.636-1.021,1.214-1.679,1.214c-0.965,0-1.191-0.703-0.862-1.691
				c0.375-1.111,1.373-2.314,1.169-2.405c-0.113-0.046-0.771,0.272-1.498,1.066c-0.669,0.726-1.169,1.815-1.657,2.882
				c-0.045,0.103-0.114,0.148-0.216,0.046c-0.227-0.227-0.295-0.601-0.113-1.169c0.227-0.715,1.1-2.292,1.815-3.347
				c0.079-0.125,0.17-0.114,0.238,0c0.17,0.284,0.159,0.669,0.034,1.077C192.645,144.484,193.417,143.859,193.791,143.916z"/>
        <path class="airplane-st11"
              d="M194.701,146.877c0.284-0.828,1.032-2.168,1.452-2.757c0.102-0.137,0.182-0.17,0.273-0.068
				c0.17,0.182,0.261,0.67-0.012,1.237c-0.238,0.499-0.715,1.27-0.953,1.838c-0.273,0.659-0.25,1.101,0.113,1.078
				c0.511-0.022,1.135-0.771,1.476-1.554c0.056-0.103,0.136-0.091,0.182,0c0.091,0.181,0.056,0.419-0.079,0.703
				c-0.34,0.703-1.056,1.18-1.691,1.18C194.599,148.534,194.383,147.808,194.701,146.877z M197.232,143.916
				c-0.148,0.159-0.465,0.125-0.636-0.17c-0.182-0.307-0.125-0.647,0.057-0.84s0.51-0.057,0.658,0.249
				C197.459,143.451,197.379,143.757,197.232,143.916z"/>
        <path class="airplane-st11"
              d="M200.387,144.608c0.238,0.477-0.216,1.078-0.443,1.691c-0.045,0.136-0.091,0.17-0.238,0.091
				c-0.272-0.136-0.431-0.477-0.272-0.987c0.125-0.409,0.465-0.897,0.306-1.022c-0.193-0.159-1.044,0.567-1.554,1.6
				c-0.624,1.282-0.386,2.224,0.284,2.235c0.953,0.012,1.77-0.896,2.235-1.611c0.056-0.091,0.158-0.102,0.204,0.012
				c0.034,0.091-0.012,0.374-0.204,0.681c-0.431,0.669-1.328,1.225-2.19,1.236c-1.441,0.023-1.929-1.384-1.191-2.848
				c0.726-1.43,1.872-2.043,2.44-1.703C200.069,144.166,200.251,144.347,200.387,144.608z"/>
        <path class="airplane-st11"
              d="M204.576,143.984c0.103-0.147,0.193-0.17,0.273-0.067c0.17,0.204,0.261,0.692-0.012,1.259
				c-0.249,0.511-0.817,1.362-1.089,1.963c-0.284,0.635-0.295,1.123,0.091,1.1c0.465-0.023,1.089-0.795,1.487-1.577
				c0.056-0.103,0.136-0.114,0.182-0.011c0.045,0.102,0.045,0.385-0.102,0.703c-0.295,0.624-1.01,1.248-1.634,1.214
				c-0.613-0.034-0.931-0.397-0.931-0.93c-0.363,0.466-0.874,0.885-1.418,0.885c-1.055,0-1.271-1.441-0.329-2.814
				c0.919-1.339,1.815-1.85,2.383-1.85c0.488,0,0.715,0.375,0.692,0.818C204.315,144.404,204.451,144.166,204.576,143.984z
				 M203.668,144.449c-0.363-0.034-1.192,0.703-1.816,1.713c-0.601,0.977-0.749,1.974-0.307,1.986
				c0.465,0.011,1.056-0.715,1.634-1.714C203.895,145.187,203.997,144.484,203.668,144.449z"/>
		</g>
      <path class="airplane-st6"
            d="M170,146.292v11.534c-2-0.063-6-0.181-10-0.348v-10.957l5.917-8.522h2.49L170,146.292z"/>
      <path class="airplane-st6"
            d="M185,154.174v-7.652l6.364-8.522h2.087l2.549,8.522v8.348C196,154.87,190,156.783,185,154.174z"/>
      <circle class="airplane-st5"
              cx="193.654"
              cy="175.014"
              r="1.507"/>
      <path class="airplane-st10"
            d="M202.726,169.507c-0.583,0-1.069,0.493-1.221,0.493H179v1h22.505c0.152,0,0.638,1.21,1.221,1.21
			c0.704,0,1.275-0.647,1.275-1.351C204.002,170.154,203.431,169.507,202.726,169.507z"/>
      <g>
			<path class="airplane-st7"
            d="M79.309,117h-11.2c-0.313,0-0.108,0.562-0.108,0.875V144h-1v-26.125c0-0.696,0.413-1.875,1.108-1.875h10.574
				L79.309,117z"/>
		</g>
      <g>

				<rect x="71.926"
              y="141.457"
              transform="matrix(0.4824 -0.8759 0.8759 0.4824 -84.0687 141.3354)"
              class="airplane-st5"
              width="11.267"
              height="0.696"/>
		</g>
      <g>

				<rect x="69.169"
              y="136.422"
              transform="matrix(0.977 -0.2131 0.2131 0.977 -28.5401 18.0624)"
              class="airplane-st5"
              width="0.695"
              height="9.997"/>
		</g>
      <path class="airplane-st8"
            d="M56.75,136.072c3.879-0.744,15.037-2.031,37.293,1.318c0.228,0.034,0.21,0.371-0.021,0.376
			c-21.729,0.45-33.251-0.603-37.253-1.085C56.421,136.639,56.405,136.139,56.75,136.072z"/>
      <g>
			<polygon class="airplane-st8"
               points="136.871,143.16 82.842,143.16 82.776,142.47 136.799,131.952 136.932,132.635 86.449,142.464
				136.871,142.464 			"/>
		</g>
      <path class="airplane-st12"
            d="M84.466,140.63"/>
      <g>
			<polygon class="airplane-st8"
               points="136.829,149.736 82.8,143.157 82.804,142.466 136.796,136.495 136.873,137.187 85.856,142.828
				136.913,149.046 			"/>
		</g>
      <g id="nr-688e">
			<path class="airplane-st10"
            d="M56.399,109.847v2.956h-0.577v-4.081h0.805l0.659,2.443v-2.443h0.571v4.081h-0.659L56.399,109.847z"/>
        <path class="airplane-st10"
              d="M59.659,112.803c-0.035-0.105-0.058-0.169-0.058-0.501v-0.641c0-0.379-0.128-0.519-0.42-0.519h-0.222v1.661
				h-0.641v-4.081h0.968c0.665,0,0.95,0.309,0.95,0.938v0.321c0,0.42-0.134,0.694-0.42,0.828c0.321,0.134,0.426,0.443,0.426,0.869
				v0.629c0,0.198,0.006,0.344,0.07,0.496H59.659z M58.959,109.305v1.254h0.251c0.239,0,0.385-0.105,0.385-0.431v-0.402
				c0-0.292-0.099-0.42-0.327-0.42H58.959z"/>
        <path class="airplane-st10"
              d="M61.526,109.736c0-0.688,0.315-1.061,0.979-1.061c0.63,0,0.956,0.373,0.956,1.026v0.105h-0.606v-0.146
				c0-0.291-0.128-0.402-0.332-0.402c-0.227,0-0.356,0.111-0.356,0.501v0.746c0.111-0.233,0.309-0.361,0.595-0.361
				c0.472,0,0.711,0.332,0.711,0.933v0.746c0,0.653-0.344,1.026-0.974,1.026c-0.629,0-0.973-0.373-0.973-1.026V109.736z
				 M62.167,111.129v0.735c0,0.291,0.128,0.396,0.332,0.396c0.204,0,0.333-0.105,0.333-0.396v-0.735
				c0-0.291-0.128-0.402-0.333-0.402C62.295,110.727,62.167,110.838,62.167,111.129z"/>
        <path class="airplane-st10"
              d="M64.797,112.849c-0.647,0-1.003-0.373-1.003-1.026v-0.321c0-0.391,0.117-0.694,0.396-0.851
				c-0.268-0.146-0.396-0.42-0.396-0.822v-0.129c0-0.653,0.356-1.026,1.003-1.026c0.647,0,1.003,0.373,1.003,1.026v0.129
				c0,0.402-0.134,0.676-0.396,0.822c0.28,0.158,0.396,0.46,0.396,0.851v0.321C65.8,112.476,65.445,112.849,64.797,112.849z
				 M64.436,109.952c0,0.327,0.152,0.431,0.361,0.431c0.204,0,0.361-0.105,0.361-0.431v-0.227c0-0.367-0.157-0.467-0.361-0.467
				c-0.204,0-0.361,0.099-0.361,0.467V109.952z M64.797,112.266c0.204,0,0.356-0.099,0.361-0.46v-0.379
				c0-0.321-0.134-0.461-0.361-0.461c-0.227,0-0.361,0.14-0.361,0.461v0.379C64.436,112.167,64.594,112.266,64.797,112.266z"/>
        <path class="airplane-st10"
              d="M67.107,112.849c-0.647,0-1.003-0.373-1.003-1.026v-0.321c0-0.391,0.117-0.694,0.396-0.851
				c-0.268-0.146-0.396-0.42-0.396-0.822v-0.129c0-0.653,0.356-1.026,1.003-1.026c0.647,0,1.003,0.373,1.003,1.026v0.129
				c0,0.402-0.134,0.676-0.396,0.822c0.28,0.158,0.396,0.46,0.396,0.851v0.321C68.11,112.476,67.754,112.849,67.107,112.849z
				 M66.746,109.952c0,0.327,0.152,0.431,0.361,0.431c0.204,0,0.361-0.105,0.361-0.431v-0.227c0-0.367-0.157-0.467-0.361-0.467
				c-0.204,0-0.361,0.099-0.361,0.467V109.952z M67.107,112.266c0.204,0,0.356-0.099,0.361-0.46v-0.379
				c0-0.321-0.134-0.461-0.361-0.461s-0.361,0.14-0.361,0.461v0.379C66.746,112.167,66.903,112.266,67.107,112.266z"/>
        <path class="airplane-st10"
              d="M69.137,110.442h0.88v0.583h-0.88v1.195h1.108v0.583h-1.749v-4.081h1.749v0.583h-1.108V110.442z"/>
		</g>
      <g>
			<path class="airplane-st9"
            d="M151.016,129.826c0-0.895-0.728-1.623-1.623-1.623h-11.289c-0.903,0-1.638,0.735-1.638,1.638v21.202h-0.261
				c-0.751,0-1.362,0.611-1.362,1.362c0,0.751,0.611,1.363,1.362,1.363h11.769c0.456,0,0.885-0.178,1.207-0.501
				c0.322-0.323,0.499-0.752,0.498-1.209l-0.054-20.529C150.418,131.368,151.016,130.666,151.016,129.826z M135.538,152.406
				c0-0.368,0.299-0.666,0.667-0.666h0.609h0.348h10.189c-0.187,0.416-0.246,0.876-0.169,1.326h-0.571
				c-0.013,0-0.024,0.006-0.037,0.007h-10.369C135.837,153.073,135.538,152.773,135.538,152.406z M148.689,152.776
				c-0.191,0.192-0.445,0.297-0.715,0.297h-0.067c-0.117-0.463-0.037-0.951,0.25-1.344l0.096-0.132
				c0.077-0.106,0.088-0.246,0.029-0.363c-0.059-0.117-0.179-0.19-0.31-0.19h-10.811v-21.202c0-0.52,0.423-0.942,0.942-0.942h10.826
				v1.971h-0.001l0.055,21.19C148.984,152.33,148.88,152.585,148.689,152.776z M149.625,130.805v-1.86
				c0.397,0.105,0.696,0.451,0.696,0.88C150.321,130.279,150.029,130.661,149.625,130.805z"/>
        <polygon class="airplane-st9"
                 points="142.002,131.502 144.031,131.502 144.668,130.928 147.973,130.029 143.886,130.029 143.306,130.609
				142.697,130.029 138.292,130.029 141.735,131.014 			"/>
        <path class="airplane-st9"
              d="M145.973,132.474c0-0.192-0.156-0.348-0.348-0.348h-5c-0.192,0-0.348,0.156-0.348,0.348
				c0,0.192,0.156,0.348,0.348,0.348h5C145.817,132.822,145.973,132.666,145.973,132.474z"/>
        <path class="airplane-st9"
              d="M137.929,134.883h3.609c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348h-3.609
				c-0.192,0-0.348,0.156-0.348,0.348S137.737,134.883,137.929,134.883z"/>
        <path class="airplane-st9"
              d="M142.929,137.03h-5c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h5
				c0.192,0,0.348-0.156,0.348-0.348S143.122,137.03,142.929,137.03z"/>
        <path class="airplane-st9"
              d="M137.929,140.568h3.935c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348h-3.935
				c-0.192,0-0.348,0.156-0.348,0.348S137.737,140.568,137.929,140.568z"/>
        <path class="airplane-st9"
              d="M137.929,143.41h4.464c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348h-4.464
				c-0.192,0-0.348,0.156-0.348,0.348S137.737,143.41,137.929,143.41z"/>
        <path class="airplane-st9"
              d="M137.929,146.253h3.609c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348h-3.609
				c-0.192,0-0.348,0.156-0.348,0.348S137.737,146.253,137.929,146.253z"/>
        <path class="airplane-st9"
              d="M142.929,148.399h-5c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h5
				c0.192,0,0.348-0.156,0.348-0.348S143.122,148.399,142.929,148.399z"/>
        <path class="airplane-st9"
              d="M144.668,134.883h2.495c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348h-2.495
				c-0.192,0-0.348,0.156-0.348,0.348S144.476,134.883,144.668,134.883z"/>
        <path class="airplane-st9"
              d="M148.125,137.03h-3.457c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h3.457
				c0.192,0,0.348-0.156,0.348-0.348S148.317,137.03,148.125,137.03z"/>
        <path class="airplane-st9"
              d="M147.389,139.872h-2.72c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h2.72
				c0.192,0,0.348-0.156,0.348-0.348S147.581,139.872,147.389,139.872z"/>
        <path class="airplane-st9"
              d="M148.102,143.062c0-0.192-0.156-0.348-0.348-0.348h-3.086c-0.192,0-0.348,0.156-0.348,0.348
				s0.156,0.348,0.348,0.348h3.086C147.947,143.41,148.102,143.255,148.102,143.062z"/>
        <path class="airplane-st9"
              d="M144.668,145.557c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h2.495
				c0.192,0,0.348-0.156,0.348-0.348s-0.156-0.348-0.348-0.348H144.668z"/>
        <path class="airplane-st9"
              d="M148.125,148.399h-3.457c-0.192,0-0.348,0.156-0.348,0.348s0.156,0.348,0.348,0.348h3.457
				c0.192,0,0.348-0.156,0.348-0.348S148.317,148.399,148.125,148.399z"/>
		</g>
	</g>
    <g id="propeller">
		<rect x="232.408"
          y="143.414"
          class="airplane-st2"
          width="2.203"
          height="6.471"/>
      <path class="airplane-st5"
            d="M232.408,143.414c-0.87-6.979,0.014-22.051,0.848-24.834c2.109,5.101,1.993,16.914,1.355,24.834H232.408z"/>
      <path class="airplane-st5"
            d="M234.66,149.884c0.87,6.979-0.014,22.051-0.848,24.834c-2.109-5.101-1.993-16.914-1.355-24.834H234.66z"/>
	</g>
</g>
  <g id="stars">
	<circle class="airplane-st14"
          cx="134.842"
          cy="197.645"
          r="2.222"/>
    <circle class="airplane-st14"
            cx="224.212"
            cy="73.469"
            r="1.031"/>
    <circle class="airplane-st14"
            cx="19.333"
            cy="155.546"
            r="1.031"/>
    <circle class="airplane-st14"
            cx="79.885"
            cy="238.333"
            r="1.031"/>
    <circle class="airplane-st14"
            cx="230.886"
            cy="267.167"
            r="1.031"/>
    <circle class="airplane-st14"
            cx="206.17"
            cy="14.365"
            r="1.031"/>
    <circle class="airplane-st14"
            cx="114.382"
            cy="34.611"
            r="2.222"/>
    <circle class="airplane-st14"
            cx="55.821"
            cy="43.167"
            r="2.222"/>
    <circle class="airplane-st14"
            cx="281.5"
            cy="158.609"
            r="2.222"/>
		</g>
  <g id="clouds">
	<path id="cloud-6"
        class="airplane-st13"
        d="M88.77,205c-0.867-5.723-5.793-10.114-11.757-10.114c-1.193,0-2.342,0.18-3.428,0.506
		C72.689,187.851,66.283,182,58.501,182c-7.439,0-13.619,5.348-14.932,12.406c-0.639-0.129-1.3-0.198-1.977-0.198
		c-5.479,0-9.92,4.441-9.92,9.92c0,0.294,0.019,0.584,0.044,0.872H88.77z"/>
    <path id="cloud-5"
          class="airplane-st13"
          d="M209.392,267.167c-1.476-9.746-9.864-17.223-20.022-17.223c-2.031,0-3.988,0.307-5.838,0.862
		C182.008,237.964,171.099,228,157.847,228c-12.668,0-23.192,9.106-25.428,21.126c-1.088-0.22-2.214-0.337-3.367-0.337
		c-9.33,0-16.893,7.563-16.893,16.893c0,0.501,0.032,0.995,0.075,1.485H209.392z"/>
    <path id="cloud-4"
          class="airplane-st13"
          d="M277.066,217c-0.904-5.972-6.044-10.554-12.269-10.554c-1.244,0-2.444,0.188-3.577,0.528
		c-0.934-7.869-7.618-13.975-15.739-13.975c-7.763,0-14.211,5.58-15.582,12.945c-0.667-0.135-1.356-0.207-2.063-0.207
		c-5.717,0-10.351,4.634-10.351,10.351c0,0.307,0.02,0.61,0.046,0.91H277.066z"/>
    <path id="cloud-3"
          class="airplane-st13"
          d="M90.512,66.167c-0.301-1.991-2.015-3.518-4.09-3.518c-0.415,0-0.815,0.063-1.192,0.176
		c-0.311-2.623-2.539-4.658-5.246-4.658c-2.588,0-4.737,1.86-5.194,4.315c-0.222-0.045-0.452-0.069-0.688-0.069
		c-1.906,0-3.45,1.545-3.45,3.45c0,0.102,0.007,0.203,0.015,0.303H90.512z"/>
    <path id="cloud-7"
          class="airplane-st13"
          d="M259.45,97.667c-0.204-1.35-1.366-2.385-2.773-2.385c-0.281,0-0.552,0.042-0.808,0.119
		c-0.211-1.778-1.722-3.158-3.557-3.158c-1.754,0-3.212,1.261-3.521,2.926c-0.151-0.03-0.307-0.047-0.466-0.047
		c-1.292,0-2.339,1.047-2.339,2.339c0,0.069,0.004,0.138,0.01,0.206H259.45z"/>
    <path id="cloud-2"
          class="airplane-st13"
          d="M174.164,101.333c-0.578-3.815-3.862-6.742-7.838-6.742c-0.795,0-1.561,0.12-2.286,0.338
		C163.444,89.901,159.174,86,153.986,86c-4.959,0-9.079,3.565-9.955,8.27c-0.426-0.086-0.867-0.132-1.318-0.132
		c-3.652,0-6.613,2.961-6.613,6.613c0,0.196,0.013,0.389,0.029,0.581H174.164z"/>
    <path id="cloud-1"
          class="airplane-st13"
          d="M226.565,49.5c-0.477-3.152-3.19-5.57-6.475-5.57c-0.657,0-1.29,0.099-1.888,0.279
		c-0.493-4.153-4.021-7.376-8.306-7.376c-4.097,0-7.5,2.945-8.224,6.832c-0.352-0.071-0.716-0.109-1.089-0.109
		c-3.017,0-5.463,2.446-5.463,5.463c0,0.162,0.01,0.322,0.024,0.48H226.565z"/>
</g>

</svg>
