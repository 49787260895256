<mat-progress-bar
        *ngIf="this.model.isLoading || this.doodleModel.isLoading"
        color="primary"
        mode="indeterminate">
</mat-progress-bar>
<span *ngIf="!this.model.isLoading && !this.doodleModel.isLoading && showHeader()">
	<mat-toolbar color="primary"
                 fxLayout="row">
		<div class="md-toolbar-tools"
             fxFlex
             fxLayout="row"
             fxLayoutAlign="end center">
            <wap-switcher *ngIf="!(this.isMobile() || this.currentUser.isGuest)"></wap-switcher>
            <wap-profile
                    (signOut)="handleProfileSingOut()"
                    [loading]="false"
                    [signOutLabel]="'CORE.USER_PROFILE.SING_OUT' | translate"
                    [user]="this.currentUser">
            </wap-profile>
		</div>
	</mat-toolbar>
	<div class="dashboard-content">
		<mat-grid-list
                [rowHeight]="this.model.gridRowHeight"
                cols="6">
			<mat-grid-tile
                    colspan="6"
                    rowspan="7">
				<app-doodles></app-doodles>
			</mat-grid-tile>
			<mat-grid-tile
                    colspan="6"
                    rowspan="2">
				<span class="nm-te-a-c
							 nm-pa-xl">
					<div class="nm-ov-y-h-ma-wi-960
								nm-tr-te-o-e-ma-wi-960
								nm-wi-vw-90_ma-wi-960
								nm-fo-s-34 nm-fo-s-20_ma-wi-960
								nm-fo-w-400 nm-fo-w-500_ma-wi-960
								nm-le-s-005-em_ma-wi-960">
						{{'CORE.DASHBOARD.HELLO' | translate}}
                        <span>{{this.currentUser && this.currentUser.fullName ? this.currentUser.fullName : this.currentUser.name || ''}},</span>
					</div>
                    <div class="nm-ov-y-h-ma-wi-960
								nm-tr-te-o-e-ma-wi-960
								nm-wi-vw-90_ma-wi-960
								nm-fo-s-34 nm-fo-s-16_ma-wi-960
								nm-li-h-40 nm-li-h-24_ma-wi-960
								nm-le-s-01-em_ma-wi-960">
                    {{'CORE.DASHBOARD.INFO' | translate}}{{this.isAcademicLicense() ? 'CORE.DASHBOARD.ACADEMIC' : '' | translate}}
                    </div>
				</span>
            </mat-grid-tile>
            <mat-grid-tile
                    *ngFor="let app of this.model.apps; let last = last;let idx = index; trackBy: trackByFn"
                    [colspan]="this.model.gridColSpanOfApps"
                    rowspan="4">
      				<mat-card (click)="this.openApp(app)"
                              class="card nm-wi-80 nm-cu-p">
						      					<mat-card-header class="nm-max-he-100p nm-he-100p">
                                                    <img alt=""
                                                         mat-card-avatar
                                                         src="{{app.imageUrl}}">
      							<div fxLayout="column">
                                    <mat-card-title class="nm-fo-s-20 nm-fo-w-400 nm-le-s-005-em">
                                        {{app.name}}
                                    </mat-card-title>
                                    <mat-card-content class="nm-li-h-24">
                                        <div class="nm-di-web-box
                                                    nm-ov-h
                                                    nm-web-l-cl"
                                             id="dashboard-app-description-{{idx}}"
                                             style="-webkit-box-orient: vertical;">
                                            {{app.description}}
                                        </div>
                                    </mat-card-content>
                                </div>
							</mat-card-header>
      				</mat-card>
      			</mat-grid-tile>
		</mat-grid-list>
	</div>
</span>
