import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwitcherConfig, SwitcherService } from '@wap/app-switcher/libs/switcher';
import { AuthenticationConfig, AuthenticationService } from '@wap/core/libs/authentication';
import { HeartbeatConfig, HeartbeatService } from '@wap/core/libs/heartbeat';
import { UserConfig, UserService } from '@wap/core/libs/user';
import { ErrorMessage, ForbiddenHandler, InfoMessage, MessageService, NotConnectedHandler, ServerUnavailableHandler, SignOutUserHandler, UnmanageableHandler } from '@wap/core/libs/message';
import { AnnouncementsService, AnnouncementsConfig, AnnouncementsDialogTexts } from '@wap/core/libs/announcements';
import { NotificationsService } from '@wap/core/libs/notifications';
import { take, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
      private router: Router,
      private translate: TranslateService,
      private authenticationService: AuthenticationService,
      private heartbeatService: HeartbeatService,
      private appSwitcherService: SwitcherService,
      private userService: UserService,
      private messageService: MessageService,
      private notificationService: NotificationsService,
      private announcementsService: AnnouncementsService
  ) {
    this.translate.addLangs(['en', 'lt']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.messageService.addHandler(new UnmanageableHandler());
    this.messageService.addHandler(new SignOutUserHandler());
    this.messageService.addHandler(new NotConnectedHandler());
    this.messageService.addHandler(new ServerUnavailableHandler());
    this.messageService.addHandler(new ForbiddenHandler());

    this.messageService.infoMessagePublished$.subscribe(
      (message: InfoMessage) => this.notificationService.info(message, true)
    );

    this.messageService.errorMessagePublished$.subscribe(
      (message: ErrorMessage) => this.notificationService.error(message)
    );

    const authenticationConfig = new AuthenticationConfig(
        '/webapp/api/authentication/token',
        '/webapp/api/authentication/authorize',
        '/webapp/api/authentication/signout');
    const heartbeatConfig = new HeartbeatConfig('/webapp/api/core/heartbeat/beat');
    const appSwitcherConfig = new SwitcherConfig('/webapp/api/core/apps');
    const userConfig = new UserConfig('/webapp/api/user/currentUser');
    const announcementsConfig = new AnnouncementsConfig(
      '/webapp/api/core/announcements',
      {
        next: 'CORE.ANNOUNCEMENTS.NEXT',
        close: 'CORE.ANNOUNCEMENTS.CLOSE',
        previous: 'CORE.ANNOUNCEMENTS.PREVIOUS',
        discard: 'CORE.ANNOUNCEMENTS.DISCARD'
      } as AnnouncementsDialogTexts
    );

    authenticationService.start(authenticationConfig);
    heartbeatService.init(heartbeatConfig);
    appSwitcherService.init(appSwitcherConfig);
    userService.init(userConfig);
    announcementsService.init(announcementsConfig);
  }

  ngOnInit(): void {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(take(1)).subscribe(() =>
      this.authenticationService.removeAuthorizationParameters()
    );
  }
}
