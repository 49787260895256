import {Component, OnInit} from '@angular/core';
import {DoodleModel} from '../../doodle-model';
import {DoodlesService} from '../../doodles.service';

@Component({
  selector: 'app-halloween',
  templateUrl: './halloween.component.html',
  styleUrls: ['./halloween.component.less']
})
export class HalloweenComponent implements OnInit {
  public model: DoodleModel;

  constructor(
    private doodlesService: DoodlesService
  ) { }

  ngOnInit(): void {

    this.model = this.doodlesService.doodleModel;
  }

}
