export interface App {
  route: string;
  imageUrl: string;
  name: string;
  description: string;
}

export class License {
  academic?: boolean;
  licenseInfoMap: {
    academic: any;
  };
}

export class DashboardModel {
  public apps: App[] = [];
  public isLoading = true;
  public license: License;
  public gridColSpanOfApps = 3;
  public gridRowHeight = '50px';
}
